@import url("./fonts.css");
.App {
  text-align: center;
}
* {
  /* font-family: Inter, sans-serif !important; */
  /* color: #0a243f !important; */
  font-family: 'Inter' !important;
    /* font-family: 'Inter' !important; */
}
body {
  position: relative;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-menu-item {
  height: 56px !important;
}
.ant-menu-submenu-title {
  color: black !important;
  height: 56px !important;
}
.ant-menu-submenu-selected {
  color: black !important;
}
.ant-menu-title-content {
  margin-left: 16px !important;
}
.ant-menu-item .ant-menu-title-content {
  margin-left: 16px !important;
}
.ant-menu-item-selected {
  border-radius: 12px !important;
  background-color: #0a243f !important;
  color: #fff !important;
}

.ant-menu-item-selected .ant-menu-item-icon {
  fill: #fff !important;
  stroke: #fff !important;
}
.ant-layout-sider-children {
  padding: 0 11px;
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #0a243f;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-tab-btn {
  color: #6c7c8c;
  font-weight: 500;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-ink-bar {
  background: #0a243f;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-modal .ant-modal-title {
  height: 72px;
  border-bottom: 16px solid #f1f1f2;
  padding: 18px;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-picker
  .ant-picker-input
  > input {
  font-size: 12px !important;
  font-weight: 500;
}
:where(.css-qgg3xn).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
}
/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #767676;
}

/* Firefox */
/* Firefox scrollbar has different pseudo-elements */
/* Also, Firefox does not support border-radius for scrollbar-thumb */
/* So, we apply a different style for Firefox */
/* Note: This style may not fully match the appearance of other browsers */
* {
  scrollbar-width: thin;
  scrollbar-color: #dedede #ffffff;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #dedede;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #767676;
}

.status-tag-style
{
 
  height: 32px;
  margin: 10px 0px 1px 20px;
  padding: 5px 16px 0px;
  border-radius: 8px;
  background-color: #ffebe6;
  font-size: 14px;
  font-weight: 600;
}
.status-waiting {
  background-color: #e8e8e8; 
  color: #0a243f;
}

.status-ready {
  background-color: #abf5d1;
  color: #064;
}

.status-rejected {
  background-color: #ffebe6;
  color: #bf2600;

}
.status-cancelled {
  background-color: #f0483e;
  color: #fff;

}


.status-default {
  background-color: #e8e8e8; 
  color: #0a243f;
  
}
