.item-wrapper {
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  border: solid 1px #eaeaea;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap:1rem
}
.item-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-title{
    font-size: 16px;
    font-weight: 500;
}
.flex-btn{
    display: flex;
    gap: 24px;
}
.item-data{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.item-button{
    display: flex;
    gap:1rem
}
.btnI{
    height: 46px;
    padding: 0 24px;
    font-weight: 500;
}
.btnII{
    height: 46px;
    padding: 0 24px;
    font-weight: 500;
    color: #ffff;
    background-color: #0354a3;
}
.item-subData{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.item-key{
    font-size: 16px;
    color:#0a243f;
    opacity: 0.6;
}
.item-val{
    font-size: 16px;
  font-weight: 500;
}
.key-val{
    display: flex;
    gap:12px
}
.item-notify{
    border-radius: 8px;
    background-color: #f8e6b6;
    padding: 1rem;
    font-weight: 500;
}
.item-check{
    display: flex;
    align-items: center;
    gap:8px
}
.item-status{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.dropdown-component-header{
background-color: #0a243f;
height: 40px;
color: #fff;
font-size: 14px;
font-weight: 500;
padding: 8px 26px;
}