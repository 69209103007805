.col-payment-strip
{
    padding: 16px;
    border-radius: 16px;
    background-color: #f5f5f5;
}
.mob-credit-splitup
{
    padding: 12px 12px 12px;
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0);
    background-image: linear-gradient(107deg, #e8f6c6 25%, #d1f4fa 66%);
}
.mob-cancel-warning
{
    padding: 16px;
    border-radius: 8px;
    background-color: #ffebe6;
}
.text-refund-size
{
    font-size: 20px;
  font-weight: 600;
  color: #0a243f;
}