.view-detail-wrapper {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  .view-detail-status {
    padding-bottom: 20px;
    border-bottom: 1px solid #d0d0d0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .view-detail-btn {
    font-size: 14px;
    font-weight: 500;
    height: 46px;
    padding: 0 24px;
  }
  .detail-activity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detail-invoice-wrapper {
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .detail-heading {
    font-size: 20px;
    font-weight: 500;
  }
  .seller-view {
    font-weight: 500;
    color: #2973f0;
  }
  .comp-wrapper {
    display: flex;
    gap: 24px;
  }
  .detail-status {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  .details-btn-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
  }
  .detail-btn {
    height: 46px;
    padding: 0 24px;
    font-weight: 500;
  }
  .vehicle-notify {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .vehicle-text {
    font-size: 14px;
    font-weight: 500;
    border-radius: 16px;
    background-color: #f8e6b6;
    padding: 24px;
    width: 100%;
  }
  .user-profile {
    border: 1px solid #dedede;
    border-radius: 8px;
  }
  .assigned-heading {
    color: #0a243f;
    font-size: 14px;
    font-weight: normal;
  }
  .assigned-container {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .section-deliverychallan
  {
   
    height: 120px; 
    padding: 24px;
    border-radius: 16px;
    background-color: #f8e6b6;
    border-color: #f8e6b6 !important;
  }
  .detail-subheading
  {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    display: block;
    color: #0a243f;
  }
  .detail-heading
  {
    margin-top: 10px;
  }
  .shortly-alert-style
  {
    font-size: 14px;
    font-weight: 500;
    color: #0a243f;
    border-radius: 16px;
    background-color: #f2f2f2;
    padding: 24px 24px 24px 24px;
  }
  .shortly-alert-style-yellow
  {
    font-size: 14px;
    font-weight: 500;
    color: #0a243f;
    border-radius: 16px;
    background-color: #f8e6b6;
    padding: 24px 24px 24px 24px;
  }
.ant-table-footer
{
  background: #fff !important;
}
.btn-primary
{
  background-color: #015fe5 !important;
}

.mob-list-table.no-right-border table tbody tr td:first-child
{
  border-right: 0px !important;
}
.ant-modal .ant-modal-header
{
  border-radius: 16px 16px 0 0;
}

/*  (Mobiles) */
@media only screen and (max-width: 480px){
  .view-detail-wrapper
  {
    padding: 0px !important;
  }
  .assigned-container
  {
display: block !important;
  }
  .section-deliverychallan
  {
   height: unset !important;
  }
  .deliveryBy-wrapper
{
  /* display: block !important; */
  width: 100%;
    
}
}
.Mobcredit-order-strip
{
  font-size: 14px;
    font-weight: 500;
align-items: center;
    border-radius: 16px;
    background-image: linear-gradient(96deg, #285b45 5%, #4fb589 30%);
    padding: 24px 24px 24px 24px;
}
.Mobcredit-order-strip span{
  color: #fff;
}
.card-paymentsummary
{
  border-radius: 16px;
  background-color: #faf9f5;
}
.card-paymentsummary .ant-card-body
{
 padding: 0px !important;
}
.tax-info-text
{
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
}
.view-detail-payment
{
  color: #2973f0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.pending-status-card
{
  padding: 13px 12px 13px 16px;
  border-radius: 8px;
  background-color: #ffebe6;
}