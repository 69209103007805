.edit-Order-model
{
    border-radius: 16px;
    background-color: #f5f5f5;
}
.edit-Order-model .ant-card-body
{
padding: 0px !important
}
.borderradius
{
    border-radius: 16px;
}