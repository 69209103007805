.info-box-hover:hover {
    box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.16);
}
.info-box-hover
{
    margin:10px 0px 1px 0px;
}
.order-list
{
    margin-top: 10px Im !important;
}
.no-records
{
    padding: 20px;   
    margin-top: 20px; 
    border-radius: 16px;
    background-color: #f2f2f2
}
.seller-title-text {
    font-weight: 600;
    color: #0a243f;
}
.item-number-style {
    opacity: 0.6;
    font-weight: normal;    
    color: #0a243f;
}
.status-missing-style {
    border-radius: 8px;
    background-color: #e8e8e8;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    margin-left: 10px;
}
.p-0
{
    padding: 0px !important;
}
.btn-theme {
    background: #015fe5 !important;
    color: #fff !important;
}
@media (min-width: 1400px) {
    .status-missing-style {
        padding: 8px 16px 7px !important;
        font-size: 14px !important;
    }
}
.mobcredit-highlight
{
    border-radius: 8px;
  background-color: #4fb589;
  margin: 0 0 20px;
  padding: 2px 2px 1px 2px;
  margin-top: 10px;
}

.mobcredit-image
{
    width: 84px;
    height: 11px;
    object-fit: contain;
    margin-top:3px;
}