.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  /* background: transparent; */
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  z-index: 999;
}

.example {
  margin: 12px;
}

/* Force update ant style */
/* .ant-input {
    padding: 16px 12px 4px 11px;
  } */

.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px !important;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 20px;
} */
.custum_input:hover {
  border: 1px solid #0a243f !important;
}
.custum_input:focus {
  border: 1px solid #0a243f !important;
}
.custum_input:active {
  border: 1px solid #0a243f !important;
}
.custum_input:focus-within {
  border: 1px solid #0a243f !important;
}
