.icon-payable-container
{
    display: flex;
    justify-content: start;
    align-items: center;
}
.tag-text
{
    display: block;
    /* text-align: right; */
    color: #0a243f;
    font-size: 24px;
    font-weight: bold;
}
.ml-4
{
    margin-left: 20px;
}
.tag-main-container-payable
{
    border: solid 1.5px #eaeaea;
  align-items: center;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    border-top: 0px !important;
    border-top-left-radius: 0px !important;
border-top-right-radius: 0px !important;
}
/* .payable-tab
{
    padding: 2rem;
} */
.payable-tab .ant-tabs-tab{
    border-radius: 12px;
    background-color: #fff;
    margin: 0px 12px 0px !important;
    padding: 13px 0;
}
.payable-tab .ant-tabs-tab .ant-tabs-tab-btn{
    color: #0a243f;
    font-size: 16px;
    padding: 0px 12px 0px !important;
}
.payable-tab .ant-tabs-tab.ant-tabs-tab-active{
    border-radius: 12px 12px 0px 0px;
    background-color: #0a243f;
    
}
.payable-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #fff !important;
    font-size: 16px;
}
.payable-tab .ant-tabs-nav-wrap
{
    border-bottom: 1px solid #d0d0d0;
}
.payable-tab .ant-tabs-ink-bar-animated
{
    display: none;
}
.notification-strip
{
    border-radius: 8px;
    background-color: #f8e6b6;
    padding: 24px;
    display: flex;
    justify-content: space-between;
}
.select-btn-text
{
    padding: 8px 16px 7px;
    border-radius: 8px;
    /* border: solid 1px #9da7b2; */
    background-color: #fff;
}
.payables .payable-card
{
border-bottom-left-radius: 0px !important;
border-bottom-right-radius: 0px !important;
border-bottom: 0px !important;
}
.text-blue
{
    color:  #0354a3;
}
