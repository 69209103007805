.handle-error-text {
  color: #f0483e;
}
.handle-input-error {
  border: 1px solid #f0483e !important;
}
.handle-input-error {
  border: 1px solid #f0483e;
  border-radius: 8px;
}

.handle-input-error > .ant-select-selector span {
  color: #f0483e;
  font-size: 14px;
  font-weight: 500;
}

/* .handle-input-error > .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
} */
.label-size
{
  font-size: 14px;
  font-weight: normal;
  color: #0a243f;
}

.label-head-size
{
  font-size: 20px;
  font-weight: 600;
  color: #0a243f;
}