.view-detail-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.view-detail-status {
  padding-bottom: 30px;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.view-detail-btn {
  font-size: 14px;
  font-weight: 500;
  height: 46px;
  padding: 0 24px;
}
.detail-activity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-invoice-wrapper {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.detail-heading {
  font-size: 20px;
  font-weight: 500;
}
.seller-view {
  font-weight: 500;
  color: #2973f0;
}
.comp-wrapper {
  display: flex;
  gap: 24px;
}
.detail-status {
  display: flex;
  gap: 24px;
  align-items: center;
}
.details-btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.detail-btn {
  height: 46px;
  padding: 0 24px;
  font-weight: 500;
}
.vehicle-notify {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.vehicle-text {
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  background-color: #f8e6b6;
  padding: 24px;
  width: 100%;
}
.user-profile {
  border: 1px solid #dedede;
  border-radius: 8px;
}
.assigned-heading {
  color: #0a243f;
  font-size: 14px;
  font-weight: normal;
}
.assigned-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
