.banner {
  width: 100%;
  height: 251px;
  background-color: #467fb6;
  display: flex;
  padding: 0 180px;
}
.left-banner {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 24px;
  justify-content: center;
  /* align-items: center; */
}
.right-banner {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 24px;
  justify-content: flex-end;
  align-items: flex-end;
}
.text-I {
  max-width: 450px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}
.text-II {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.image {
  height: 169px;
  width: 243px;
}
.task-bar {
  display: flex;
  width: 100%;
  padding: 0 2rem;
  gap: 2rem;
  /* justify-content: space-between; */
}
.table-wrapper {
  padding: 0 2rem;
}

.dropdown-wrapperr {
  display: flex;
  /* width: 40%; */
  /* justify-content: space-between; */
  gap: 2rem;
}
.sticky-col
{
  width: 100%;
  height: 96px;
  background-color: #fff;
  display: flex;
  padding: 0 40px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  justify-content: space-between;
  align-items: center;
}
.sticky-col-inner
{
  display: flex;                   
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.ant-select-single
{
  height: 48px !important;
}