.main-seller-view-container {
  position: relative;
}
.gray-background {
  height: 200px;
  width: 100%;
  background-color: #e8e8e8;
  position: absolute;
}
.header-container-seller-view {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dedede;
}
.verified-tag {
  background-color: #01a685;
  color: #fff;
  height: 28px;
  font-size: 14px;
  font-weight: 500;
}
.toggle-container-seller-view {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 0 20px;
}
.flag-text {
  color: #bf2600;
}
.store-text {
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.body-container-seller-view {
  padding: 20px 30px;
  display: flex;
  gap: 30px;
}
.image-container {
  width: 10%;
}
.detail-container {
  width: 100%;
}
.detail-name-text {
  font-size: 20px;
  font-weight: 500;
  color: #0a243f;
  display: block;
}
.detail-id-text {
  font-size: 14px;
  font-weight: normal;
  color: #0a243f;
  display: block;
}
.detail-body-container {
  margin-top: 20px;
  display: flex;
}
.detail-body-container-one {
  width: 30%;
}

.detail-body-container-one-heading {
  font-size: 14px;
  font-weight: normal;
  color: #6c7c8c;
  display: block;
  margin-bottom: 10px;
}
.detail-body-container-one-content {
  color: #0a243f;
  font-size: 16px;
  font-weight: 500;
}
.review {
}
.actions-container-seller-view {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.end-container-seller-view {
  display: flex;
  justify-content: space-between;
}
.tag-main-container {
  /* width: 32%; */
  border: solid 1px #eaeaea;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.icon-main-container {
  background-color: #f8e6b6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 45px;
  width: 45px;
}
.tag-text-container {
}

.tag-text-heading {
  display: block;
  text-align: right;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #0a243f;
}
.tag-text-number {
  display: block;
  text-align: right;
  color: #0a243f;
  font-size: 24px;
  font-weight: bold;
}
.icon-background-2 {
  background-color: #e3eafd;
}
.icon-background-3 {
  background-color: #cff8e2;
}
.dispute-container {
  background-color: #fcf2eb;
  /* width: 32%; */
  border: solid 1px #eaeaea;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.icon-background-4 {
  background-color: #f5c3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 45px;
  width: 90px;
}
.icon-background-5 {
  background-color: #ceecd8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 45px;
  width: 83px;
}
.assets-container {
  background-color: #ebf7ef;
  /* width: 32%; */
  border: solid 1px #eaeaea;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.footer-container-text {
  font-size: 14px;
  font-weight: normal;
  text-align: right;
  color: #6c7c8c;
  display: block;
  margin-top: 10px;
}
.negative-tag-text-container {
  background-color: #eaeaea;
}
.negative-icon-main-container {
  background-color: rgba(234, 234, 234, 1);
}
.negative-text {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #6c7c8c;
  text-align: right;
  margin-top: 10px;
}
.end-container-seller-view
{
  gap: 16px;
}
@media only screen and (max-width: 768px) {

.end-container-seller-view {
  display: flex;
  flex-flow: column;
}
.settleHeaderWrapper , .settlementWrapper
{
padding: 10px !important;
}
}