.notification-divider {
    border-block-start: 16px solid rgba(5, 5, 5, 0.06);
    margin: 0px !important;
}
.notification-drawer .ant-drawer-body {
    padding: 0px !important;
}
.notification-drawer .ant-drawer-header {
    border-bottom: 16px solid rgba(5, 5, 5, 0.06);
}
.notification-order-list {
    padding: 24px;
}
.status-tag-style-notification {
    height: 32px;
    padding: 5px 16px 0px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
}
.btn-common-width {
    width: 170px !important
}