.ant-select-single
{
    width: 40%;
}
.paymentmode .ant-select-selector
{
    height: 46px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.input-style-width
{
    width: 60%;
}
.ant-select-selection-item .creditdropdown
{
display: none !important;
}
.creditdropdown-icon
{
    display: none !important;
}
.ant-select-selection-item .creditdropdown-icon
{
    display: block !important;
}
.ant-select-item.ant-select-item-option
{
border-bottom: 1px solid #dedede;

}
.ant-select-item-option-disabled
{
    background-color: #f2f2f2 !important;
}
.ant-select-dropdown
{
    padding: 0px;
}
.text-font
{
    font-size: 16px;
  font-weight: 600;
}


.text-credit-active
{
    font-size: 14px;
    color : #01a685 !important;
}
.text-credit-inactive
{
    font-size: 14px;
    color : #f0483e !important;
}
.paymentsummary .ant-card-head
{
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
padding: 0px !important;
background-color: #f0483e;
color: #fff;

}
.paymentsummary  .ant-card-head-title
{
    padding: 16px;
}
.paymentsummary  .ant-card
{
    border-radius: 12px;
    padding: 0px !important;
}
.static-text-credit .ant-card-head-wrapper
{
    display: block !important;
}
.cls-outline-error
{
    border-color: #f0483e !important;
    border: 1px solid;
    border-radius: 6px;
}