.custom-collapse-terms .ant-collapse-header-text
{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
}
.custom-collapse-terms .ant-collapse-content-box p
{
    font-size: 14px;
    opacity: .8;
    text-align: left;
    color: #0a243f;
}

.custom-tab-terms .ant-tabs-tab-btn
{
    font-size: 16px;
    font-weight: 500;
    color: #0a243f;
}
.custom-tab-terms  .ant-tabs-tab
{
    margin: 10px !important;
}
.custom-tab-terms .ant-tabs-tab.ant-tabs-tab-active
{
    border-radius: 8px !important;
    background-color: #0a243f !important;
    height: 48px;
 
}
.custom-tab-terms .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
{
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
}