.order-list-table .ant-table-row-expand-icon-cell {
    width: 0 !important;
  }
  
  .order-list-table table tbody tr td:first-child {
    border-right: none;
  }
  
  .order-list-table .ant-table-expanded-row .ant-table-cell {
    background-color: #ffffff;
    padding: 16px 30px 16px 48px;
  }
  
  .cursor {
    cursor: pointer;
  }
  .order-id-text {
    color: #2973f0;
    cursor: pointer;
  }
  .plus-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 3px;
    margin-left: 1rem;
  }
  .header-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
  }
  .action-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .action-sub-container {
    display: flex;
    gap: 16px;
  }
  .create-order-button {
    background-color: #0354a3;
    color: #fff;
    font-weight: 500;
    height: 46px !important;
  }
  .create-order-button:hover {
    color: #fff !important;
  }
  
  .tag-style {
    
    border: none;
    font-weight: 500;
    padding: 8px;
    border-radius: 8px;
}
.no-wrap table
{
  word-wrap: unset !important;
  text-wrap: nowrap !important;
  table-layout: auto !important;
}
.text-light-sm
{
  font-size: 14px;
  font-weight: normal;
  color: #6c7c8c;
}

.btn-success{
  border-radius: 8px;
  background-color: #008548;
  font-weight: 600;
  height: 48px !important;
  font-size: 14px;
}
.btn-outline-danger
{
  font-size: 14px;
  font-weight: 600;
  height: 48px !important;
}
.btn-success:hover{
  border-radius: 8px;
  background-color: #008548;
  font-weight: 600;
  color:#fff !important;
}
.btn-outline-danger:hover
{
  font-weight: 600;
  color:#fff !important; 
}
.title-settle
{
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline !important;
    cursor: pointer;
}
.modal-title-settle
{
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;
  
}
.text-static-caption
{
    color: #0a243f;
    font-size: 14px;
    font-weight: normal;
}
.text-static-caption-bold
{
    color: #0a243f;
    font-size: 16px;
  font-weight: bold;
}