.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* light transparent background */
  z-index: 1000; /* ensure the loader is on top of other elements */
}

.full-page-loader > * {
  margin: auto;
}
.OTP-form
{
  display: flex;
  justify-content: center;
  align-items: center;
}