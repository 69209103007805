.input-style
{
    height: 48px;
    text-align: left;
}
.search-input-style ,.ddl-style .ant-select-selector
{
    height: 46px !important;
}

/* h5.ant-typography
{
    font-size: 14px;
} */
 .credit-strip-font
 {
    font-size: 14px;
 }
 .text-address-tag
 {
    font-size: 16px;
 }