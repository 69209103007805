.vehicle-submit-btn {
  height: 46px;
  width: 50%;
  font-weight: 500;
  background-color: #0354a3;
}
.vehicle-cancel-btn {
  height: 46px;
  width: 30%;
  font-weight: 500;
}
.pickup-address-wrapper {
  border-bottom: 12px solid #f1f1f2;
  padding: 12px 24px 24px 20px;
  display: flex;
  justify-content: space-between;
}
.choose-vehicle-header {
  font-size: 16px;
  font-weight: 500;
}
.choose-vehicle-address {
  width: 80%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  font-size: 1rem;
}
.choose-vehicle-change {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #2973f0;
}
.choose-vehicle-logistics {
  border-bottom: 12px solid #f1f1f2;
  padding: 20px 24px 16px 20px !important;
}
.logistics-partner-txt {
  font-size: 14px;
  line-height: 1.71;
}
.assign-vehicle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 24px 24px 20px;
}
.note-align {
  display: flex;
  gap: 20px;
  width: 80%;
  align-items: center;
}
.icon-style{
  width: 55px;
}
.styled-txt{
  line-height: 1.5;
  color: #6c7c8c;
  
  font-size: 14px;

}
.vehicle-add-button
{
  width: 100%;
  height: 52px;
  margin: 16px 0 16px;  
  border-radius: 8px;
  border: solid 1px #9da7b2;
  background-color: #fff;
  color: #2973f0;
  font-size: 16px;
  font-weight: 500;
}
.add-vehicle-type-request
{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  border-radius: 16px;
  padding: 16px;
  border: solid 1px #dedede;  
}
.vehicle-icon
{
  width: 80px;
}
.text-bold
{
font-weight: 600 !important;
}