.rejected-table tbody, .rejected-table .ant-table-footer {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .rejected-table tbody tr, .rejected-table .ant-table-footer {
    background-color: #e8e8e8; /* or any other style you want to apply */
  }
  
  .custom-tablehead th:last-child
  {
      padding-right: 32px !important;
  }
  .text-notpaid
  {
    font-size: 14px;
  font-weight: 500;
  color: #bf2600;
  }
  .text-upper
  {
    /* text-transform: capitalize; */
  }