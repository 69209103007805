.view-detail-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.view-detail-status {
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.view-detail-btn {
  font-size: 14px;
  font-weight: 500;
  height: 46px;
  padding: 0 24px;
}

.detail-activity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-invoice-wrapper {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.detail-heading {
  font-size: 16px;
  font-weight: 500;
}

.seller-view {
  font-weight: 500;
  color: #2973f0;
}

.comp-wrapper {
  display: flex;
  gap: 24px;
}

.detail-status {
  display: flex;
  gap: 24px;
  align-items: center;
}

.details-btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.detail-btn {
  height: 46px;
  padding: 0 24px;
  font-weight: 500;
}

.vehicle-notify {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.vehicle-text {
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  background-color: #f8e6b6;
  padding: 24px;
  width: 100%;
}

.user-profile {
  border: 1px solid #dedede;
  border-radius: 8px;
}

.assigned-heading {
  color: #0a243f;
  font-size: 14px;
  font-weight: normal;
}

.assigned-container {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.section-deliverychallan {

  height: 120px;
  padding: 24px;
  border-radius: 16px;
  background-color: #f8e6b6;
  border-color: #f8e6b6 !important;
}

.detail-subheading {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
  color: #0a243f;
}

.detail-heading {
  margin-top: 10px;
}

.shortly-alert-style {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 24px 24px 24px 24px;
}

.shortly-alert-style-yellow {
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
  border-radius: 16px;
  background-color: #f8e6b6;
  padding: 24px 24px 24px 24px;
}

.ant-table-footer {
  background: #fff !important;
}

.btn-primary {
  background-color: #015fe5 !important;
}

.mob-list-table.no-right-border table tbody tr td:first-child {
  border-right: 0px !important;
}

.ant-modal .ant-modal-header {
  border-radius: 16px 16px 0 0;
}

/*  (Mobiles) */
@media only screen and (max-width: 480px) {
  .view-detail-wrapper {
    padding: 0px !important;
  }

  .assigned-container {
    display: block !important;
  }

  .section-deliverychallan {
    height: unset !important;
  }

  .deliveryBy-wrapper {
    /* display: block !important; */
    width: 100%;

  }
}

.card-credit-link .ant-card-head-title {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
}

.text-heading {
  font-size: 32px;
  font-weight: 600;
}

.bg-bmp-square {
  background-color: #80a0e8;
  width: 96px;
  height: 96px; 
  margin-bottom: 40px;
  border-radius: 16px;
  word-wrap: break-word; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-bmp-square span{
  color: #fff !important;
  font-weight: 600;
}
.bg-bmp-square-dark
{
  background-color: #1e1e20;
  width: 96px;
  height: 96px; 
  margin-bottom: 40px;
  border-radius: 16px;
  word-wrap: break-word; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-caption
{
  font-size: 16px;
  font-weight: 600;
}
.text-detail
{
  font-size: 16px;
  color: #0a243f;
}
.no-border
{
  border: 0 !important;
}
.top-credit-strip .ant-card-head
{
  background-image: linear-gradient(96deg, #e8f6c6 25%, #d1f4fa 103%);
}

  .top-credit-strip,.top-store-strip,.card-credit-success
  {  
    padding: 0px !important;
  }
  .credit-bg
  {
    margin: 0 8px 0 0;
  padding: 7.8px 12.1px 8.3px 12.6px;
  border-radius: 14px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  background-color: #1e1e20;
  }
 
  .top-store-strip .ant-card-head
{
  background: #f8e6b6;
}
.bg-green
{
 background-color: #e9fdf2;
 font-size: 14px;
 font-weight: 500;
}
.bt-card-common
{
  border-radius: 8px;
  padding: 16px;
}
.card-credit-success .ant-card-head
{
  
  background-image: linear-gradient(96deg, #285b45 5%, #4fb589 30%);

}
.mob-address-card
{
  /* border-left :8px solid #4fb589; */
}
.store-bg
{
  font-size: 14px;
  font-weight: 500;
}
.copytext-btn
{
  background-color: #535dd8 !important;
  color: #fff !important
}
.copytext-alignment
{
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.text-blue
{
  color: #0354a3;
  
}
.pt-6
{
  padding-top: 60px;
}
.no-strip .ant-card-head
{
  display: none !important;
}
.ml-1
{
  margin-left: 2px !important;
}
.text-invoice-billing
{
  font-style: italic;
  font-size: 16px;
  color: #bf2500 !important;
}
.card-routingstore
{
  border-radius: 16px;
  background-color: #fdf4de;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.bg-yellow
{
  background-color: #f8e6b6;
}
.badge-info-text
{
  border-radius: 8px;
  background-color: #f0483e !important;
  color: #fff;
  padding: 4px 12px 4px;
  position: absolute;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 14px;
}
.bt-card-height
{
  height: 100px;
}
.ddl-width 
{
  width: 220px !important;
  text-align: left;
}
.ddl-width .ant-select-selection-placeholder
{
  color: #0a243f !important;
}
.btn-payment
{
  background-color: #0354a3;
  color: #fff;
}
.btn-payment:hover
{
  background-color: #0354a3 !important;
  color: #fff !important;
}
.status-tag-style
{
margin-top: 2px !important;
}
.card-green-border .ant-card-body
{
  border: solid 2px #4fb589;
}
.hyperlink
{
  text-decoration: underline;
  color: #0354a3;
}
.table-product-wrapper {
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
}
.ddl-custom-invoice .ant-select-selection-placeholder
{
font-weight: 500;
}
.cls-invoice-float
{
  align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}
.post-conversion-status
{
  padding: 24px 24px 24px 24px;
  border-radius: 16px;
  background-color: #f3f2ef;
}
.text-bold-heading
{
  font-size: 16px;
  font-weight: 600;
  color: #0a243f;
}