.SalesReport-wrapper{
padding: 2rem;
display: flex;
flex-direction: column;
gap: 32px;
}
/*  (Mobiles) */
@media only screen and (max-width: 480px){
.SalesReport-wrapper
{
padding:  0px !important;
}
}