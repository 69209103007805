
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    line-height: 1.5;
}

.text-danger {
    color: #dc3545 !important;
}
.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.card-design
{
    border-radius: 16px !important;
    border: solid 1.5px #dedede !important;
    box-shadow:  unset !important;
    display: block !important ;
    margin-bottom: 20px !important;
}
.card-design .card-header
{
    border-bottom: 1px solid #eee !important;
    padding-top :0px !important;
    padding-bottom: 20px;;
}
.text-header
{
    font-size: 20px;
  font-weight: 600;
}
.text-head
{
    font-size: 16px;
  font-weight: 600;
}
.text-head-light
{
    font-size: 14px;
    font-weight: normal;
}
.row-style
{
    display: flex;
    align-items: center;
   
}
.mr-2{
    margin-right: 10px ;
}
.card-design .ant-card-body
{
padding: 0px !important;
border-radius: 0px !important;
}
.address-content
{
    padding: 16px 0px 16px 16px;
  border-radius: 8px;
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0px 10px 0px;
}
.google-link
{
    display: flex;
    padding: 16px 0px 16px 0px;
    border-radius: 8px;   
    justify-content: space-between;
    font-weight: 500
}
.dot {
    height: 5px;
    width: 5px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}
.btn-outline-nocolor
{   
    height: 48px;
    border-radius: 8px;
    border: solid 1px #9da7b2;
    background-color: #fff;
    color: #0a243f;
    font-weight: 600;
  
}

.password-change-container
{
    padding: 20px !important;
    display: block !important;
}
.footer-bottom-border-line
{
    box-shadow: unset !important;
}
.member-name
{
    color: #0a243f;
    font-size: 16px;

}
.member-email
{
    font-size: 14px;
    color: #0a243f;    
}
.btn-blank
{
    
    border :0px !important;
}

.member_img {
    width: 40px;
    height: 40px;
    margin: 12px;
    padding: 9px 10px;
    border-radius: 20px;
    background-color: #cce5e5;
    color: #0354a3;
}

.ant-modal-confirmation .ant-modal-confirm-btns
{
   
    display: flex;
   justify-content: center;
    background: transparent;
    padding: 12px;
}
.ant-modal-confirmation .anticon-exclamation-circle
{
    display: none;
}
.ant-modal-confirmation .ant-modal-confirm-content
{
    padding: 12px;
    margin: 18px;
    width: 100%;
    text-align: center;
}

.btn-del-danger
{
    /* width: 222px; */
    height: 46px;
    margin: 0 0 0 16px;
    padding: 12px 32px 12px;
    border-radius: 8px;
    background-color: #de350b;
}
.btn-del-danger:hover
{
    background-color: #de350b !important;
}
.order-cancel-btn-style:hover
{
    background-color: #fff !important;
    color: rgb(222, 53, 11);
}
.no-border-class ,.no-border-class .ant-select-selector ,.no-border-class .ant-select-item .ant-select-item-option
{
    border: none !important;
    background-color: transparent !important;
}
.table-member
{
    border-radius: 16px;
    border: solid 1.5px #dedede;
}
.time-picker-control
{
 width: 200px;
    height: 46px;
    margin: 8px 0 0;
    padding: 14px 20px 16px 12px;
    border-radius: 8px;
    border: solid 1px #bac7d5;
}
.edit-link
{
    color: #0060e5;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}
.btn-preview
{
    height: 42px;
    /* padding: 12px 24px 12px; */
    border-radius: 8px;
    background-color: #015fe5;
}
.btn-cancel-link
{
    height: 42px;
    border-radius: 8px;
}
.btn-preview span
{
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.upload-image-details
{
    padding: 0px 12px 12px 16px;
    border-radius: 8px;
    border: solid 1.5px #dedede;
    background-color: #fff;
    width: 152px !important;
    height: 152px !important;
}
.upload-image-details .ant-upload.ant-upload-select
{
    background: #fff !important;
    padding: 16px !important;
    border: 0px !important;
    width: 130px !important;
    height: 140px !important;

}
.txt-recommended
{
    color: #0a243f;
    font-size: 14px;
}
.card-QR-code
{
    border: 2px solid red !important;
    border-radius: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-QR-code .ant-card-body{

    text-align: center;
   
}
.card-bankdetails
{
    /* border: 2px solid red !important; */
    border-radius: 16px;
}
.card-bankdetails  .ant-card-body,.card-QR-code .ant-card-body,.card-terms-static .ant-card-body
{
    padding: 0px !important;
}
.text-caption-title:not(span)
{
font-size: 14px;
font-weight: 600;
}
.text-caption-details
{
    font-size: 12px !important;
    font-weight: 500  !important;
}
.save-link
{
    margin-left: 6px;
}
.terms-notes-strip
{
    padding: 16px;
  border-radius: 8px;
  background-color: #f2f2f2;
}

.select-custom-dropdown
{
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    padding: 4px 11px;
    border-radius: 6px;
}