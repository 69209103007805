.ant-modal .ant-modal-content {
  padding: 0px;
}
.ant-modal-header {
  padding: 18px;
  border-bottom: 12px solid #f1f1f2;
  margin-bottom: 20px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0354a3 !important;
  border-color: #0354a3 !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #0354a3 !important;
}
.ant-checkbox-input:checked + .ant-checkbox-inner {
  border-color: #0354a3 !important;
}
:where(.css-dev-only-do-not-override-txh9fw).ant-checkbox-checked:after {
  border: 2px solid #0354a3 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #0354a3 !important;
}
.Confirm-Order-model 
{
  margin-top: 20px;
  padding: 0px !important;
}
.Confirm-Order-model .ant-card-head
{
height: 30px !important;
min-height: 30px !important;
}
.Confirm-Order-model .ant-card-head
{
 

 background-image: linear-gradient(92deg, #1e1e20 -14%, #68686a 87%);
 border-top-left-radius: 16px;
 border-top-right-radius: 16px;
 object-fit: contain;
}
.Confirm-Order-model .ant-card-body
{
  background-image: linear-gradient(118deg, #e8f6c6 22%, #d1f4fa 109%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  /* height: 28vh; */
}
.card-content-para
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.bg-light-credit
{
  border-radius: 12px;
  background-color: #fff;
  padding: 12px 12px 2px;
  margin-bottom: 10px;
  opacity: 0.8;

}
.btn-caption-step
{
  padding: 16px;
  border-radius: 12px;
  background-color: #f3f2ef;
}
.text-strong
{
  font-weight: bold !important;
}