.ant-card-meta-detail
{
    margin: -50px;
    background: #fff;
    padding: 20px;
}
.ant-card-cover
{
    display: flex;
    justify-content: center;
}