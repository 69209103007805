

*,
::after,
::before {
    box-sizing: border-box
}

html {
    /* font-family: Inter !important; */
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Inter' !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-size: 1rem;
    text-align: left;
    font-family: 'Inter' !important;
    background: #fff !important;
}

.mob-seller-play {
    width: 48px !important;
    position: absolute;
    bottom: 13px;
    right: 16px;
    height: 48px;
    font-family: 'Inter' !important;
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

dl,
ol,
p,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

.card-login-box-style.login-otp-style {
    right: 106px;
    border-radius: 16px;
    box-shadow: 0 13px 36px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    padding: 25px;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 35%;
    margin: auto;
    display: table
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote,
figure {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    text-decoration: none;
    background-color: transparent
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

img {
    vertical-align: middle;
    border-style: none
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: -webkit-focus-ring-color auto 5px
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1.2
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 7rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 2.8125rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 2.125rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
}

.blockquote-footer::before {
    content: "— "
}

.mb-10 {
    margin-bottom: 10px
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fafafa;
    border: 1px solid #dee2e6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #6c757d
}

code {
    font-size: 87.5%;
    color: #e91e63;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem;
    box-shadow: inset 0 -.1rem 0 rgba(0, 0, 0, .25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500;
    box-shadow: none
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    border-top: 0px !important;
    padding: 0px !important;
}

.header-logo {
    width: 100% !important
}

@media(min-width: 576px) {
    .container {
        max-width: 540px
    }
    .banner-section {
        background-size: 45% !important;
    }
}

@media(min-width: 768px) {
    .home-registration {
        width: 420px !important
    }

    .container {
        max-width: 720px
    }

    .banner-section {
        background-size: 45% !important;
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 960px
    }
    .banner-section {
        background-size: 35% !important;
    }
}

@media(min-width: 1200px) {
    .container {
        /* max-width: 1320px */
           max-width: 1120px
    }
    .banner-section {
        background-size: 35% !important;
    }
    

}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.3333333333%
}

.offset-2 {
    margin-left: 16.6666666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.3333333333%
}

.offset-5 {
    margin-left: 41.6666666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.3333333333%
}

.offset-8 {
    margin-left: 66.6666666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.3333333333%
}

.offset-11 {
    margin-left: 91.6666666667%
}

@media(min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.3333333333%
    }

    .offset-md-2 {
        margin-left: 16.6666666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }

    .offset-md-5 {
        margin-left: 41.6666666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.3333333333%
    }

    .offset-md-8 {
        margin-left: 66.6666666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.3333333333%
    }

    .offset-md-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: .4375rem 0;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff !important;
    box-shadow: none;
    border: 1px solid #ced4da !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.accordion-body {
    font-size: 20px;
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #495057;
    border-color: #9acffa;
    outline: 0;
    box-shadow: none, 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.form-control::-webkit-input-placeholder {
    opacity: 1
}

.form-control::-moz-placeholder {
    opacity: 1
}

.form-control:-ms-input-placeholder {
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: rgba(0, 0, 0, 0)
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.4375rem+1px);
    padding-bottom: calc(.4375rem+1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5625rem+1px);
    padding-bottom: calc(.5625rem+1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.25rem+1px);
    padding-bottom: calc(.25rem+1px);
    font-size: .875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .4375rem;
    padding-bottom: .4375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em+.8125rem+2px);
    padding: .25rem 0;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(1.5em+2.25rem+2px);
    padding: .5625rem 0;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #4caf50
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(76, 175, 80, .9);
    border-radius: .25rem
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #4caf50;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #4caf50;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
    display: block
}

.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #4caf50
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #4caf50
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #4caf50
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #6ec071;
    background-color: #6ec071
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #4caf50
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #4caf50
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #f44336
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(244, 67, 54, .9);
    border-radius: .25rem
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #f44336;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44336 'viewBox='-2-277'%3e%3cpathstroke='%23f44336'd='M00l33m0-3L03'/%3e%3ccircler='.5'/%3e%3ccirclecx='3'r='.5'/%3e%3ccirclecy='3'r='.5'/%3e%3ccirclecx='3'cy='3'r='.5'/%3e%3c/svg%3E"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #f44336;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44336' viewBox='-2-277'%3e%3cpathstroke='%23f44336'd='M00l33m0-3L03'/%3e%3ccircler='.5'/%3e%3ccirclecx='3'r='.5'/%3e%3ccirclecy='3'r='.5'/%3e%3ccirclecx='3'cy='3'r='.5'/%3e%3c/svg%3E") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
    display: block
}

.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #f44336
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #f44336
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: #f44336
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    border-color: #f77066;
    background-color: #f77066
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #f44336
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #f44336
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media(min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    max-width: unset !important;
    height: unset !important;
    display: inline-block;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65;
    box-shadow: none
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    box-shadow: none
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .25)
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3;
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background-color: #0c83e2;
    border-color: #0c7cd5
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none, 0 0 0 .2rem rgba(66, 166, 245, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0c7cd5;
    border-color: #0b75c9
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(66, 166, 245, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    box-shadow: none
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none, 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: none
}

.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: none, 0 0 0 .2rem rgba(103, 187, 106, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(103, 187, 106, .5)
}

.btn-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow: none
}

.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: none, 0 0 0 .2rem rgba(38, 198, 218, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 198, 218, .5)
}

.btn-warning {
    color: #212529;
    background-color: #ffeb3b;
    border-color: #ffeb3b;
    box-shadow: none
}

.btn-warning:hover {
    color: #212529;
    background-color: #ffe715;
    border-color: #ffe608
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: none, 0 0 0 .2rem rgba(222, 205, 56, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffeb3b;
    border-color: #ffeb3b
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffe608;
    border-color: #fae100
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 205, 56, .5)
}

.btn-danger {
    color: #fff;
    background-color: #de350b;
    border-color: #de350b;
    box-shadow: none
}

.btn-danger:hover {
    color: #fff !important;
    background-color: #de350b;
    border-color: #de350b
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: none, 0 0 0 .2rem rgba(246, 95, 84, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(246, 95, 84, .5)
}

.btn-light {
    color: #0a243f !important;
    background-color: #f8f9fa !important;
    border: solid 1px #bac7d5 !important;
    box-shadow: none !important;
    text-transform: capitalize !important
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: none, 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: none
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: none, 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-outline-primary {
    color: #2196f3;
    border-color: #2196f3
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #2196f3;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .5)
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
    color: #4caf50;
    border-color: #4caf50
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .5)
}

.btn-outline-info {
    color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .5)
}

.btn-outline-warning {
    color: #ffeb3b;
    border-color: #ffeb3b
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffeb3b;
    border-color: #ffeb3b
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 235, 59, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffeb3b;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffeb3b;
    border-color: #ffeb3b
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 235, 59, .5)
}

.btn-outline-danger {
    color: #f44336;
    border-color: #f44336
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
    font-weight: 400;
    color: #9c27b0;
    text-decoration: none
}

.btn-link:hover {
    color: #641971;
    text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #999;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 1.125rem 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .40625rem 1.25rem;
    font-size: .875rem;
    line-height: 1.5
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media(min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
    vertical-align: 0
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
    vertical-align: 0
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .625rem 1.25rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2196f3
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent
}

.dropdown-header {
    display: block;
    padding: .5rem 1.25rem;
    margin-bottom: 0;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .625rem 1.25rem;
    color: #212529
}

.btn-group,
.btn-group-vertical {
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .9375rem;
    padding-left: .9375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.6875rem;
    padding-left: 1.6875rem
}

.btn-group.show .dropdown-toggle,
.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .4375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em+2.25rem+2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5625rem 0;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em+.8125rem+2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem 0;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #2196f3;
    background-color: #2196f3;
    box-shadow: none
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none, 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #9acffa
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #cae6fc;
    border-color: #cae6fc;
    box-shadow: none
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #adb5bd;
    box-shadow: none
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50%/50% 50% no-repeat
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%23ffffff'd='M6.564.75l-3.593.612-1.538-1.55L04.262.9747.2582.193z'/%3e%3c/svg%3e") */
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #2196f3;
    background-color: #2196f3;
    box-shadow: none
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0044'%3e%3cpathstroke='%23ffffff'd='M02h4'/%3e%3c/svg%3e") */
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(33, 150, 243, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(33, 150, 243, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4-488'%3e%3ccircler='3'fill='%23ffffff'/%3e%3c/svg%3e") */
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(33, 150, 243, .5)
}

.custom-switch {
    padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem+2px);
    left: calc(-2.25rem+2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: rgba(0, 0, 0, 0);
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(33, 150, 243, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em+.875rem+2px);
    padding: .4375rem 1rem .4375rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat rgba(0,0,0,0); */
    border: 1px solid #d2d2d2;
    border-radius: .25rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #9acffa;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: rgba(0, 0, 0, 0)
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0;
    background-image: none
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
}

.custom-select::-ms-expand {
    display: none
}

.custom-select-sm {
    height: calc(1.5em+.8125rem+2px);
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: 0;
    font-size: .875rem
}

.custom-select-lg {
    height: calc(1.5em+2.25rem+2px);
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    padding-left: 0;
    font-size: 1.25rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em+.875rem+2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em+.875rem+2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #9acffa;
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em+.875rem+2px);
    padding: .4375rem 0;
    font-weight: 400;
    line-height: 1.3;
    color: #495057;
    background-color: transparent;
    border: 0 solid #d2d2d2;
    border-radius: 0;
    box-shadow: none
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em+.875rem);
    padding: .4375rem 0;
    line-height: 1.3;
    color: #495057;
    content: "Browse";
    background-color: transparent;
    border-left: inherit;
    border-radius: 0
}

.custom-range {
    width: 100%;
    height: calc(1rem+.4rem);
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fafafa, 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #2196f3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {

    .collapsing,
    .custom-switch .custom-control-label::after {
        transition: none
    }

    .custom-range::-webkit-slider-thumb {
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #cae6fc
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196f3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #cae6fc
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #2196f3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #cae6fc
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fafafa;
    border-color: #dee2e6 #dee2e6 #fafafa
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #2196f3
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: center center/100% 100% no-repeat
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #eee
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #eee
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media(min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group>.card {
    margin-bottom: 15px
}

@media(min-width: 576px) {
    .card-group {
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }

    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #9c27b0;
    background-color: transparent;
    border: 0 solid #dee2e6
}

.page-link:hover {
    z-index: 2;
    color: #641971;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #2196f3;
    border-color: #2196f3
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 0;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem 0;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #2196f3
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #0c7cd5
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(33, 150, 243, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
    background-color: #545b62
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.badge-success {
    color: #fff;
    background-color: #4caf50
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #3d8b40
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .5)
}

.badge-info {
    color: #fff;
    background-color: #00bcd4
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #008fa1
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .5)
}

.badge-warning {
    color: #212529;
    background-color: #ffeb3b
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #212529;
    background-color: #ffe608
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 235, 59, .5)
}

.badge-danger {
    color: #fff;
    background-color: #f44336
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #ea1c0d
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .5)
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

a.badge-light:focus,
a.badge-light:hover {
    color: #212529;
    background-color: #dae0e5
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.badge-dark {
    color: #fff;
    background-color: #343a40
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #1d2124
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem
}

@media(min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    margin-bottom: 1rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 500
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #114e7e;
    background-color: #d3eafd;
    border-color: #c1e2fc
}

.alert-primary hr {
    border-top-color: #a9d7fb
}

.alert-primary .alert-link {
    color: #0b3251
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db
}

.alert-secondary hr {
    border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
    color: #202326
}

.alert-success {
    background-color: #dbefdc;
    border-color: #cde9ce
}

.alert-success hr {
    border-top-color: #bbe1bd
}

.alert-success .alert-link {
    color: #18381a
}

.alert-info {
    background-color: #ccf2f6;
    border-color: #b8ecf3
}

.alert-info hr {
    border-top-color: #a2e6ef
}

.alert-info .alert-link {
    color: #00353b
}

.alert-warning {
    background-color: #fffbd8;
    border-color: #fff9c8
}

.alert-warning hr {
    border-top-color: #fff6af
}

.alert-warning .alert-link {
    color: #5c5415
}

.alert-danger {
    background-color: #fdd9d7;
    border-color: #fccac7
}

.alert-danger hr {
    border-top-color: #fbb3af
}

.alert-danger .alert-link {
    color: #551713
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca
}

.alert-dark hr {
    border-top-color: #b9bbbe
}

.alert-dark .alert-link {
    color: #040505
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media(min-width: 576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media(min-width: 768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media(min-width: 992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media(min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media(min-width: 768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media(min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}


.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media(min-width: 576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width: 768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width: 992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }

    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 500 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0a6ebd !important
}

.text-secondary {
    color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #494f54 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #357a38 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #007888 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #eed500 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #d2190b !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #121416 !important
}

.text-body {
    color: #212529 !important
}

.bmd-help,
.text-muted {
    color: #6c757d !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #adb5bd;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container,
    body {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #dee2e6 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: rgba(0, 0, 0, .06)
    }

    .table .thead-dark th {
        color: inherit;
        border-color: rgba(0, 0, 0, .06)
    }
}

a:focus,
button:focus {
    outline: 0
}

.bmd-layout-canvas {
    position: absolute;
    width: 100%;
    height: 100%
}

.bmd-layout-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.bmd-layout-header {
    z-index: 3;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-shrink: 0;
    justify-content: flex-start;
    width: 100%;
    max-height: 1000px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.bmd-layout-content {
    position: relative;
    z-index: 1;
    display: inline-block;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.bmd-layout-spacer {
    flex-grow: 1
}

.bmd-layout-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

@supports(pointer-events: auto) {
    .bmd-layout-backdrop {
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        transition-property: opacity;
        visibility: visible;
        pointer-events: none
    }
}

.btn,
.mat-button.btn,
.mat-raised-button.btn,
.mat-raised-button.btn:not([class*=mat-elevation-z]) {
    position: relative;
    padding: 12px 30px;
    margin: .3125rem 1px;
    min-width: auto;
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.42857143;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 0;
    cursor: pointer;
    border: 0;
    outline: 0;
    transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1);
    will-change: box-shadow, transform
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
.mat-button.btn.active.focus,
.mat-button.btn.active:focus,
.mat-button.btn.focus,
.mat-button.btn:active.focus,
.mat-button.btn:active:focus,
.mat-button.btn:focus,
.mat-raised-button.btn.active.focus,
.mat-raised-button.btn.active:focus,
.mat-raised-button.btn.focus,
.mat-raised-button.btn:active.focus,
.mat-raised-button.btn:active:focus,
.mat-raised-button.btn:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):focus {
    outline: 0
}

.btn.btn-primary,
.mat-button.btn.btn-primary,
.mat-raised-button.btn.btn-primary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary {
    color: #fff;
    background-color: #9c27b0;
    border-color: #9c27b0;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, .14), 0 3px 1px -2px rgba(156, 39, 176, .2), 0 1px 5px 0 rgba(156, 39, 176, .12)
}

.btn.btn-primary.focus,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.mat-button.btn.btn-primary.focus,
.mat-button.btn.btn-primary:focus,
.mat-button.btn.btn-primary:hover,
.mat-raised-button.btn.btn-primary.focus,
.mat-raised-button.btn.btn-primary:focus,
.mat-raised-button.btn.btn-primary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e
}

.btn.btn-primary.active,
.btn.btn-primary:active,
.mat-button.btn.btn-primary.active,
.mat-button.btn.btn-primary:active,
.mat-raised-button.btn.btn-primary.active,
.mat-raised-button.btn.btn-primary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active,
.open>.btn.btn-primary.dropdown-toggle,
.open>.mat-button.btn.btn-primary.dropdown-toggle,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle,
.show>.btn.btn-primary.dropdown-toggle,
.show>.mat-button.btn.btn-primary.dropdown-toggle,
.show>.mat-raised-button.btn.btn-primary.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9124a3;
    border-color: #701c7e;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, .14), 0 3px 1px -2px rgba(156, 39, 176, .2), 0 1px 5px 0 rgba(156, 39, 176, .12)
}

.btn.btn-primary.active.focus,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.btn.btn-primary:active.focus,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.mat-button.btn.btn-primary.active.focus,
.mat-button.btn.btn-primary.active:focus,
.mat-button.btn.btn-primary.active:hover,
.mat-button.btn.btn-primary:active.focus,
.mat-button.btn.btn-primary:active:focus,
.mat-button.btn.btn-primary:active:hover,
.mat-raised-button.btn.btn-primary.active.focus,
.mat-raised-button.btn.btn-primary.active:focus,
.mat-raised-button.btn.btn-primary.active:hover,
.mat-raised-button.btn.btn-primary:active.focus,
.mat-raised-button.btn.btn-primary:active:focus,
.mat-raised-button.btn.btn-primary:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active:hover,
.open>.btn.btn-primary.dropdown-toggle.focus,
.open>.btn.btn-primary.dropdown-toggle:focus,
.open>.btn.btn-primary.dropdown-toggle:hover,
.open>.mat-button.btn.btn-primary.dropdown-toggle.focus,
.open>.mat-button.btn.btn-primary.dropdown-toggle:focus,
.open>.mat-button.btn.btn-primary.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle:hover,
.show>.btn.btn-primary.dropdown-toggle.focus,
.show>.btn.btn-primary.dropdown-toggle:focus,
.show>.btn.btn-primary.dropdown-toggle:hover,
.show>.mat-button.btn.btn-primary.dropdown-toggle.focus,
.show>.mat-button.btn.btn-primary.dropdown-toggle:focus,
.show>.mat-button.btn.btn-primary.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-primary.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-primary.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-primary.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle:hover {
    color: #fff;
    background-color: #9124a3;
    border-color: #3f1048
}

.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #9c27b0
}

.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #9124a3
}

.btn.btn-primary.disabled.focus,
.btn.btn-primary.disabled:focus,
.btn.btn-primary:disabled.focus,
.btn.btn-primary:disabled:focus,
.mat-button.btn.btn-primary.disabled.focus,
.mat-button.btn.btn-primary.disabled:focus,
.mat-button.btn.btn-primary:disabled.focus,
.mat-button.btn.btn-primary:disabled:focus,
.mat-raised-button.btn.btn-primary.disabled.focus,
.mat-raised-button.btn.btn-primary.disabled:focus,
.mat-raised-button.btn.btn-primary:disabled.focus,
.mat-raised-button.btn.btn-primary:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:disabled:focus {
    background-color: #9c27b0;
    border-color: #9c27b0
}

.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover,
.mat-button.btn.btn-primary.disabled:hover,
.mat-button.btn.btn-primary:disabled:hover,
.mat-raised-button.btn.btn-primary.disabled:hover,
.mat-raised-button.btn.btn-primary:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:disabled:hover {
    background-color: #9c27b0;
    border-color: #9c27b0
}

.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.mat-button.btn.btn-primary:active,
.mat-button.btn.btn-primary:focus,
.mat-button.btn.btn-primary:hover,
.mat-raised-button.btn.btn-primary:active,
.mat-raised-button.btn.btn-primary:focus,
.mat-raised-button.btn.btn-primary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:hover {
    box-shadow: 0 14px 26px -12px rgba(156, 39, 176, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(156, 39, 176, .2)
}

.btn.btn-primary.btn-link,
.mat-button.btn.btn-primary.btn-link,
.mat-raised-button.btn.btn-primary.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.btn-link {
    background-color: transparent;
    color: #9c27b0;
    box-shadow: none
}

.btn.btn-primary.btn-link:active,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:hover,
.mat-button.btn.btn-primary.btn-link:active,
.mat-button.btn.btn-primary.btn-link:focus,
.mat-button.btn.btn-primary.btn-link:hover,
.mat-raised-button.btn.btn-primary.btn-link:active,
.mat-raised-button.btn.btn-primary.btn-link:focus,
.mat-raised-button.btn.btn-primary.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.btn-link:hover {
    background-color: transparent;
    color: #9c27b0
}

.btn.btn-secondary,
.mat-button.btn.btn-secondary,
.mat-raised-button.btn.btn-secondary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary {
    color: #333;
    background-color: #fafafa;
    border-color: #ccc;
    box-shadow: 0 2px 2px 0 rgba(250, 250, 250, .14), 0 3px 1px -2px rgba(250, 250, 250, .2), 0 1px 5px 0 rgba(250, 250, 250, .12)
}

.btn.btn-secondary.focus,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover,
.mat-button.btn.btn-secondary.focus,
.mat-button.btn.btn-secondary:focus,
.mat-button.btn.btn-secondary:hover,
.mat-raised-button.btn.btn-secondary.focus,
.mat-raised-button.btn.btn-secondary:focus,
.mat-raised-button.btn.btn-secondary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:hover {
    color: #333;
    background-color: #f2f2f2;
    border-color: #adadad
}

.btn.btn-secondary.active,
.btn.btn-secondary:active,
.mat-button.btn.btn-secondary.active,
.mat-button.btn.btn-secondary:active,
.mat-raised-button.btn.btn-secondary.active,
.mat-raised-button.btn.btn-secondary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:active,
.open>.btn.btn-secondary.dropdown-toggle,
.open>.mat-button.btn.btn-secondary.dropdown-toggle,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle,
.show>.btn.btn-secondary.dropdown-toggle,
.show>.mat-button.btn.btn-secondary.dropdown-toggle,
.show>.mat-raised-button.btn.btn-secondary.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle {
    color: #333;
    background-color: #f2f2f2;
    border-color: #adadad;
    box-shadow: 0 2px 2px 0 rgba(250, 250, 250, .14), 0 3px 1px -2px rgba(250, 250, 250, .2), 0 1px 5px 0 rgba(250, 250, 250, .12)
}

.btn.btn-secondary.active.focus,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary:active.focus,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active:hover,
.mat-button.btn.btn-secondary.active.focus,
.mat-button.btn.btn-secondary.active:focus,
.mat-button.btn.btn-secondary.active:hover,
.mat-button.btn.btn-secondary:active.focus,
.mat-button.btn.btn-secondary:active:focus,
.mat-button.btn.btn-secondary:active:hover,
.mat-raised-button.btn.btn-secondary.active.focus,
.mat-raised-button.btn.btn-secondary.active:focus,
.mat-raised-button.btn.btn-secondary.active:hover,
.mat-raised-button.btn.btn-secondary:active.focus,
.mat-raised-button.btn.btn-secondary:active:focus,
.mat-raised-button.btn.btn-secondary:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:active:hover,
.open>.btn.btn-secondary.dropdown-toggle.focus,
.open>.btn.btn-secondary.dropdown-toggle:focus,
.open>.btn.btn-secondary.dropdown-toggle:hover,
.open>.mat-button.btn.btn-secondary.dropdown-toggle.focus,
.open>.mat-button.btn.btn-secondary.dropdown-toggle:focus,
.open>.mat-button.btn.btn-secondary.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle:hover,
.show>.btn.btn-secondary.dropdown-toggle.focus,
.show>.btn.btn-secondary.dropdown-toggle:focus,
.show>.btn.btn-secondary.dropdown-toggle:hover,
.show>.mat-button.btn.btn-secondary.dropdown-toggle.focus,
.show>.mat-button.btn.btn-secondary.dropdown-toggle:focus,
.show>.mat-button.btn.btn-secondary.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-secondary.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-secondary.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-secondary.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle:hover {
    color: #333;
    background-color: #f2f2f2;
    border-color: #8c8c8c
}

.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #fafafa
}

.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f2f2f2
}

.btn.btn-secondary.disabled.focus,
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary:disabled.focus,
.btn.btn-secondary:disabled:focus,
.mat-button.btn.btn-secondary.disabled.focus,
.mat-button.btn.btn-secondary.disabled:focus,
.mat-button.btn.btn-secondary:disabled.focus,
.mat-button.btn.btn-secondary:disabled:focus,
.mat-raised-button.btn.btn-secondary.disabled.focus,
.mat-raised-button.btn.btn-secondary.disabled:focus,
.mat-raised-button.btn.btn-secondary:disabled.focus,
.mat-raised-button.btn.btn-secondary:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:disabled:focus {
    background-color: #fafafa;
    border-color: #ccc
}

.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover,
.mat-button.btn.btn-secondary.disabled:hover,
.mat-button.btn.btn-secondary:disabled:hover,
.mat-raised-button.btn.btn-secondary.disabled:hover,
.mat-raised-button.btn.btn-secondary:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:disabled:hover {
    background-color: #fafafa;
    border-color: #ccc
}

.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover,
.mat-button.btn.btn-secondary:active,
.mat-button.btn.btn-secondary:focus,
.mat-button.btn.btn-secondary:hover,
.mat-raised-button.btn.btn-secondary:active,
.mat-raised-button.btn.btn-secondary:focus,
.mat-raised-button.btn.btn-secondary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary:hover {
    box-shadow: 0 14px 26px -12px rgba(250, 250, 250, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(250, 250, 250, .2)
}

.btn.btn-secondary.btn-link,
.mat-button.btn.btn-secondary.btn-link,
.mat-raised-button.btn.btn-secondary.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.btn-link {
    background-color: transparent;
    color: #fafafa;
    box-shadow: none
}

.btn.btn-secondary.btn-link:active,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:hover,
.mat-button.btn.btn-secondary.btn-link:active,
.mat-button.btn.btn-secondary.btn-link:focus,
.mat-button.btn.btn-secondary.btn-link:hover,
.mat-raised-button.btn.btn-secondary.btn-link:active,
.mat-raised-button.btn.btn-secondary.btn-link:focus,
.mat-raised-button.btn.btn-secondary.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-secondary.btn-link:hover {
    background-color: transparent;
    color: #fafafa
}

.btn.btn-info,
.mat-button.btn.btn-info,
.mat-raised-button.btn.btn-info,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow: 0 2px 2px 0 rgba(0, 188, 212, .14), 0 3px 1px -2px rgba(0, 188, 212, .2), 0 1px 5px 0 rgba(0, 188, 212, .12)
}

.btn.btn-info.focus,
.btn.btn-info:focus,
.btn.btn-info:hover,
.mat-button.btn.btn-info.focus,
.mat-button.btn.btn-info:focus,
.mat-button.btn.btn-info:hover,
.mat-raised-button.btn.btn-info.focus,
.mat-raised-button.btn.btn-info:focus,
.mat-raised-button.btn.btn-info:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:hover {
    color: #fff;
    background-color: #00aec5;
    border-color: #008697
}

.btn.btn-info.active,
.btn.btn-info:active,
.mat-button.btn.btn-info.active,
.mat-button.btn.btn-info:active,
.mat-raised-button.btn.btn-info.active,
.mat-raised-button.btn.btn-info:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:active,
.open>.btn.btn-info.dropdown-toggle,
.open>.mat-button.btn.btn-info.dropdown-toggle,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle,
.show>.btn.btn-info.dropdown-toggle,
.show>.mat-button.btn.btn-info.dropdown-toggle,
.show>.mat-raised-button.btn.btn-info.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00aec5;
    border-color: #008697;
    box-shadow: 0 2px 2px 0 rgba(0, 188, 212, .14), 0 3px 1px -2px rgba(0, 188, 212, .2), 0 1px 5px 0 rgba(0, 188, 212, .12)
}

.btn.btn-info.active.focus,
.btn.btn-info.active:focus,
.btn.btn-info.active:hover,
.btn.btn-info:active.focus,
.btn.btn-info:active:focus,
.btn.btn-info:active:hover,
.mat-button.btn.btn-info.active.focus,
.mat-button.btn.btn-info.active:focus,
.mat-button.btn.btn-info.active:hover,
.mat-button.btn.btn-info:active.focus,
.mat-button.btn.btn-info:active:focus,
.mat-button.btn.btn-info:active:hover,
.mat-raised-button.btn.btn-info.active.focus,
.mat-raised-button.btn.btn-info.active:focus,
.mat-raised-button.btn.btn-info.active:hover,
.mat-raised-button.btn.btn-info:active.focus,
.mat-raised-button.btn.btn-info:active:focus,
.mat-raised-button.btn.btn-info:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:active:hover,
.open>.btn.btn-info.dropdown-toggle.focus,
.open>.btn.btn-info.dropdown-toggle:focus,
.open>.btn.btn-info.dropdown-toggle:hover,
.open>.mat-button.btn.btn-info.dropdown-toggle.focus,
.open>.mat-button.btn.btn-info.dropdown-toggle:focus,
.open>.mat-button.btn.btn-info.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle:hover,
.show>.btn.btn-info.dropdown-toggle.focus,
.show>.btn.btn-info.dropdown-toggle:focus,
.show>.btn.btn-info.dropdown-toggle:hover,
.show>.mat-button.btn.btn-info.dropdown-toggle.focus,
.show>.mat-button.btn.btn-info.dropdown-toggle:focus,
.show>.mat-button.btn.btn-info.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-info.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-info.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-info.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle:hover {
    color: #fff;
    background-color: #00aec5;
    border-color: #004b55
}

.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #00bcd4
}

.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #00aec5
}

.btn.btn-info.disabled.focus,
.btn.btn-info.disabled:focus,
.btn.btn-info:disabled.focus,
.btn.btn-info:disabled:focus,
.mat-button.btn.btn-info.disabled.focus,
.mat-button.btn.btn-info.disabled:focus,
.mat-button.btn.btn-info:disabled.focus,
.mat-button.btn.btn-info:disabled:focus,
.mat-raised-button.btn.btn-info.disabled.focus,
.mat-raised-button.btn.btn-info.disabled:focus,
.mat-raised-button.btn.btn-info:disabled.focus,
.mat-raised-button.btn.btn-info:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:disabled:focus {
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover,
.mat-button.btn.btn-info.disabled:hover,
.mat-button.btn.btn-info:disabled:hover,
.mat-raised-button.btn.btn-info.disabled:hover,
.mat-raised-button.btn.btn-info:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:disabled:hover {
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn.btn-info:active,
.btn.btn-info:focus,
.btn.btn-info:hover,
.mat-button.btn.btn-info:active,
.mat-button.btn.btn-info:focus,
.mat-button.btn.btn-info:hover,
.mat-raised-button.btn.btn-info:active,
.mat-raised-button.btn.btn-info:focus,
.mat-raised-button.btn.btn-info:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 188, 212, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 188, 212, .2)
}

.btn.btn-info.btn-link,
.mat-button.btn.btn-info.btn-link,
.mat-raised-button.btn.btn-info.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.btn-link {
    background-color: transparent;
    color: #00bcd4;
    box-shadow: none
}

.btn.btn-info.btn-link:active,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:hover,
.mat-button.btn.btn-info.btn-link:active,
.mat-button.btn.btn-info.btn-link:focus,
.mat-button.btn.btn-info.btn-link:hover,
.mat-raised-button.btn.btn-info.btn-link:active,
.mat-raised-button.btn.btn-info.btn-link:focus,
.mat-raised-button.btn.btn-info.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-info.btn-link:hover {
    background-color: transparent;
    color: #00bcd4
}

.btn.btn-success,
.mat-button.btn.btn-success,
.mat-raised-button.btn.btn-success,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, .14), 0 3px 1px -2px rgba(76, 175, 80, .2), 0 1px 5px 0 rgba(76, 175, 80, .12)
}

.btn.btn-success.focus,
.btn.btn-success:focus,
.btn.btn-success:hover,
.mat-button.btn.btn-success.focus,
.mat-button.btn.btn-success:focus,
.mat-button.btn.btn-success:hover,
.mat-raised-button.btn.btn-success.focus,
.mat-raised-button.btn.btn-success:focus,
.mat-raised-button.btn.btn-success:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c
}

.btn.btn-success.active,
.btn.btn-success:active,
.mat-button.btn.btn-success.active,
.mat-button.btn.btn-success:active,
.mat-raised-button.btn.btn-success.active,
.mat-raised-button.btn.btn-success:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:active,
.open>.btn.btn-success.dropdown-toggle,
.open>.mat-button.btn.btn-success.dropdown-toggle,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle,
.show>.btn.btn-success.dropdown-toggle,
.show>.mat-button.btn.btn-success.dropdown-toggle,
.show>.mat-raised-button.btn.btn-success.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, .14), 0 3px 1px -2px rgba(76, 175, 80, .2), 0 1px 5px 0 rgba(76, 175, 80, .12)
}

.btn.btn-success.active.focus,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.btn.btn-success:active.focus,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.mat-button.btn.btn-success.active.focus,
.mat-button.btn.btn-success.active:focus,
.mat-button.btn.btn-success.active:hover,
.mat-button.btn.btn-success:active.focus,
.mat-button.btn.btn-success:active:focus,
.mat-button.btn.btn-success:active:hover,
.mat-raised-button.btn.btn-success.active.focus,
.mat-raised-button.btn.btn-success.active:focus,
.mat-raised-button.btn.btn-success.active:hover,
.mat-raised-button.btn.btn-success:active.focus,
.mat-raised-button.btn.btn-success:active:focus,
.mat-raised-button.btn.btn-success:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:active:hover,
.open>.btn.btn-success.dropdown-toggle.focus,
.open>.btn.btn-success.dropdown-toggle:focus,
.open>.btn.btn-success.dropdown-toggle:hover,
.open>.mat-button.btn.btn-success.dropdown-toggle.focus,
.open>.mat-button.btn.btn-success.dropdown-toggle:focus,
.open>.mat-button.btn.btn-success.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle:hover,
.show>.btn.btn-success.dropdown-toggle.focus,
.show>.btn.btn-success.dropdown-toggle:focus,
.show>.btn.btn-success.dropdown-toggle:hover,
.show>.mat-button.btn.btn-success.dropdown-toggle.focus,
.show>.mat-button.btn.btn-success.dropdown-toggle:focus,
.show>.mat-button.btn.btn-success.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-success.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-success.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-success.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #255627
}

.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #4caf50
}

.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #47a44b
}

.btn.btn-success.disabled.focus,
.btn.btn-success.disabled:focus,
.btn.btn-success:disabled.focus,
.btn.btn-success:disabled:focus,
.mat-button.btn.btn-success.disabled.focus,
.mat-button.btn.btn-success.disabled:focus,
.mat-button.btn.btn-success:disabled.focus,
.mat-button.btn.btn-success:disabled:focus,
.mat-raised-button.btn.btn-success.disabled.focus,
.mat-raised-button.btn.btn-success.disabled:focus,
.mat-raised-button.btn.btn-success:disabled.focus,
.mat-raised-button.btn.btn-success:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:disabled:focus {
    background-color: #4caf50;
    border-color: #4caf50
}

.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover,
.mat-button.btn.btn-success.disabled:hover,
.mat-button.btn.btn-success:disabled:hover,
.mat-raised-button.btn.btn-success.disabled:hover,
.mat-raised-button.btn.btn-success:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:disabled:hover {
    background-color: #4caf50;
    border-color: #4caf50
}

.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:hover,
.mat-button.btn.btn-success:active,
.mat-button.btn.btn-success:focus,
.mat-button.btn.btn-success:hover,
.mat-raised-button.btn.btn-success:active,
.mat-raised-button.btn.btn-success:focus,
.mat-raised-button.btn.btn-success:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success:hover {
    box-shadow: 0 14px 26px -12px rgba(76, 175, 80, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(76, 175, 80, .2)
}

.btn.btn-success.btn-link,
.mat-button.btn.btn-success.btn-link,
.mat-raised-button.btn.btn-success.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.btn-link {
    background-color: transparent;
    color: #4caf50;
    box-shadow: none
}

.btn.btn-success.btn-link:active,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:hover,
.mat-button.btn.btn-success.btn-link:active,
.mat-button.btn.btn-success.btn-link:focus,
.mat-button.btn.btn-success.btn-link:hover,
.mat-raised-button.btn.btn-success.btn-link:active,
.mat-raised-button.btn.btn-success.btn-link:focus,
.mat-raised-button.btn.btn-success.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-success.btn-link:hover {
    background-color: transparent;
    color: #4caf50
}

.btn.btn-warning,
.mat-button.btn.btn-warning,
.mat-raised-button.btn.btn-warning,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(255, 152, 0, .14), 0 3px 1px -2px rgba(255, 152, 0, .2), 0 1px 5px 0 rgba(255, 152, 0, .12)
}

.btn.btn-warning.focus,
.btn.btn-warning:focus,
.btn.btn-warning:hover,
.mat-button.btn.btn-warning.focus,
.mat-button.btn.btn-warning:focus,
.mat-button.btn.btn-warning:hover,
.mat-raised-button.btn.btn-warning.focus,
.mat-raised-button.btn.btn-warning:focus,
.mat-raised-button.btn.btn-warning:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:hover {
    color: #fff;
    background-color: #f08f00;
    border-color: #c27400
}

.btn.btn-warning.active,
.btn.btn-warning:active,
.mat-button.btn.btn-warning.active,
.mat-button.btn.btn-warning:active,
.mat-raised-button.btn.btn-warning.active,
.mat-raised-button.btn.btn-warning:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:active,
.open>.btn.btn-warning.dropdown-toggle,
.open>.mat-button.btn.btn-warning.dropdown-toggle,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle,
.show>.btn.btn-warning.dropdown-toggle,
.show>.mat-button.btn.btn-warning.dropdown-toggle,
.show>.mat-raised-button.btn.btn-warning.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f08f00;
    border-color: #c27400;
    box-shadow: 0 2px 2px 0 rgba(255, 152, 0, .14), 0 3px 1px -2px rgba(255, 152, 0, .2), 0 1px 5px 0 rgba(255, 152, 0, .12)
}

.btn.btn-warning.active.focus,
.btn.btn-warning.active:focus,
.btn.btn-warning.active:hover,
.btn.btn-warning:active.focus,
.btn.btn-warning:active:focus,
.btn.btn-warning:active:hover,
.mat-button.btn.btn-warning.active.focus,
.mat-button.btn.btn-warning.active:focus,
.mat-button.btn.btn-warning.active:hover,
.mat-button.btn.btn-warning:active.focus,
.mat-button.btn.btn-warning:active:focus,
.mat-button.btn.btn-warning:active:hover,
.mat-raised-button.btn.btn-warning.active.focus,
.mat-raised-button.btn.btn-warning.active:focus,
.mat-raised-button.btn.btn-warning.active:hover,
.mat-raised-button.btn.btn-warning:active.focus,
.mat-raised-button.btn.btn-warning:active:focus,
.mat-raised-button.btn.btn-warning:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:active:hover,
.open>.btn.btn-warning.dropdown-toggle.focus,
.open>.btn.btn-warning.dropdown-toggle:focus,
.open>.btn.btn-warning.dropdown-toggle:hover,
.open>.mat-button.btn.btn-warning.dropdown-toggle.focus,
.open>.mat-button.btn.btn-warning.dropdown-toggle:focus,
.open>.mat-button.btn.btn-warning.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle:hover,
.show>.btn.btn-warning.dropdown-toggle.focus,
.show>.btn.btn-warning.dropdown-toggle:focus,
.show>.btn.btn-warning.dropdown-toggle:hover,
.show>.mat-button.btn.btn-warning.dropdown-toggle.focus,
.show>.mat-button.btn.btn-warning.dropdown-toggle:focus,
.show>.mat-button.btn.btn-warning.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-warning.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-warning.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-warning.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle:hover {
    color: #fff;
    background-color: #f08f00;
    border-color: #804c00
}

.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #ff9800
}

.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f08f00
}

.btn.btn-warning.disabled.focus,
.btn.btn-warning.disabled:focus,
.btn.btn-warning:disabled.focus,
.btn.btn-warning:disabled:focus,
.mat-button.btn.btn-warning.disabled.focus,
.mat-button.btn.btn-warning.disabled:focus,
.mat-button.btn.btn-warning:disabled.focus,
.mat-button.btn.btn-warning:disabled:focus,
.mat-raised-button.btn.btn-warning.disabled.focus,
.mat-raised-button.btn.btn-warning.disabled:focus,
.mat-raised-button.btn.btn-warning:disabled.focus,
.mat-raised-button.btn.btn-warning:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:disabled:focus {
    background-color: #ff9800;
    border-color: #ff9800
}

.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover,
.mat-button.btn.btn-warning.disabled:hover,
.mat-button.btn.btn-warning:disabled:hover,
.mat-raised-button.btn.btn-warning.disabled:hover,
.mat-raised-button.btn.btn-warning:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:disabled:hover {
    background-color: #ff9800;
    border-color: #ff9800
}

.btn.btn-warning:active,
.btn.btn-warning:focus,
.btn.btn-warning:hover,
.mat-button.btn.btn-warning:active,
.mat-button.btn.btn-warning:focus,
.mat-button.btn.btn-warning:hover,
.mat-raised-button.btn.btn-warning:active,
.mat-raised-button.btn.btn-warning:focus,
.mat-raised-button.btn.btn-warning:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning:hover {
    box-shadow: 0 14px 26px -12px rgba(255, 152, 0, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(255, 152, 0, .2)
}

.btn.btn-warning.btn-link,
.mat-button.btn.btn-warning.btn-link,
.mat-raised-button.btn.btn-warning.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.btn-link {
    background-color: transparent;
    color: #ff9800;
    box-shadow: none
}

.btn.btn-warning.btn-link:active,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:hover,
.mat-button.btn.btn-warning.btn-link:active,
.mat-button.btn.btn-warning.btn-link:focus,
.mat-button.btn.btn-warning.btn-link:hover,
.mat-raised-button.btn.btn-warning.btn-link:active,
.mat-raised-button.btn.btn-warning.btn-link:focus,
.mat-raised-button.btn.btn-warning.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-warning.btn-link:hover {
    background-color: transparent;
    color: #ff9800
}

.btn.btn-danger,
.mat-button.btn.btn-danger,
.mat-raised-button.btn.btn-danger,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, .14), 0 3px 1px -2px rgba(244, 67, 54, .2), 0 1px 5px 0 rgba(244, 67, 54, .12)
}

.btn.btn-danger.focus,
.btn.btn-danger:focus,
.btn.btn-danger:hover,
.mat-button.btn.btn-danger.focus,
.mat-button.btn.btn-danger:focus,
.mat-button.btn.btn-danger:hover,
.mat-raised-button.btn.btn-danger.focus,
.mat-raised-button.btn.btn-danger:focus,
.mat-raised-button.btn.btn-danger:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:hover {
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c
}

.btn.btn-danger.active,
.btn.btn-danger:active,
.mat-button.btn.btn-danger.active,
.mat-button.btn.btn-danger:active,
.mat-raised-button.btn.btn-danger.active,
.mat-raised-button.btn.btn-danger:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:active,
.open>.btn.btn-danger.dropdown-toggle,
.open>.mat-button.btn.btn-danger.dropdown-toggle,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle,
.show>.btn.btn-danger.dropdown-toggle,
.show>.mat-button.btn.btn-danger.dropdown-toggle,
.show>.mat-raised-button.btn.btn-danger.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c;
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, .14), 0 3px 1px -2px rgba(244, 67, 54, .2), 0 1px 5px 0 rgba(244, 67, 54, .12)
}

.btn.btn-danger.active.focus,
.btn.btn-danger.active:focus,
.btn.btn-danger.active:hover,
.btn.btn-danger:active.focus,
.btn.btn-danger:active:focus,
.btn.btn-danger:active:hover,
.mat-button.btn.btn-danger.active.focus,
.mat-button.btn.btn-danger.active:focus,
.mat-button.btn.btn-danger.active:hover,
.mat-button.btn.btn-danger:active.focus,
.mat-button.btn.btn-danger:active:focus,
.mat-button.btn.btn-danger:active:hover,
.mat-raised-button.btn.btn-danger.active.focus,
.mat-raised-button.btn.btn-danger.active:focus,
.mat-raised-button.btn.btn-danger.active:hover,
.mat-raised-button.btn.btn-danger:active.focus,
.mat-raised-button.btn.btn-danger:active:focus,
.mat-raised-button.btn.btn-danger:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:active:hover,
.open>.btn.btn-danger.dropdown-toggle.focus,
.open>.btn.btn-danger.dropdown-toggle:focus,
.open>.btn.btn-danger.dropdown-toggle:hover,
.open>.mat-button.btn.btn-danger.dropdown-toggle.focus,
.open>.mat-button.btn.btn-danger.dropdown-toggle:focus,
.open>.mat-button.btn.btn-danger.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle:hover,
.show>.btn.btn-danger.dropdown-toggle.focus,
.show>.btn.btn-danger.dropdown-toggle:focus,
.show>.btn.btn-danger.dropdown-toggle:hover,
.show>.mat-button.btn.btn-danger.dropdown-toggle.focus,
.show>.mat-button.btn.btn-danger.dropdown-toggle:focus,
.show>.mat-button.btn.btn-danger.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-danger.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-danger.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-danger.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle:hover {
    color: #fff;
    background-color: #f33527;
    border-color: #a21309
}

.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #f44336
}

.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #f33527
}

.btn.btn-danger.disabled.focus,
.btn.btn-danger.disabled:focus,
.btn.btn-danger:disabled.focus,
.btn.btn-danger:disabled:focus,
.mat-button.btn.btn-danger.disabled.focus,
.mat-button.btn.btn-danger.disabled:focus,
.mat-button.btn.btn-danger:disabled.focus,
.mat-button.btn.btn-danger:disabled:focus,
.mat-raised-button.btn.btn-danger.disabled.focus,
.mat-raised-button.btn.btn-danger.disabled:focus,
.mat-raised-button.btn.btn-danger:disabled.focus,
.mat-raised-button.btn.btn-danger:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:disabled:focus {
    background-color: #f44336;
    border-color: #f44336
}

.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover,
.mat-button.btn.btn-danger.disabled:hover,
.mat-button.btn.btn-danger:disabled:hover,
.mat-raised-button.btn.btn-danger.disabled:hover,
.mat-raised-button.btn.btn-danger:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:disabled:hover {
    background-color: #f44336;
    border-color: #f44336
}

.btn.btn-danger:active,
.btn.btn-danger:focus,
.btn.btn-danger:hover,
.mat-button.btn.btn-danger:active,
.mat-button.btn.btn-danger:focus,
.mat-button.btn.btn-danger:hover,
.mat-raised-button.btn.btn-danger:active,
.mat-raised-button.btn.btn-danger:focus,
.mat-raised-button.btn.btn-danger:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger:hover {
    box-shadow: 0 14px 26px -12px rgba(244, 67, 54, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(244, 67, 54, .2)
}

.btn.btn-danger.btn-link,
.mat-button.btn.btn-danger.btn-link,
.mat-raised-button.btn.btn-danger.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.btn-link {
    background-color: transparent;
    color: #f44336;
    box-shadow: none
}

.btn.btn-danger.btn-link:active,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:hover,
.mat-button.btn.btn-danger.btn-link:active,
.mat-button.btn.btn-danger.btn-link:focus,
.mat-button.btn.btn-danger.btn-link:hover,
.mat-raised-button.btn.btn-danger.btn-link:active,
.mat-raised-button.btn.btn-danger.btn-link:focus,
.mat-raised-button.btn.btn-danger.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-danger.btn-link:hover {
    background-color: transparent;
    color: #f44336
}

.btn.btn-rose,
.mat-button.btn.btn-rose,
.mat-raised-button.btn.btn-rose,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose {
    color: #fff;
    background-color: #e91e63;
    border-color: #e91e63;
    box-shadow: 0 2px 2px 0 rgba(233, 30, 99, .14), 0 3px 1px -2px rgba(233, 30, 99, .2), 0 1px 5px 0 rgba(233, 30, 99, .12)
}

.btn.btn-rose.focus,
.btn.btn-rose:focus,
.btn.btn-rose:hover,
.mat-button.btn.btn-rose.focus,
.mat-button.btn.btn-rose:focus,
.mat-button.btn.btn-rose:hover,
.mat-raised-button.btn.btn-rose.focus,
.mat-raised-button.btn.btn-rose:focus,
.mat-raised-button.btn.btn-rose:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:hover {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #b8124a
}

.btn.btn-rose.active,
.btn.btn-rose:active,
.mat-button.btn.btn-rose.active,
.mat-button.btn.btn-rose:active,
.mat-raised-button.btn.btn-rose.active,
.mat-raised-button.btn.btn-rose:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:active,
.open>.btn.btn-rose.dropdown-toggle,
.open>.mat-button.btn.btn-rose.dropdown-toggle,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle,
.show>.btn.btn-rose.dropdown-toggle,
.show>.mat-button.btn.btn-rose.dropdown-toggle,
.show>.mat-raised-button.btn.btn-rose.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #b8124a;
    box-shadow: 0 2px 2px 0 rgba(233, 30, 99, .14), 0 3px 1px -2px rgba(233, 30, 99, .2), 0 1px 5px 0 rgba(233, 30, 99, .12)
}

.btn.btn-rose.active.focus,
.btn.btn-rose.active:focus,
.btn.btn-rose.active:hover,
.btn.btn-rose:active.focus,
.btn.btn-rose:active:focus,
.btn.btn-rose:active:hover,
.mat-button.btn.btn-rose.active.focus,
.mat-button.btn.btn-rose.active:focus,
.mat-button.btn.btn-rose.active:hover,
.mat-button.btn.btn-rose:active.focus,
.mat-button.btn.btn-rose:active:focus,
.mat-button.btn.btn-rose:active:hover,
.mat-raised-button.btn.btn-rose.active.focus,
.mat-raised-button.btn.btn-rose.active:focus,
.mat-raised-button.btn.btn-rose.active:hover,
.mat-raised-button.btn.btn-rose:active.focus,
.mat-raised-button.btn.btn-rose:active:focus,
.mat-raised-button.btn.btn-rose:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:active:hover,
.open>.btn.btn-rose.dropdown-toggle.focus,
.open>.btn.btn-rose.dropdown-toggle:focus,
.open>.btn.btn-rose.dropdown-toggle:hover,
.open>.mat-button.btn.btn-rose.dropdown-toggle.focus,
.open>.mat-button.btn.btn-rose.dropdown-toggle:focus,
.open>.mat-button.btn.btn-rose.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle:hover,
.show>.btn.btn-rose.dropdown-toggle.focus,
.show>.btn.btn-rose.dropdown-toggle:focus,
.show>.btn.btn-rose.dropdown-toggle:hover,
.show>.mat-button.btn.btn-rose.dropdown-toggle.focus,
.show>.mat-button.btn.btn-rose.dropdown-toggle:focus,
.show>.mat-button.btn.btn-rose.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-rose.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-rose.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-rose.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle:hover {
    color: #fff;
    background-color: #ea2c6d;
    border-color: #7b0c32
}

.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-rose.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #e91e63
}

.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.dropdown-toggle.bmd-btn-icon:hover {
    background-color: #ea2c6d
}

.btn.btn-rose.disabled.focus,
.btn.btn-rose.disabled:focus,
.btn.btn-rose:disabled.focus,
.btn.btn-rose:disabled:focus,
.mat-button.btn.btn-rose.disabled.focus,
.mat-button.btn.btn-rose.disabled:focus,
.mat-button.btn.btn-rose:disabled.focus,
.mat-button.btn.btn-rose:disabled:focus,
.mat-raised-button.btn.btn-rose.disabled.focus,
.mat-raised-button.btn.btn-rose.disabled:focus,
.mat-raised-button.btn.btn-rose:disabled.focus,
.mat-raised-button.btn.btn-rose:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:disabled:focus {
    background-color: #e91e63;
    border-color: #e91e63
}

.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover,
.mat-button.btn.btn-rose.disabled:hover,
.mat-button.btn.btn-rose:disabled:hover,
.mat-raised-button.btn.btn-rose.disabled:hover,
.mat-raised-button.btn.btn-rose:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:disabled:hover {
    background-color: #e91e63;
    border-color: #e91e63
}

.btn.btn-rose:active,
.btn.btn-rose:focus,
.btn.btn-rose:hover,
.mat-button.btn.btn-rose:active,
.mat-button.btn.btn-rose:focus,
.mat-button.btn.btn-rose:hover,
.mat-raised-button.btn.btn-rose:active,
.mat-raised-button.btn.btn-rose:focus,
.mat-raised-button.btn.btn-rose:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose:hover {
    box-shadow: 0 14px 26px -12px rgba(233, 30, 99, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(233, 30, 99, .2)
}

.btn.btn-rose.btn-link,
.mat-button.btn.btn-rose.btn-link,
.mat-raised-button.btn.btn-rose.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.btn-link {
    background-color: transparent;
    color: #e91e63;
    box-shadow: none
}

.btn.btn-rose.btn-link:active,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:hover,
.mat-button.btn.btn-rose.btn-link:active,
.mat-button.btn.btn-rose.btn-link:focus,
.mat-button.btn.btn-rose.btn-link:hover,
.mat-raised-button.btn.btn-rose.btn-link:active,
.mat-raised-button.btn.btn-rose.btn-link:focus,
.mat-raised-button.btn.btn-rose.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-rose.btn-link:hover {
    background-color: transparent;
    color: #e91e63
}

.btn,
.btn.btn-default,
.mat-button.btn,
.mat-button.btn.btn-default,
.mat-raised-button.btn,
.mat-raised-button.btn.btn-default,
.mat-raised-button.btn:not([class*=mat-elevation-z]),
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default {
    color: #fff;
    background-color: #999;
    border-color: #999;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, .14), 0 3px 1px -2px rgba(153, 153, 153, .2), 0 1px 5px 0 rgba(153, 153, 153, .12)
}

.btn.btn-default.focus,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn.focus,
.btn:focus,
.btn:hover,
.mat-button.btn.btn-default.focus,
.mat-button.btn.btn-default:focus,
.mat-button.btn.btn-default:hover,
.mat-button.btn.focus,
.mat-button.btn:focus,
.mat-button.btn:hover,
.mat-raised-button.btn.btn-default.focus,
.mat-raised-button.btn.btn-default:focus,
.mat-raised-button.btn.btn-default:hover,
.mat-raised-button.btn.focus,
.mat-raised-button.btn:focus,
.mat-raised-button.btn:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):hover {
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a
}

.btn.active,
.btn.btn-default.active,
.btn.btn-default:active,
.btn:active,
.mat-button.btn.active,
.mat-button.btn.btn-default.active,
.mat-button.btn.btn-default:active,
.mat-button.btn:active,
.mat-raised-button.btn.active,
.mat-raised-button.btn.btn-default.active,
.mat-raised-button.btn.btn-default:active,
.mat-raised-button.btn:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active,
.open>.btn.btn-default.dropdown-toggle,
.open>.btn.dropdown-toggle,
.open>.mat-button.btn.btn-default.dropdown-toggle,
.open>.mat-button.btn.dropdown-toggle,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle,
.open>.mat-raised-button.btn.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle,
.show>.btn.btn-default.dropdown-toggle,
.show>.btn.dropdown-toggle,
.show>.mat-button.btn.btn-default.dropdown-toggle,
.show>.mat-button.btn.dropdown-toggle,
.show>.mat-raised-button.btn.btn-default.dropdown-toggle,
.show>.mat-raised-button.btn.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle {
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, .14), 0 3px 1px -2px rgba(153, 153, 153, .2), 0 1px 5px 0 rgba(153, 153, 153, .12)
}

.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn.btn-default.active.focus,
.btn.btn-default.active:focus,
.btn.btn-default.active:hover,
.btn.btn-default:active.focus,
.btn.btn-default:active:focus,
.btn.btn-default:active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.mat-button.btn.active.focus,
.mat-button.btn.active:focus,
.mat-button.btn.active:hover,
.mat-button.btn.btn-default.active.focus,
.mat-button.btn.btn-default.active:focus,
.mat-button.btn.btn-default.active:hover,
.mat-button.btn.btn-default:active.focus,
.mat-button.btn.btn-default:active:focus,
.mat-button.btn.btn-default:active:hover,
.mat-button.btn:active.focus,
.mat-button.btn:active:focus,
.mat-button.btn:active:hover,
.mat-raised-button.btn.active.focus,
.mat-raised-button.btn.active:focus,
.mat-raised-button.btn.active:hover,
.mat-raised-button.btn.btn-default.active.focus,
.mat-raised-button.btn.btn-default.active:focus,
.mat-raised-button.btn.btn-default.active:hover,
.mat-raised-button.btn.btn-default:active.focus,
.mat-raised-button.btn.btn-default:active:focus,
.mat-raised-button.btn.btn-default:active:hover,
.mat-raised-button.btn:active.focus,
.mat-raised-button.btn:active:focus,
.mat-raised-button.btn:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active:hover,
.open>.btn.btn-default.dropdown-toggle.focus,
.open>.btn.btn-default.dropdown-toggle:focus,
.open>.btn.btn-default.dropdown-toggle:hover,
.open>.btn.dropdown-toggle.focus,
.open>.btn.dropdown-toggle:focus,
.open>.btn.dropdown-toggle:hover,
.open>.mat-button.btn.btn-default.dropdown-toggle.focus,
.open>.mat-button.btn.btn-default.dropdown-toggle:focus,
.open>.mat-button.btn.btn-default.dropdown-toggle:hover,
.open>.mat-button.btn.dropdown-toggle.focus,
.open>.mat-button.btn.dropdown-toggle:focus,
.open>.mat-button.btn.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle:hover,
.open>.mat-raised-button.btn.dropdown-toggle.focus,
.open>.mat-raised-button.btn.dropdown-toggle:focus,
.open>.mat-raised-button.btn.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle:hover,
.show>.btn.btn-default.dropdown-toggle.focus,
.show>.btn.btn-default.dropdown-toggle:focus,
.show>.btn.btn-default.dropdown-toggle:hover,
.show>.btn.dropdown-toggle.focus,
.show>.btn.dropdown-toggle:focus,
.show>.btn.dropdown-toggle:hover,
.show>.mat-button.btn.btn-default.dropdown-toggle.focus,
.show>.mat-button.btn.btn-default.dropdown-toggle:focus,
.show>.mat-button.btn.btn-default.dropdown-toggle:hover,
.show>.mat-button.btn.dropdown-toggle.focus,
.show>.mat-button.btn.dropdown-toggle:focus,
.show>.mat-button.btn.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-default.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-default.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-default.dropdown-toggle:hover,
.show>.mat-raised-button.btn.dropdown-toggle.focus,
.show>.mat-raised-button.btn.dropdown-toggle:focus,
.show>.mat-raised-button.btn.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle:hover {
    color: #fff;
    background-color: #919191;
    border-color: #595959
}

.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon,
.open>.btn.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-default.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: #999
}

.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open>.btn.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).dropdown-toggle.bmd-btn-icon:hover {
    background-color: #919191
}

.btn.btn-default.disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default:disabled.focus,
.btn.btn-default:disabled:focus,
.btn.disabled.focus,
.btn.disabled:focus,
.btn:disabled.focus,
.btn:disabled:focus,
.mat-button.btn.btn-default.disabled.focus,
.mat-button.btn.btn-default.disabled:focus,
.mat-button.btn.btn-default:disabled.focus,
.mat-button.btn.btn-default:disabled:focus,
.mat-button.btn.disabled.focus,
.mat-button.btn.disabled:focus,
.mat-button.btn:disabled.focus,
.mat-button.btn:disabled:focus,
.mat-raised-button.btn.btn-default.disabled.focus,
.mat-raised-button.btn.btn-default.disabled:focus,
.mat-raised-button.btn.btn-default:disabled.focus,
.mat-raised-button.btn.btn-default:disabled:focus,
.mat-raised-button.btn.disabled.focus,
.mat-raised-button.btn.disabled:focus,
.mat-raised-button.btn:disabled.focus,
.mat-raised-button.btn:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):disabled:focus {
    background-color: #999;
    border-color: #999
}

.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover,
.btn.disabled:hover,
.btn:disabled:hover,
.mat-button.btn.btn-default.disabled:hover,
.mat-button.btn.btn-default:disabled:hover,
.mat-button.btn.disabled:hover,
.mat-button.btn:disabled:hover,
.mat-raised-button.btn.btn-default.disabled:hover,
.mat-raised-button.btn.btn-default:disabled:hover,
.mat-raised-button.btn.disabled:hover,
.mat-raised-button.btn:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]):disabled:hover {
    background-color: #999;
    border-color: #999
}

.btn.btn-default:active,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn:active,
.btn:focus,
.btn:hover,
.mat-button.btn.btn-default:active,
.mat-button.btn.btn-default:focus,
.mat-button.btn.btn-default:hover,
.mat-button.btn:active,
.mat-button.btn:focus,
.mat-button.btn:hover,
.mat-raised-button.btn.btn-default:active,
.mat-raised-button.btn.btn-default:focus,
.mat-raised-button.btn.btn-default:hover,
.mat-raised-button.btn:active,
.mat-raised-button.btn:focus,
.mat-raised-button.btn:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]):active,
.mat-raised-button.btn:not([class*=mat-elevation-z]):focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]):hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(153, 153, 153, .2)
}

.btn.btn-default.btn-link,
.btn.btn-link,
.mat-button.btn.btn-default.btn-link,
.mat-button.btn.btn-link,
.mat-raised-button.btn.btn-default.btn-link,
.mat-raised-button.btn.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link {
    background-color: transparent;
    color: #999;
    box-shadow: none
}

.btn.btn-default.btn-link:active,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:hover,
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover,
.mat-button.btn.btn-default.btn-link:active,
.mat-button.btn.btn-default.btn-link:focus,
.mat-button.btn.btn-default.btn-link:hover,
.mat-button.btn.btn-link:active,
.mat-button.btn.btn-link:focus,
.mat-button.btn.btn-link:hover,
.mat-raised-button.btn.btn-default.btn-link:active,
.mat-raised-button.btn.btn-default.btn-link:focus,
.mat-raised-button.btn.btn-default.btn-link:hover,
.mat-raised-button.btn.btn-link:active,
.mat-raised-button.btn.btn-link:focus,
.mat-raised-button.btn.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-default.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:hover {
    background-color: transparent;
    color: #999
}

.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover,
.mat-button.btn.btn-white,
.mat-button.btn.btn-white:focus,
.mat-button.btn.btn-white:hover,
.mat-raised-button.btn.btn-white,
.mat-raised-button.btn.btn-white:focus,
.mat-raised-button.btn.btn-white:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-white,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-white:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-white:hover {
    background-color: #fff;
    color: #999
}

.btn.btn-white.btn-link,
.mat-button.btn.btn-white.btn-link,
.mat-raised-button.btn.btn-white.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-white.btn-link {
    color: #fff;
    background: 0;
    box-shadow: none
}

.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover,
.mat-button.btn.btn-link:active,
.mat-button.btn.btn-link:focus,
.mat-button.btn.btn-link:hover,
.mat-raised-button.btn.btn-link:active,
.mat-raised-button.btn.btn-link:focus,
.mat-raised-button.btn.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:hover {
    text-decoration: none !important
}

.btn-group-raised .btn.btn-link,
.btn-group-raised .btn.btn-link.active,
.btn-group-raised .btn.btn-link:active,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:hover,
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled],
.btn-group-raised .mat-button.btn.btn-link,
.btn-group-raised .mat-button.btn.btn-link.active,
.btn-group-raised .mat-button.btn.btn-link:active,
.btn-group-raised .mat-button.btn.btn-link:focus,
.btn-group-raised .mat-button.btn.btn-link:hover,
.btn-group-raised .mat-button.btn.disabled,
.btn-group-raised .mat-button.btn:disabled,
.btn-group-raised .mat-button.btn[disabled],
.btn-group-raised .mat-raised-button.btn.btn-link,
.btn-group-raised .mat-raised-button.btn.btn-link.active,
.btn-group-raised .mat-raised-button.btn.btn-link:active,
.btn-group-raised .mat-raised-button.btn.btn-link:focus,
.btn-group-raised .mat-raised-button.btn.btn-link:hover,
.btn-group-raised .mat-raised-button.btn.disabled,
.btn-group-raised .mat-raised-button.btn:disabled,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link.active,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:active,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:focus,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-link:hover,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]).disabled,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]):disabled,
.btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z])[disabled],
.btn-group-raised .mat-raised-button.btn[disabled],
.btn.btn-raised.btn-link,
.btn.btn-raised.btn-link.active,
.btn.btn-raised.btn-link:active,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:hover,
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
.mat-button.btn.btn-raised.btn-link,
.mat-button.btn.btn-raised.btn-link.active,
.mat-button.btn.btn-raised.btn-link:active,
.mat-button.btn.btn-raised.btn-link:focus,
.mat-button.btn.btn-raised.btn-link:hover,
.mat-button.btn.btn-raised.disabled,
.mat-button.btn.btn-raised:disabled,
.mat-button.btn.btn-raised[disabled],
.mat-raised-button.btn.btn-raised.btn-link,
.mat-raised-button.btn.btn-raised.btn-link.active,
.mat-raised-button.btn.btn-raised.btn-link:active,
.mat-raised-button.btn.btn-raised.btn-link:focus,
.mat-raised-button.btn.btn-raised.btn-link:hover,
.mat-raised-button.btn.btn-raised.disabled,
.mat-raised-button.btn.btn-raised:disabled,
.mat-raised-button.btn.btn-raised[disabled],
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.btn-link.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.btn-link:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.btn-link:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.btn-link:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised.disabled,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised:disabled,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
fieldset[disabled][disabled] .btn-group-raised .mat-button.btn,
fieldset[disabled][disabled] .btn-group-raised .mat-raised-button.btn,
fieldset[disabled][disabled] .btn-group-raised .mat-raised-button.btn:not([class*=mat-elevation-z]),
fieldset[disabled][disabled] .btn.btn-raised,
fieldset[disabled][disabled] .mat-button.btn.btn-raised,
fieldset[disabled][disabled] .mat-raised-button.btn.btn-raised,
fieldset[disabled][disabled] .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-raised {
    box-shadow: none
}

.btn.btn-outline,
.btn.btn-outline-danger,
.btn.btn-outline-info,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-success,
.btn.btn-outline-warning,
.mat-button.btn.btn-outline,
.mat-button.btn.btn-outline-danger,
.mat-button.btn.btn-outline-info,
.mat-button.btn.btn-outline-primary,
.mat-button.btn.btn-outline-secondary,
.mat-button.btn.btn-outline-success,
.mat-button.btn.btn-outline-warning,
.mat-raised-button.btn.btn-outline,
.mat-raised-button.btn.btn-outline-danger,
.mat-raised-button.btn.btn-outline-info,
.mat-raised-button.btn.btn-outline-primary,
.mat-raised-button.btn.btn-outline-secondary,
.mat-raised-button.btn.btn-outline-success,
.mat-raised-button.btn.btn-outline-warning,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning {
    border: 1px solid currentColor
}

.btn.btn-outline,
.mat-button.btn.btn-outline,
.mat-raised-button.btn.btn-outline,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline {
    color: #333;
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline.focus,
.btn.btn-outline:focus,
.btn.btn-outline:hover,
.mat-button.btn.btn-outline.focus,
.mat-button.btn.btn-outline:focus,
.mat-button.btn.btn-outline:hover,
.mat-raised-button.btn.btn-outline.focus,
.mat-raised-button.btn.btn-outline:focus,
.mat-raised-button.btn.btn-outline:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:hover {
    color: #333;
    background-color: rgba(153, 153, 153, .2);
    border-color: #333
}

.btn.btn-outline.active,
.btn.btn-outline:active,
.mat-button.btn.btn-outline.active,
.mat-button.btn.btn-outline:active,
.mat-raised-button.btn.btn-outline.active,
.mat-raised-button.btn.btn-outline:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active,
.open>.btn.btn-outline.dropdown-toggle,
.open>.mat-button.btn.btn-outline.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle,
.show>.btn.btn-outline.dropdown-toggle,
.show>.mat-button.btn.btn-outline.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle {
    color: #333;
    background-color: rgba(153, 153, 153, .2);
    border-color: #333;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline.active.focus,
.btn.btn-outline.active:focus,
.btn.btn-outline.active:hover,
.btn.btn-outline:active.focus,
.btn.btn-outline:active:focus,
.btn.btn-outline:active:hover,
.mat-button.btn.btn-outline.active.focus,
.mat-button.btn.btn-outline.active:focus,
.mat-button.btn.btn-outline.active:hover,
.mat-button.btn.btn-outline:active.focus,
.mat-button.btn.btn-outline:active:focus,
.mat-button.btn.btn-outline:active:hover,
.mat-raised-button.btn.btn-outline.active.focus,
.mat-raised-button.btn.btn-outline.active:focus,
.mat-raised-button.btn.btn-outline.active:hover,
.mat-raised-button.btn.btn-outline:active.focus,
.mat-raised-button.btn.btn-outline:active:focus,
.mat-raised-button.btn.btn-outline:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active:hover,
.open>.btn.btn-outline.dropdown-toggle.focus,
.open>.btn.btn-outline.dropdown-toggle:focus,
.open>.btn.btn-outline.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:hover,
.show>.btn.btn-outline.dropdown-toggle.focus,
.show>.btn.btn-outline.dropdown-toggle:focus,
.show>.btn.btn-outline.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:hover {
    color: #333;
    background-color: rgba(153, 153, 153, .4);
    border-color: #333
}

.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline.disabled.focus,
.btn.btn-outline.disabled:focus,
.btn.btn-outline:disabled.focus,
.btn.btn-outline:disabled:focus,
.mat-button.btn.btn-outline.disabled.focus,
.mat-button.btn.btn-outline.disabled:focus,
.mat-button.btn.btn-outline:disabled.focus,
.mat-button.btn.btn-outline:disabled:focus,
.mat-raised-button.btn.btn-outline.disabled.focus,
.mat-raised-button.btn.btn-outline.disabled:focus,
.mat-raised-button.btn.btn-outline:disabled.focus,
.mat-raised-button.btn.btn-outline:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled:focus {
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover,
.mat-button.btn.btn-outline.disabled:hover,
.mat-button.btn.btn-outline:disabled:hover,
.mat-raised-button.btn.btn-outline.disabled:hover,
.mat-raised-button.btn.btn-outline:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled:hover {
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline,
.bg-inverse .mat-button.btn.btn-outline,
.bg-inverse .mat-raised-button.btn.btn-outline,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline {
    color: #333;
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline:hover,
.bg-inverse .mat-button.btn.btn-outline.focus,
.bg-inverse .mat-button.btn.btn-outline:focus,
.bg-inverse .mat-button.btn.btn-outline:hover,
.bg-inverse .mat-raised-button.btn.btn-outline.focus,
.bg-inverse .mat-raised-button.btn.btn-outline:focus,
.bg-inverse .mat-raised-button.btn.btn-outline:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:hover {
    color: #333;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline.active,
.bg-inverse .btn.btn-outline:active,
.bg-inverse .mat-button.btn.btn-outline.active,
.bg-inverse .mat-button.btn.btn-outline:active,
.bg-inverse .mat-raised-button.btn.btn-outline.active,
.bg-inverse .mat-raised-button.btn.btn-outline:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle {
    color: #333;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline.active.focus,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active:hover,
.bg-inverse .mat-button.btn.btn-outline.active.focus,
.bg-inverse .mat-button.btn.btn-outline.active:focus,
.bg-inverse .mat-button.btn.btn-outline.active:hover,
.bg-inverse .mat-button.btn.btn-outline:active.focus,
.bg-inverse .mat-button.btn.btn-outline:active:focus,
.bg-inverse .mat-button.btn.btn-outline:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:active:hover,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle:hover {
    color: #333;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled:focus {
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline:disabled:hover {
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline.btn-link,
.mat-button.btn.btn-outline.btn-link,
.mat-raised-button.btn.btn-outline.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline.btn-link {
    background-color: transparent
}

.btn.btn-outline-primary,
.mat-button.btn.btn-outline-primary,
.mat-raised-button.btn.btn-outline-primary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary {
    color: #9c27b0;
    background-color: transparent;
    border-color: #9c27b0
}

.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:hover,
.mat-button.btn.btn-outline-primary.focus,
.mat-button.btn.btn-outline-primary:focus,
.mat-button.btn.btn-outline-primary:hover,
.mat-raised-button.btn.btn-outline-primary.focus,
.mat-raised-button.btn.btn-outline-primary:focus,
.mat-raised-button.btn.btn-outline-primary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:hover {
    color: #9c27b0;
    background-color: rgba(153, 153, 153, .2);
    border-color: #9c27b0
}

.btn.btn-outline-primary.active,
.btn.btn-outline-primary:active,
.mat-button.btn.btn-outline-primary.active,
.mat-button.btn.btn-outline-primary:active,
.mat-raised-button.btn.btn-outline-primary.active,
.mat-raised-button.btn.btn-outline-primary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active,
.open>.btn.btn-outline-primary.dropdown-toggle,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle,
.show>.btn.btn-outline-primary.dropdown-toggle,
.show>.mat-button.btn.btn-outline-primary.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle {
    color: #9c27b0;
    background-color: rgba(153, 153, 153, .2);
    border-color: #9c27b0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-primary.active.focus,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active:hover,
.mat-button.btn.btn-outline-primary.active.focus,
.mat-button.btn.btn-outline-primary.active:focus,
.mat-button.btn.btn-outline-primary.active:hover,
.mat-button.btn.btn-outline-primary:active.focus,
.mat-button.btn.btn-outline-primary:active:focus,
.mat-button.btn.btn-outline-primary:active:hover,
.mat-raised-button.btn.btn-outline-primary.active.focus,
.mat-raised-button.btn.btn-outline-primary.active:focus,
.mat-raised-button.btn.btn-outline-primary.active:hover,
.mat-raised-button.btn.btn-outline-primary:active.focus,
.mat-raised-button.btn.btn-outline-primary:active:focus,
.mat-raised-button.btn.btn-outline-primary:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active:hover,
.open>.btn.btn-outline-primary.dropdown-toggle.focus,
.open>.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:hover,
.show>.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:hover {
    color: #9c27b0;
    background-color: rgba(153, 153, 153, .4);
    border-color: #9c27b0
}

.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary:disabled.focus,
.btn.btn-outline-primary:disabled:focus,
.mat-button.btn.btn-outline-primary.disabled.focus,
.mat-button.btn.btn-outline-primary.disabled:focus,
.mat-button.btn.btn-outline-primary:disabled.focus,
.mat-button.btn.btn-outline-primary:disabled:focus,
.mat-raised-button.btn.btn-outline-primary.disabled.focus,
.mat-raised-button.btn.btn-outline-primary.disabled:focus,
.mat-raised-button.btn.btn-outline-primary:disabled.focus,
.mat-raised-button.btn.btn-outline-primary:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled:focus {
    background-color: transparent;
    border-color: #9c27b0
}

.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover,
.mat-button.btn.btn-outline-primary.disabled:hover,
.mat-button.btn.btn-outline-primary:disabled:hover,
.mat-raised-button.btn.btn-outline-primary.disabled:hover,
.mat-raised-button.btn.btn-outline-primary:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled:hover {
    background-color: transparent;
    border-color: #9c27b0
}

.bg-inverse .btn.btn-outline-primary,
.bg-inverse .mat-button.btn.btn-outline-primary,
.bg-inverse .mat-raised-button.btn.btn-outline-primary,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary {
    color: #9c27b0;
    background-color: transparent;
    border-color: #9c27b0
}

.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary:hover,
.bg-inverse .mat-button.btn.btn-outline-primary.focus,
.bg-inverse .mat-button.btn.btn-outline-primary:focus,
.bg-inverse .mat-button.btn.btn-outline-primary:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:hover {
    color: #9c27b0;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-primary.active,
.bg-inverse .btn.btn-outline-primary:active,
.bg-inverse .mat-button.btn.btn-outline-primary.active,
.bg-inverse .mat-button.btn.btn-outline-primary:active,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.active,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle {
    color: #9c27b0;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-primary.active.focus,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active:hover,
.bg-inverse .mat-button.btn.btn-outline-primary.active.focus,
.bg-inverse .mat-button.btn.btn-outline-primary.active:focus,
.bg-inverse .mat-button.btn.btn-outline-primary.active:hover,
.bg-inverse .mat-button.btn.btn-outline-primary:active.focus,
.bg-inverse .mat-button.btn.btn-outline-primary:active:focus,
.bg-inverse .mat-button.btn.btn-outline-primary:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:active:hover,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle:hover {
    color: #9c27b0;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-primary.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-primary.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-primary:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-primary:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled:focus {
    background-color: transparent;
    border-color: #9c27b0
}

.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-primary.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-primary:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-primary.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-primary:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary:disabled:hover {
    background-color: transparent;
    border-color: #9c27b0
}

.btn.btn-outline-primary.btn-link,
.mat-button.btn.btn-outline-primary.btn-link,
.mat-raised-button.btn.btn-outline-primary.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-primary.btn-link {
    background-color: transparent
}

.btn.btn-outline-secondary,
.mat-button.btn.btn-outline-secondary,
.mat-raised-button.btn.btn-outline-secondary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary {
    color: #333;
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:hover,
.mat-button.btn.btn-outline-secondary.focus,
.mat-button.btn.btn-outline-secondary:focus,
.mat-button.btn.btn-outline-secondary:hover,
.mat-raised-button.btn.btn-outline-secondary.focus,
.mat-raised-button.btn.btn-outline-secondary:focus,
.mat-raised-button.btn.btn-outline-secondary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:hover {
    color: #333;
    background-color: rgba(153, 153, 153, .2);
    border-color: #333
}

.btn.btn-outline-secondary.active,
.btn.btn-outline-secondary:active,
.mat-button.btn.btn-outline-secondary.active,
.mat-button.btn.btn-outline-secondary:active,
.mat-raised-button.btn.btn-outline-secondary.active,
.mat-raised-button.btn.btn-outline-secondary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active,
.open>.btn.btn-outline-secondary.dropdown-toggle,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle,
.show>.btn.btn-outline-secondary.dropdown-toggle,
.show>.mat-button.btn.btn-outline-secondary.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle {
    color: #333;
    background-color: rgba(153, 153, 153, .2);
    border-color: #333;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-secondary.active.focus,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active:hover,
.mat-button.btn.btn-outline-secondary.active.focus,
.mat-button.btn.btn-outline-secondary.active:focus,
.mat-button.btn.btn-outline-secondary.active:hover,
.mat-button.btn.btn-outline-secondary:active.focus,
.mat-button.btn.btn-outline-secondary:active:focus,
.mat-button.btn.btn-outline-secondary:active:hover,
.mat-raised-button.btn.btn-outline-secondary.active.focus,
.mat-raised-button.btn.btn-outline-secondary.active:focus,
.mat-raised-button.btn.btn-outline-secondary.active:hover,
.mat-raised-button.btn.btn-outline-secondary:active.focus,
.mat-raised-button.btn.btn-outline-secondary:active:focus,
.mat-raised-button.btn.btn-outline-secondary:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active:hover,
.open>.btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:hover,
.show>.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:hover {
    color: #333;
    background-color: rgba(153, 153, 153, .4);
    border-color: #333
}

.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary:disabled.focus,
.btn.btn-outline-secondary:disabled:focus,
.mat-button.btn.btn-outline-secondary.disabled.focus,
.mat-button.btn.btn-outline-secondary.disabled:focus,
.mat-button.btn.btn-outline-secondary:disabled.focus,
.mat-button.btn.btn-outline-secondary:disabled:focus,
.mat-raised-button.btn.btn-outline-secondary.disabled.focus,
.mat-raised-button.btn.btn-outline-secondary.disabled:focus,
.mat-raised-button.btn.btn-outline-secondary:disabled.focus,
.mat-raised-button.btn.btn-outline-secondary:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled:focus {
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover,
.mat-button.btn.btn-outline-secondary.disabled:hover,
.mat-button.btn.btn-outline-secondary:disabled:hover,
.mat-raised-button.btn.btn-outline-secondary.disabled:hover,
.mat-raised-button.btn.btn-outline-secondary:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled:hover {
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline-secondary,
.bg-inverse .mat-button.btn.btn-outline-secondary,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary {
    color: #333;
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary:hover,
.bg-inverse .mat-button.btn.btn-outline-secondary.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:hover {
    color: #333;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-secondary.active,
.bg-inverse .btn.btn-outline-secondary:active,
.bg-inverse .mat-button.btn.btn-outline-secondary.active,
.bg-inverse .mat-button.btn.btn-outline-secondary:active,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.active,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle {
    color: #333;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-secondary.active.focus,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active:hover,
.bg-inverse .mat-button.btn.btn-outline-secondary.active.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary.active:focus,
.bg-inverse .mat-button.btn.btn-outline-secondary.active:hover,
.bg-inverse .mat-button.btn.btn-outline-secondary:active.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:active:focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:active:hover,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle:hover {
    color: #333;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-secondary.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-secondary:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled:focus {
    background-color: transparent;
    border-color: #333
}

.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-secondary.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-secondary:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-secondary:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary:disabled:hover {
    background-color: transparent;
    border-color: #333
}

.btn.btn-outline-secondary.btn-link,
.mat-button.btn.btn-outline-secondary.btn-link,
.mat-raised-button.btn.btn-outline-secondary.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-secondary.btn-link {
    background-color: transparent
}

.btn.btn-outline-info,
.mat-button.btn.btn-outline-info,
.mat-raised-button.btn.btn-outline-info,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info {
    color: #00bcd4;
    background-color: transparent;
    border-color: #00bcd4
}

.btn.btn-outline-info.focus,
.btn.btn-outline-info:focus,
.btn.btn-outline-info:hover,
.mat-button.btn.btn-outline-info.focus,
.mat-button.btn.btn-outline-info:focus,
.mat-button.btn.btn-outline-info:hover,
.mat-raised-button.btn.btn-outline-info.focus,
.mat-raised-button.btn.btn-outline-info:focus,
.mat-raised-button.btn.btn-outline-info:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, .2);
    border-color: #00bcd4
}

.btn.btn-outline-info.active,
.btn.btn-outline-info:active,
.mat-button.btn.btn-outline-info.active,
.mat-button.btn.btn-outline-info:active,
.mat-raised-button.btn.btn-outline-info.active,
.mat-raised-button.btn.btn-outline-info:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active,
.open>.btn.btn-outline-info.dropdown-toggle,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle,
.show>.btn.btn-outline-info.dropdown-toggle,
.show>.mat-button.btn.btn-outline-info.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-info.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, .2);
    border-color: #00bcd4;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-info.active.focus,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active:hover,
.mat-button.btn.btn-outline-info.active.focus,
.mat-button.btn.btn-outline-info.active:focus,
.mat-button.btn.btn-outline-info.active:hover,
.mat-button.btn.btn-outline-info:active.focus,
.mat-button.btn.btn-outline-info:active:focus,
.mat-button.btn.btn-outline-info:active:hover,
.mat-raised-button.btn.btn-outline-info.active.focus,
.mat-raised-button.btn.btn-outline-info.active:focus,
.mat-raised-button.btn.btn-outline-info.active:hover,
.mat-raised-button.btn.btn-outline-info:active.focus,
.mat-raised-button.btn.btn-outline-info:active:focus,
.mat-raised-button.btn.btn-outline-info:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active:hover,
.open>.btn.btn-outline-info.dropdown-toggle.focus,
.open>.btn.btn-outline-info.dropdown-toggle:focus,
.open>.btn.btn-outline-info.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:hover,
.show>.btn.btn-outline-info.dropdown-toggle.focus,
.show>.btn.btn-outline-info.dropdown-toggle:focus,
.show>.btn.btn-outline-info.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-info.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-info.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-info.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-info.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-info.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-info.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:hover {
    color: #00bcd4;
    background-color: rgba(153, 153, 153, .4);
    border-color: #00bcd4
}

.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info:disabled.focus,
.btn.btn-outline-info:disabled:focus,
.mat-button.btn.btn-outline-info.disabled.focus,
.mat-button.btn.btn-outline-info.disabled:focus,
.mat-button.btn.btn-outline-info:disabled.focus,
.mat-button.btn.btn-outline-info:disabled:focus,
.mat-raised-button.btn.btn-outline-info.disabled.focus,
.mat-raised-button.btn.btn-outline-info.disabled:focus,
.mat-raised-button.btn.btn-outline-info:disabled.focus,
.mat-raised-button.btn.btn-outline-info:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled:focus {
    background-color: transparent;
    border-color: #00bcd4
}

.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover,
.mat-button.btn.btn-outline-info.disabled:hover,
.mat-button.btn.btn-outline-info:disabled:hover,
.mat-raised-button.btn.btn-outline-info.disabled:hover,
.mat-raised-button.btn.btn-outline-info:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled:hover {
    background-color: transparent;
    border-color: #00bcd4
}

.bg-inverse .btn.btn-outline-info,
.bg-inverse .mat-button.btn.btn-outline-info,
.bg-inverse .mat-raised-button.btn.btn-outline-info,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info {
    color: #00bcd4;
    background-color: transparent;
    border-color: #00bcd4
}

.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info:hover,
.bg-inverse .mat-button.btn.btn-outline-info.focus,
.bg-inverse .mat-button.btn.btn-outline-info:focus,
.bg-inverse .mat-button.btn.btn-outline-info:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-info.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:hover {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-info.active,
.bg-inverse .btn.btn-outline-info:active,
.bg-inverse .mat-button.btn.btn-outline-info.active,
.bg-inverse .mat-button.btn.btn-outline-info:active,
.bg-inverse .mat-raised-button.btn.btn-outline-info.active,
.bg-inverse .mat-raised-button.btn.btn-outline-info:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-info.active.focus,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active:hover,
.bg-inverse .mat-button.btn.btn-outline-info.active.focus,
.bg-inverse .mat-button.btn.btn-outline-info.active:focus,
.bg-inverse .mat-button.btn.btn-outline-info.active:hover,
.bg-inverse .mat-button.btn.btn-outline-info:active.focus,
.bg-inverse .mat-button.btn.btn-outline-info:active:focus,
.bg-inverse .mat-button.btn.btn-outline-info:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-info.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-info:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:active:hover,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle:hover {
    color: #00bcd4;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-info.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-info.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-info:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-info:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-info:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled:focus {
    background-color: transparent;
    border-color: #00bcd4
}

.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-info.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-info:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-info.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-info:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info:disabled:hover {
    background-color: transparent;
    border-color: #00bcd4
}

.btn.btn-outline-info.btn-link,
.mat-button.btn.btn-outline-info.btn-link,
.mat-raised-button.btn.btn-outline-info.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-info.btn-link {
    background-color: transparent
}

.btn.btn-outline-success,
.mat-button.btn.btn-outline-success,
.mat-raised-button.btn.btn-outline-success,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success {
    color: #4caf50;
    background-color: transparent;
    border-color: #4caf50
}

.btn.btn-outline-success.focus,
.btn.btn-outline-success:focus,
.btn.btn-outline-success:hover,
.mat-button.btn.btn-outline-success.focus,
.mat-button.btn.btn-outline-success:focus,
.mat-button.btn.btn-outline-success:hover,
.mat-raised-button.btn.btn-outline-success.focus,
.mat-raised-button.btn.btn-outline-success:focus,
.mat-raised-button.btn.btn-outline-success:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(153, 153, 153, .2);
    border-color: #4caf50
}

.btn.btn-outline-success.active,
.btn.btn-outline-success:active,
.mat-button.btn.btn-outline-success.active,
.mat-button.btn.btn-outline-success:active,
.mat-raised-button.btn.btn-outline-success.active,
.mat-raised-button.btn.btn-outline-success:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active,
.open>.btn.btn-outline-success.dropdown-toggle,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle,
.show>.btn.btn-outline-success.dropdown-toggle,
.show>.mat-button.btn.btn-outline-success.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-success.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle {
    color: #4caf50;
    background-color: rgba(153, 153, 153, .2);
    border-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-success.active.focus,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active:hover,
.mat-button.btn.btn-outline-success.active.focus,
.mat-button.btn.btn-outline-success.active:focus,
.mat-button.btn.btn-outline-success.active:hover,
.mat-button.btn.btn-outline-success:active.focus,
.mat-button.btn.btn-outline-success:active:focus,
.mat-button.btn.btn-outline-success:active:hover,
.mat-raised-button.btn.btn-outline-success.active.focus,
.mat-raised-button.btn.btn-outline-success.active:focus,
.mat-raised-button.btn.btn-outline-success.active:hover,
.mat-raised-button.btn.btn-outline-success:active.focus,
.mat-raised-button.btn.btn-outline-success:active:focus,
.mat-raised-button.btn.btn-outline-success:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active:hover,
.open>.btn.btn-outline-success.dropdown-toggle.focus,
.open>.btn.btn-outline-success.dropdown-toggle:focus,
.open>.btn.btn-outline-success.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:hover,
.show>.btn.btn-outline-success.dropdown-toggle.focus,
.show>.btn.btn-outline-success.dropdown-toggle:focus,
.show>.btn.btn-outline-success.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-success.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-success.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-success.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-success.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-success.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-success.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:hover {
    color: #4caf50;
    background-color: rgba(153, 153, 153, .4);
    border-color: #4caf50
}

.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success:disabled.focus,
.btn.btn-outline-success:disabled:focus,
.mat-button.btn.btn-outline-success.disabled.focus,
.mat-button.btn.btn-outline-success.disabled:focus,
.mat-button.btn.btn-outline-success:disabled.focus,
.mat-button.btn.btn-outline-success:disabled:focus,
.mat-raised-button.btn.btn-outline-success.disabled.focus,
.mat-raised-button.btn.btn-outline-success.disabled:focus,
.mat-raised-button.btn.btn-outline-success:disabled.focus,
.mat-raised-button.btn.btn-outline-success:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled:focus {
    background-color: transparent;
    border-color: #4caf50
}

.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover,
.mat-button.btn.btn-outline-success.disabled:hover,
.mat-button.btn.btn-outline-success:disabled:hover,
.mat-raised-button.btn.btn-outline-success.disabled:hover,
.mat-raised-button.btn.btn-outline-success:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled:hover {
    background-color: transparent;
    border-color: #4caf50
}

.bg-inverse .btn.btn-outline-success,
.bg-inverse .mat-button.btn.btn-outline-success,
.bg-inverse .mat-raised-button.btn.btn-outline-success,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success {
    color: #4caf50;
    background-color: transparent;
    border-color: #4caf50
}

.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success:hover,
.bg-inverse .mat-button.btn.btn-outline-success.focus,
.bg-inverse .mat-button.btn.btn-outline-success:focus,
.bg-inverse .mat-button.btn.btn-outline-success:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-success.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:hover {
    color: #4caf50;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-success.active,
.bg-inverse .btn.btn-outline-success:active,
.bg-inverse .mat-button.btn.btn-outline-success.active,
.bg-inverse .mat-button.btn.btn-outline-success:active,
.bg-inverse .mat-raised-button.btn.btn-outline-success.active,
.bg-inverse .mat-raised-button.btn.btn-outline-success:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle {
    color: #4caf50;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-success.active.focus,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active:hover,
.bg-inverse .mat-button.btn.btn-outline-success.active.focus,
.bg-inverse .mat-button.btn.btn-outline-success.active:focus,
.bg-inverse .mat-button.btn.btn-outline-success.active:hover,
.bg-inverse .mat-button.btn.btn-outline-success:active.focus,
.bg-inverse .mat-button.btn.btn-outline-success:active:focus,
.bg-inverse .mat-button.btn.btn-outline-success:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-success.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-success:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:active:hover,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle:hover {
    color: #4caf50;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-success.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-success.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-success:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-success:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-success:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled:focus {
    background-color: transparent;
    border-color: #4caf50
}

.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-success.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-success:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-success.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-success:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success:disabled:hover {
    background-color: transparent;
    border-color: #4caf50
}

.btn.btn-outline-success.btn-link,
.mat-button.btn.btn-outline-success.btn-link,
.mat-raised-button.btn.btn-outline-success.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-success.btn-link {
    background-color: transparent
}

.btn.btn-outline-warning,
.mat-button.btn.btn-outline-warning,
.mat-raised-button.btn.btn-outline-warning,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning {
    color: #ff9800;
    background-color: transparent;
    border-color: #ff9800
}

.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning:hover,
.mat-button.btn.btn-outline-warning.focus,
.mat-button.btn.btn-outline-warning:focus,
.mat-button.btn.btn-outline-warning:hover,
.mat-raised-button.btn.btn-outline-warning.focus,
.mat-raised-button.btn.btn-outline-warning:focus,
.mat-raised-button.btn.btn-outline-warning:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(153, 153, 153, .2);
    border-color: #ff9800
}

.btn.btn-outline-warning.active,
.btn.btn-outline-warning:active,
.mat-button.btn.btn-outline-warning.active,
.mat-button.btn.btn-outline-warning:active,
.mat-raised-button.btn.btn-outline-warning.active,
.mat-raised-button.btn.btn-outline-warning:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active,
.open>.btn.btn-outline-warning.dropdown-toggle,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle,
.show>.btn.btn-outline-warning.dropdown-toggle,
.show>.mat-button.btn.btn-outline-warning.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle {
    color: #ff9800;
    background-color: rgba(153, 153, 153, .2);
    border-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-warning.active.focus,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active:hover,
.mat-button.btn.btn-outline-warning.active.focus,
.mat-button.btn.btn-outline-warning.active:focus,
.mat-button.btn.btn-outline-warning.active:hover,
.mat-button.btn.btn-outline-warning:active.focus,
.mat-button.btn.btn-outline-warning:active:focus,
.mat-button.btn.btn-outline-warning:active:hover,
.mat-raised-button.btn.btn-outline-warning.active.focus,
.mat-raised-button.btn.btn-outline-warning.active:focus,
.mat-raised-button.btn.btn-outline-warning.active:hover,
.mat-raised-button.btn.btn-outline-warning:active.focus,
.mat-raised-button.btn.btn-outline-warning:active:focus,
.mat-raised-button.btn.btn-outline-warning:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active:hover,
.open>.btn.btn-outline-warning.dropdown-toggle.focus,
.open>.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:hover,
.show>.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:hover {
    color: #ff9800;
    background-color: rgba(153, 153, 153, .4);
    border-color: #ff9800
}

.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning:disabled.focus,
.btn.btn-outline-warning:disabled:focus,
.mat-button.btn.btn-outline-warning.disabled.focus,
.mat-button.btn.btn-outline-warning.disabled:focus,
.mat-button.btn.btn-outline-warning:disabled.focus,
.mat-button.btn.btn-outline-warning:disabled:focus,
.mat-raised-button.btn.btn-outline-warning.disabled.focus,
.mat-raised-button.btn.btn-outline-warning.disabled:focus,
.mat-raised-button.btn.btn-outline-warning:disabled.focus,
.mat-raised-button.btn.btn-outline-warning:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled:focus {
    background-color: transparent;
    border-color: #ff9800
}

.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover,
.mat-button.btn.btn-outline-warning.disabled:hover,
.mat-button.btn.btn-outline-warning:disabled:hover,
.mat-raised-button.btn.btn-outline-warning.disabled:hover,
.mat-raised-button.btn.btn-outline-warning:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled:hover {
    background-color: transparent;
    border-color: #ff9800
}

.bg-inverse .btn.btn-outline-warning,
.bg-inverse .mat-button.btn.btn-outline-warning,
.bg-inverse .mat-raised-button.btn.btn-outline-warning,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning {
    color: #ff9800;
    background-color: transparent;
    border-color: #ff9800
}

.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning:hover,
.bg-inverse .mat-button.btn.btn-outline-warning.focus,
.bg-inverse .mat-button.btn.btn-outline-warning:focus,
.bg-inverse .mat-button.btn.btn-outline-warning:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:hover {
    color: #ff9800;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-warning.active,
.bg-inverse .btn.btn-outline-warning:active,
.bg-inverse .mat-button.btn.btn-outline-warning.active,
.bg-inverse .mat-button.btn.btn-outline-warning:active,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.active,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle {
    color: #ff9800;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-warning.active.focus,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active:hover,
.bg-inverse .mat-button.btn.btn-outline-warning.active.focus,
.bg-inverse .mat-button.btn.btn-outline-warning.active:focus,
.bg-inverse .mat-button.btn.btn-outline-warning.active:hover,
.bg-inverse .mat-button.btn.btn-outline-warning:active.focus,
.bg-inverse .mat-button.btn.btn-outline-warning:active:focus,
.bg-inverse .mat-button.btn.btn-outline-warning:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:active:hover,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle:hover {
    color: #ff9800;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-warning.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-warning.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-warning:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-warning:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled:focus {
    background-color: transparent;
    border-color: #ff9800
}

.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-warning.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-warning:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-warning.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-warning:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning:disabled:hover {
    background-color: transparent;
    border-color: #ff9800
}

.btn.btn-outline-warning.btn-link,
.mat-button.btn.btn-outline-warning.btn-link,
.mat-raised-button.btn.btn-outline-warning.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-warning.btn-link {
    background-color: transparent
}

.btn.btn-outline-danger,
.mat-button.btn.btn-outline-danger,
.mat-raised-button.btn.btn-outline-danger,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336
}

.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger:hover,
.mat-button.btn.btn-outline-danger.focus,
.mat-button.btn.btn-outline-danger:focus,
.mat-button.btn.btn-outline-danger:hover,
.mat-raised-button.btn.btn-outline-danger.focus,
.mat-raised-button.btn.btn-outline-danger:focus,
.mat-raised-button.btn.btn-outline-danger:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(153, 153, 153, .2);
    border-color: #f44336
}

.btn.btn-outline-danger.active,
.btn.btn-outline-danger:active,
.mat-button.btn.btn-outline-danger.active,
.mat-button.btn.btn-outline-danger:active,
.mat-raised-button.btn.btn-outline-danger.active,
.mat-raised-button.btn.btn-outline-danger:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active,
.open>.btn.btn-outline-danger.dropdown-toggle,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle,
.show>.btn.btn-outline-danger.dropdown-toggle,
.show>.mat-button.btn.btn-outline-danger.dropdown-toggle,
.show>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle {
    color: #f44336;
    background-color: rgba(153, 153, 153, .2);
    border-color: #f44336;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn.btn-outline-danger.active.focus,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active:hover,
.mat-button.btn.btn-outline-danger.active.focus,
.mat-button.btn.btn-outline-danger.active:focus,
.mat-button.btn.btn-outline-danger.active:hover,
.mat-button.btn.btn-outline-danger:active.focus,
.mat-button.btn.btn-outline-danger:active:focus,
.mat-button.btn.btn-outline-danger:active:hover,
.mat-raised-button.btn.btn-outline-danger.active.focus,
.mat-raised-button.btn.btn-outline-danger.active:focus,
.mat-raised-button.btn.btn-outline-danger.active:hover,
.mat-raised-button.btn.btn-outline-danger:active.focus,
.mat-raised-button.btn.btn-outline-danger:active:focus,
.mat-raised-button.btn.btn-outline-danger:active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active:hover,
.open>.btn.btn-outline-danger.dropdown-toggle.focus,
.open>.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle.focus,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle.focus,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:focus,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:hover,
.show>.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.mat-button.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.mat-button.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.mat-button.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:focus,
.show>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:hover {
    color: #f44336;
    background-color: rgba(153, 153, 153, .4);
    border-color: #f44336
}

.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(153, 153, 153, .2)
}

.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger:disabled.focus,
.btn.btn-outline-danger:disabled:focus,
.mat-button.btn.btn-outline-danger.disabled.focus,
.mat-button.btn.btn-outline-danger.disabled:focus,
.mat-button.btn.btn-outline-danger:disabled.focus,
.mat-button.btn.btn-outline-danger:disabled:focus,
.mat-raised-button.btn.btn-outline-danger.disabled.focus,
.mat-raised-button.btn.btn-outline-danger.disabled:focus,
.mat-raised-button.btn.btn-outline-danger:disabled.focus,
.mat-raised-button.btn.btn-outline-danger:disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled:focus {
    background-color: transparent;
    border-color: #f44336
}

.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover,
.mat-button.btn.btn-outline-danger.disabled:hover,
.mat-button.btn.btn-outline-danger:disabled:hover,
.mat-raised-button.btn.btn-outline-danger.disabled:hover,
.mat-raised-button.btn.btn-outline-danger:disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled:hover {
    background-color: transparent;
    border-color: #f44336
}

.bg-inverse .btn.btn-outline-danger,
.bg-inverse .mat-button.btn.btn-outline-danger,
.bg-inverse .mat-raised-button.btn.btn-outline-danger,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger {
    color: #f44336;
    background-color: transparent;
    border-color: #f44336
}

.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger:hover,
.bg-inverse .mat-button.btn.btn-outline-danger.focus,
.bg-inverse .mat-button.btn.btn-outline-danger:focus,
.bg-inverse .mat-button.btn.btn-outline-danger:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:hover {
    color: #f44336;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-danger.active,
.bg-inverse .btn.btn-outline-danger:active,
.bg-inverse .mat-button.btn.btn-outline-danger.active,
.bg-inverse .mat-button.btn.btn-outline-danger:active,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.active,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle {
    color: #f44336;
    background-color: rgba(204, 204, 204, .15);
    border-color: rgba(204, 204, 204, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.bg-inverse .btn.btn-outline-danger.active.focus,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active:hover,
.bg-inverse .mat-button.btn.btn-outline-danger.active.focus,
.bg-inverse .mat-button.btn.btn-outline-danger.active:focus,
.bg-inverse .mat-button.btn.btn-outline-danger.active:hover,
.bg-inverse .mat-button.btn.btn-outline-danger:active.focus,
.bg-inverse .mat-button.btn.btn-outline-danger:active:focus,
.bg-inverse .mat-button.btn.btn-outline-danger:active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.active:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:active.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:active:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.active:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:active:hover,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle.focus,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle:hover {
    color: #f44336;
    background-color: rgba(204, 204, 204, .25);
    border-color: rgba(204, 204, 204, .25)
}

.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: transparent
}

.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover,
.open>.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
    background-color: rgba(204, 204, 204, .15)
}

.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-danger.disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-danger.disabled:focus,
.bg-inverse .mat-button.btn.btn-outline-danger:disabled.focus,
.bg-inverse .mat-button.btn.btn-outline-danger:disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.disabled:focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:disabled.focus,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled:focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled.focus,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled:focus {
    background-color: transparent;
    border-color: #f44336
}

.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-danger.disabled:hover,
.bg-inverse .mat-button.btn.btn-outline-danger:disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-danger.disabled:hover,
.bg-inverse .mat-raised-button.btn.btn-outline-danger:disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.disabled:hover,
.bg-inverse .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger:disabled:hover {
    background-color: transparent;
    border-color: #f44336
}

.btn.btn-outline-danger.btn-link,
.mat-button.btn.btn-outline-danger.btn-link,
.mat-raised-button.btn.btn-outline-danger.btn-link,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-outline-danger.btn-link {
    background-color: transparent
}

.btn-group-lg .btn,
.btn-group-lg .mat-button.btn,
.btn-group-lg .mat-raised-button.btn,
.btn-group-lg .mat-raised-button.btn:not([class*=mat-elevation-z]),
.btn.btn-lg,
.mat-button.btn.btn-lg,
.mat-raised-button.btn.btn-lg,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-lg {
    padding: 1.125rem 2.25rem;
    font-size: .875rem;
    line-height: 1.3333333;
    border-radius: .2rem
}

.btn-group-sm .btn,
.btn-group-sm .mat-button.btn,
.btn-group-sm .mat-raised-button.btn,
.btn-group-sm .mat-raised-button.btn:not([class*=mat-elevation-z]),
.btn.btn-sm,
.mat-button.btn.btn-sm,
.mat-raised-button.btn.btn-sm,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-sm {
    padding: .40625rem 1.25rem;
    font-size: .6875rem;
    line-height: 1.5
}

.btn-group-lg>.btn-just-icon.btn,
.btn-just-icon.btn-lg {
    font-size: 24px;
    height: 41px;
    min-width: 41px;
    width: 41px
}

.input-group-btn>.btn {
    border: 0
}

.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
    position: relative;
    display: inline-block;
    top: 0;
    margin-top: -1em;
    margin-bottom: -1em;
    font-size: 1.1rem;
    vertical-align: middle
}

.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
    border-radius: 0 0 .25rem .25rem
}

.btn-group-vertical.btn-group-raised,
.btn-group.btn-group-raised {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.btn-group .btn-group,
.btn-group .mat-raised-button.btn,
.btn-group .mat-raised-button.btn+.mat-raised-button.btn,
.btn-group .mat-raised-button.btn:active,
.btn-group-vertical .btn-group,
.btn-group-vertical .mat-raised-button.btn,
.btn-group-vertical .mat-raised-button.btn+.mat-raised-button.btn,
.btn-group-vertical .mat-raised-button.btn:active,
.btn-group-vertical>.btn-group,
.btn-group>.btn-group {
    margin: 0
}

.btn-group>.btn-group:not(:first-child)>.mat-raised-button.btn,
.btn-group>.btn-group:not(:first-child)>.mat-raised-button.btn .mat-button-focus-overlay,
.btn-group>.btn-group:not(:first-child)>.mat-raised-button.btn .mat-button-ripple,
.btn-group>.mat-raised-button.btn:not(:first-child),
.btn-group>.mat-raised-button.btn:not(:first-child) .mat-button-focus-overlay,
.btn-group>.mat-raised-button.btn:not(:first-child) .mat-button-ripple {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group>.btn-group:not(:last-child)>.mat-raised-button.btn,
.btn-group>.btn-group:not(:last-child)>.mat-raised-button.btn .mat-button-focus-overlay,
.btn-group>.btn-group:not(:last-child)>.mat-raised-button.btn .mat-button-ripple,
.btn-group>.mat-raised-button.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.mat-raised-button.btn:not(:last-child):not(.dropdown-toggle) .mat-button-focus-overlay,
.btn-group>.mat-raised-button.btn:not(:last-child):not(.dropdown-toggle) .mat-button-ripple {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-no-ripple .mat-button-ripple {
    display: none
}

.form-check {
    margin-bottom: .5rem;
    padding-left: 0
}

.form-check .form-check-input {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    left: 0;
    pointer-events: none
}

.form-check .form-check-sign:before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, .84);
    height: 20px;
    width: 20px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    top: 0;
    -webkit-transform: scale3d(2.3, 2.3, 1);
    transform: scale3d(2.3, 2.3, 1)
}

.form-check .form-check-sign .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .54);
    overflow: hidden;
    z-index: 1;
    border-radius: 3px
}

.form-check .form-check-sign .check:before {
    position: absolute;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    margin-top: -3px;
    margin-left: 7px;
    width: 0;
    color: #fff;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: .3s forwards checkbox-off;
    animation: .3s forwards checkbox-off
}

.form-check .form-check-input:focus+.form-check-sign .check:after {
    opacity: .2
}

.form-check .form-check-input:checked+.form-check-sign .check {
    background: #9c27b0
}

.form-check .form-check-input:checked+.form-check-sign .check:before {
    color: #fff;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    -webkit-animation: .3s forwards checkbox-on;
    animation: .3s forwards checkbox-on
}

.form-check .form-check-input:checked+.form-check-sign:before {
    -webkit-animation: .5s rippleOn;
    animation: .5s rippleOn
}

.form-check .form-check-input:checked+.form-check-sign .check:after {
    -webkit-animation: .5s forwards rippleOn;
    animation: .5s forwards rippleOn
}

.form-check .form-check-input:not(:checked)+.form-check-sign .check:after,
.form-check .form-check-input:not(:checked)+.form-check-sign:before {
    -webkit-animation: .5s rippleOff;
    animation: .5s rippleOff
}

.form-check .rtl .form-check .form-check-sign .check::before {
    margin-right: 10px
}

.form-check .form-check-input[disabled]+.circle,
.form-check .form-check-input[disabled]~.form-check-sign .check,
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input {
    opacity: .5
}

.form-check .form-check-input[disabled]~.form-check-sign .check {
    border-color: #000;
    opacity: .26
}

.form-check .form-check-input[disabled]+.form-check-sign .check:after {
    background-color: rgba(0, 0, 0, .87);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.form-check .form-check-input[disabled][checked]+.form-check-sign .check {
    background-color: #000
}

/* checkboxAnimation.module.css */
.checkbox-on {
    @keyframes checkbox-on {
        0% {
            box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
        }

        50% {
            box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
        }

        100% {
            box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
        }
    }
}

.rippleOn {
    @keyframes rippleOn {

        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: .2;
        }
    }
}

.rippleOff {
    @keyframes rippleOff {

        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: .2;
        }
    }
}


.card {
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #333;
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.card .card-category:not([class*=text-]) {
    color: #999
}

.card .card-category {
    margin-top: 10px
}

.card .card-category .material-icons {
    position: relative;
    top: 8px;
    line-height: 0
}

.card .form-check {
    margin-top: 15px
}

.card .card-title:last-child {
    margin-bottom: 0
}

.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
    box-shadow: none !important
}

.card .card-body+.card-footer {
    border: 0
}

.card .card-footer {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0
}

.card .card-footer .author,
.card .card-footer .stats {
    display: inline-flex
}

.card .card-footer .stats .material-icons {
    margin-right: 3px;
    margin-left: 3px
}

.card.bmd-card-raised {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2)
}

@media(min-width: 992px) {
    .card.bmd-card-flat {
        box-shadow: none
    }
}

.card .card-header {
    border-bottom: 0;
    background: 0
}

.card .card-header .title {
    color: #fff
}

.card .card-header .nav-tabs {
    padding: 0
}

.card .card-header.card-header-image {
    position: relative;
    padding: 0;
    z-index: 1;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px
}

.card .card-header.card-header-image img {
    width: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, .24), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.card .card-header.card-header-image .card-title {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-size: 1.125rem;
    text-shadow: 0 2px 5px rgba(33, 33, 33, .5)
}

.card .card-header.card-header-image .colored-shadow {
    -webkit-transform: scale(.94);
    transform: scale(.94);
    top: 12px;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    transition: opacity .45s;
    opacity: 0
}

.card .card-header.card-header-image.no-shadow {
    box-shadow: none
}

.card .card-header.card-header-image.no-shadow.shadow-normal {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.card .card-header.card-header-image.no-shadow .colored-shadow {
    display: none !important
}

.card .card-header-primary .card-icon,
.card .card-header-primary .card-text,
.card .card-header-primary:not(.card-header-icon):not(.card-header-text),
.card.bg-primary,
.card.card-rotate.bg-primary .back,
.card.card-rotate.bg-primary .front {
    background: linear-gradient(60deg, #ab47bc, #8e24aa)
}

.card .card-header-info .card-icon,
.card .card-header-info .card-text,
.card .card-header-info:not(.card-header-icon):not(.card-header-text),
.card.bg-info,
.card.card-rotate.bg-info .back,
.card.card-rotate.bg-info .front {
    background: linear-gradient(60deg, #26c6da, #00acc1)
}

.card .card-header-success .card-icon,
.card .card-header-success .card-text,
.card .card-header-success:not(.card-header-icon):not(.card-header-text),
.card.bg-success,
.card.card-rotate.bg-success .back,
.card.card-rotate.bg-success .front {
    background: linear-gradient(60deg, #087c3e, #056839)
}

.card .card-header-warning .card-icon,
.card .card-header-warning .card-text,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text),
.card.bg-warning,
.card.card-rotate.bg-warning .back,
.card.card-rotate.bg-warning .front {
    background: linear-gradient(60deg, #ffa726, #fb8c00)
}

.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .back,
.card.card-rotate.bg-danger .front {
    background: linear-gradient(60deg, #ef5350, #e53935)
}

.card .card-header-rose .card-icon,
.card .card-header-rose .card-text,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text),
.card.bg-rose,
.card.card-rotate.bg-rose .back,
.card.card-rotate.bg-rose .front {
    background: linear-gradient(60deg, #ec407a, #d81b60)
}

.card .card-header-primary .card-icon,
.card .card-header-primary .card-text,
.card .card-header-primary:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(156, 39, 176, .4)
}

.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(244, 67, 54, .4)
}

.card .card-header-rose .card-icon,
.card .card-header-rose .card-text,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(233, 30, 99, .4)
}

.card .card-header-warning .card-icon,
.card .card-header-warning .card-text,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4)
}

.card .card-header-info .card-icon,
.card .card-header-info .card-text,
.card .card-header-info:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 188, 212, .4)
}

.card .card-header-success .card-icon,
.card .card-header-success .card-text,
.card .card-header-success:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(76, 175, 80, .4)
}

.card [class*=card-header-],
.card [class*=card-header-] .card-title,
.card [class*=card-header-] .card-title a,
.card [class*=card-header-] .icon i,
.card[class*=bg-],
.card[class*=bg-] .card-title,
.card[class*=bg-] .card-title a,
.card[class*=bg-] .icon i {
    color: #fff
}

.card [class*=card-header-] .icon i,
.card[class*=bg-] .icon i {
    border-color: rgba(255, 255, 255, .25)
}

.card [class*=card-header-] .author a,
.card [class*=card-header-] .card-category,
.card [class*=card-header-] .card-description,
.card [class*=card-header-] .stats,
.card[class*=bg-] .author a,
.card[class*=bg-] .card-category,
.card[class*=bg-] .card-description,
.card[class*=bg-] .stats {
    color: rgba(255, 255, 255, .8)
}

.card [class*=card-header-] .author a:active,
.card [class*=card-header-] .author a:focus,
.card [class*=card-header-] .author a:hover,
.card[class*=bg-] .author a:active,
.card[class*=bg-] .author a:focus,
.card[class*=bg-] .author a:hover {
    color: #fff
}

.card .author .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px
}

.card .author a {
    color: #3c4858;
    text-decoration: none
}

.card .author a .ripple-container {
    display: none
}

.card .card-category-social .fa {
    font-size: 24px;
    position: relative;
    margin-top: -4px;
    top: 2px;
    margin-right: 5px
}

.card .card-category-social .material-icons {
    position: relative;
    top: 5px
}

.card[class*=bg-],
.card[class*=bg-] .card-body {
    border-radius: 6px
}

.card[class*=bg-] .card-body h1 small,
.card[class*=bg-] .card-body h2 small,
.card[class*=bg-] .card-body h3 small,
.card[class*=bg-] h1 small,
.card[class*=bg-] h2 small,
.card[class*=bg-] h3 small {
    color: rgba(255, 255, 255, .8)
}

.card .card-stats {
    background: 0;
    display: flex
}

.card .card-stats .author,
.card .card-stats .stats {
    display: inline-flex
}

.card-plain {
    background: 0;
    box-shadow: none !important
}

.card-plain .card-header:not(.card-avatar) {
    margin-left: 0;
    margin-right: 0
}

.card-plain .card-header:not(.card-avatar) .card-category,
.card-plain .card-header:not(.card-avatar) .card-description {
    color: #999
}

.card-plain .card-body {
    padding-left: 5px;
    padding-right: 5px
}

.card-plain .card-header-image {
    margin: 0 !important;
    border-radius: 6px
}

.card-plain .card-header-image img {
    border-radius: 6px
}

.card-plain .card-footer {
    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent
}

.card-profile,
.card-testimonial {
    margin-top: 30px;
    text-align: center
}

.card-profile .card-avatar,
.card-testimonial .card-avatar {
    margin: -50px auto 0;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.card-profile .card-avatar+.card-body,
.card-testimonial .card-avatar+.card-body {
    margin-top: 15px
}

.card-profile .card-avatar img,
.card-testimonial .card-avatar img {
    width: 100%;
    height: auto
}

.card-profile .card-body+.card-footer,
.card-testimonial .card-body+.card-footer {
    margin-top: -15px
}

.card-profile .card-footer .btn.btn-just-icon,
.card-testimonial .card-footer .btn.btn-just-icon {
    font-size: 20px;
    padding: 12px;
    line-height: 1em
}

.card-plain.card-profile .card-avatar,
.card-plain.card-testimonial .card-avatar {
    margin-top: 0
}

.card-testimonial .card-avatar {
    max-width: 100px;
    max-height: 100px
}

.card-testimonial .card-footer {
    margin-top: 0;
    display: block
}

.card-testimonial .card-footer .card-avatar {
    margin-top: 10px;
    margin-bottom: -60px
}

.card-testimonial .card-description {
    font-style: italic
}

.card-testimonial .card-description+.card-title,
.card-testimonial .icon {
    margin-top: 30px
}

.card-testimonial .icon .material-icons {
    font-size: 40px
}

.card-profile .card-header:not([class*=card-header-]) {
    background: 0;
    box-shadow: none
}

.card-profile .card-avatar {
    max-width: 130px;
    max-height: 130px
}

.card-blog {
    margin-top: 60px
}

.card-blog [class*=col-] .card-header-image img {
    width: 100%
}

.card-blog .carf-footer .stats .material-icons {
    font-size: 18px;
    position: relative;
    top: 4px;
    width: 19px
}

.card-product {
    margin-top: 30px
}

.card-product .btn-simple.btn-just-icon {
    padding: 0
}

.card-product .card-footer .price h4 {
    margin-bottom: 0
}

.card-product .card-footer .btn {
    margin: 0
}

.card-product .card-category,
.card-product .card-description,
.card-product .card-title {
    text-align: center
}

.card-product .category {
    margin-bottom: 0
}

.card-product .category~.card-title {
    margin-top: 0
}

.card-product .price {
    font-size: 18px;
    color: #9a9a9a
}

.card-product .price-old {
    text-decoration: line-through;
    font-size: 16px;
    color: #9a9a9a
}

.card-product .price-new {
    color: #f44336
}

.card-pricing {
    text-align: center
}

.card-pricing:after {
    background-color: rgba(0, 0, 0, .7) !important
}

.card-pricing .card-title {
    margin-top: 30px
}

.card-pricing .card-body {
    padding: 15px !important;
    margin: 0 !important
}

.card-pricing .card-icon {
    padding: 10px 0 0
}

.card-pricing .card-icon i {
    font-size: 55px;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    width: 130px;
    line-height: 130px;
    height: 130px
}

.card-pricing h1 small {
    font-size: 18px;
    display: inline-flex;
    height: 0
}

.card-pricing h1 small:first-child {
    position: relative;
    top: -17px;
    font-size: 26px
}

.card-pricing ul {
    list-style: none;
    padding: 0;
    max-width: 240px;
    margin: 10px auto
}

.card-pricing ul li {
    color: #999;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid rgba(153, 153, 153, .3)
}

.card-pricing ul li:last-child {
    border: 0
}

.card-pricing ul li b {
    color: #3c4858
}

.card-pricing ul li i {
    top: 6px;
    position: relative
}

.card-pricing.card-background ul li,
.card-pricing[class*=bg-] ul li {
    color: #fff;
    border-color: rgba(255, 255, 255, .3)
}

.card-pricing.card-background ul li b,
.card-pricing[class*=bg-] ul li b {
    color: #fff;
    font-weight: 700
}

.card-pricing.card-background .card-category,
.card-pricing.card-background [class*=text-],
.card-pricing[class*=bg-] .card-category,
.card-pricing[class*=bg-] [class*=text-] {
    color: #fff !important
}

.card-pricing .card-footer {
    z-index: 2
}

.card-collapse,
.card-collapse .card-header {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0
}

.card-collapse {
    margin: 0
}

.card-collapse .card-header {
    border-bottom: 1px solid #ddd;
    padding: 25px 10px 5px 0;
    margin: 0;
    box-shadow: none !important;
    background: #fff
}

.card-collapse .card-header a {
    color: #3c4858;
    font-size: .9375rem;
    display: block
}

.card-collapse .card-header a:active,
.card-collapse .card-header a:hover,
.card-collapse .card-header a[aria-expanded=true] {
    color: #e91e63
}

.card-collapse .card-header a i {
    float: right;
    top: 4px;
    position: relative
}

.card-collapse .card-header a[aria-expanded=true] i {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.card-collapse .card-body {
    padding: 15px 0 5px
}

.card-signup .card-header {
    padding: 20px 0;
    width: 100%;
    margin: -40px 20px 15px
}

.card-signup .card-body {
    padding: 0 30px 0 10px
}

.card-signup .form-check label {
    margin-left: 18px
}

.card-signup .form-check .form-check-sign {
    padding-right: 27px;
    left: 27px
}

.card-signup .social-line {
    margin-top: 1rem;
    text-align: center;
    padding: 0
}

.card-signup .social-line .btn {
    color: #fff;
    margin-left: 5px;
    margin-right: 5px
}

.card-form-horizontal .card-body {
    padding-left: 15px;
    padding-right: 15px
}

.card-form-horizontal .form-group .form-control,
.card-form-horizontal .input-group .form-control {
    margin-bottom: 0
}

.card-form-horizontal .btn,
.card-form-horizontal form {
    margin: 0
}

.card-form-horizontal .input-group .input-group-addon {
    padding-left: 0
}

.card-form-horizontal .bmd-form-group {
    padding-bottom: 0;
    padding-top: 0
}

.card-background {
    background-position: center center;
    background-size: cover;
    text-align: center
}

.card-background .card-body {
    position: relative;
    z-index: 2;
    min-height: 280px;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 440px;
    margin: 0 auto
}

.card-background .card-category,
.card-background .card-description,
.card-background small {
    color: rgba(255, 255, 255, .7) !important
}

.card-background .card-title {
    color: #fff;
    margin-top: 10px
}

.card-background:not(.card-pricing) .btn {
    margin-bottom: 0
}

.card-background:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, .56);
    border-radius: 6px
}

.card .table tr:first-child td {
    border-top: 0
}

.card .card-title {
    margin-top: 0;
    margin-bottom: 3px
}

.card .card-body {
    padding: .9375rem 20px;
    position: relative
}

.card .card-header {
    z-index: 3 !important
}

.card .card-header .card-category {
    margin: 0
}

.card .card-header.card-header-text {
    display: inline-block
}

.card .card-header.card-header-text:after {
    content: "";
    display: table
}

.card .card-header.card-header-icon i,
.card .card-header.card-header-text i {
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 33px
}

.card .card-header.card-header-icon .card-title,
.card .card-header.card-header-text .card-title {
    margin-top: 15px;
    color: #3c4858
}

.card .card-header.card-header-icon h4,
.card .card-header.card-header-text h4 {
    font-weight: 300
}

.card .card-header.card-header-tabs .nav-tabs {
    background: 0;
    padding: 0
}

.card .card-header.card-header-tabs .nav-tabs-title {
    float: left;
    padding: 10px 10px 10px 0;
    line-height: 24px
}

.card.card-plain .card-header.card-header-icon+.card-body .card-category,
.card.card-plain .card-header.card-header-icon+.card-body .card-title {
    margin-top: -20px
}

.card .card-actions {
    position: absolute;
    z-index: 1;
    top: -50px;
    width: calc(100% - 30px);
    left: 17px;
    right: 17px;
    text-align: center
}

.card .card-actions .card-header {
    padding: 0;
    min-height: 160px
}

.card .card-actions .btn {
    padding-left: 12px;
    padding-right: 12px
}

.card .card-actions .fix-broken-card {
    position: absolute;
    top: -65px
}

.card.card-chart .card-footer i:nth-child(1n+2) {
    width: 18px;
    text-align: center
}

.card.card-chart .card-category {
    margin: 0
}

.card .card-body+.card-footer,
.card .card-footer {
    padding: 10px 15px 0
}

.bmd-form-group {
    margin-bottom: 10px
}

.card .card-body+.card-footer h6,
.card .card-footer h6 {
    width: 100%
}

.card .card-body+.card-footer .stats,
.card .card-footer .stats {
    color: #999;
    font-size: 12px;
    line-height: 22px
}

.card .card-body+.card-footer .stats .card-category,
.card .card-footer .stats .card-category {
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0
}

.card .card-body+.card-footer .stats .material-icons,
.card .card-footer .stats .material-icons {
    position: relative;
    top: 4px;
    font-size: 16px
}

.card [class*=card-header-] {
    margin: 0 15px;
    padding: 0;
    position: relative
}

.card [class*=card-header-]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
    border-radius: 3px;
    margin-top: -20px;
    padding: 15px
}

.card [class*=card-header-] .card-icon,
.card [class*=card-header-] .card-text {
    border-radius: 3px;
    background-color: #999;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left
}

.card [class*=card-header-] .card-text {
    float: none;
    display: inline-block;
    margin-right: 0
}

.card [class*=card-header-] .card-text .card-title {
    color: #fff;
    margin-top: 0
}

.card [class*=card-header-] .ct-chart .card-title {
    color: #fff
}

.card [class*=card-header-] .ct-chart .card-category {
    margin-bottom: 0;
    color: rgba(255, 255, 255, .62)
}

.card [class*=card-header-] .ct-chart .ct-label {
    color: rgba(255, 255, 255, .7)
}

.card [class*=card-header-] .ct-chart .ct-grid {
    stroke: rgba(255, 255, 255, .2)
}

.card [class*=card-header-] .ct-chart .ct-series-a .ct-bar,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-line,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-point,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-slice-donut {
    stroke: rgba(255, 255, 255, .8)
}

.card [class*=card-header-] .ct-chart .ct-series-a .ct-area,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-slice-pie {
    fill: rgba(255, 255, 255, .4)
}

.card [class*=card-header-] .ct-chart .ct-series-a .ct-bar {
    stroke-width: 10px
}

.card [class*=card-header-] .ct-chart .ct-point {
    stroke-width: 10px;
    stroke-linecap: round
}

.card [class*=card-header-] .ct-chart .ct-line {
    fill: none;
    stroke-width: 4px
}

.card [data-header-animation=true] {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: all .3s cubic-bezier(.34, 1.61, .7, 1)
}

.card:hover [data-header-animation=true] {
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0)
}

.card.card-stats .card-header.card-header-icon,
.card.card-stats .card-header.card-header-text {
    text-align: right
}

.card.card-stats .card-header .card-icon+.card-category,
.card.card-stats .card-header .card-icon+.card-title {
    padding-top: 10px
}

.card.card-stats .card-header .card-category,
.card.card-stats .card-header .card-title {
    margin: 0
}

.card.card-stats .card-header .card-category {
    margin-bottom: 0;
    margin-top: 0
}

.card.card-stats .card-header .card-category:not([class*=text-]) {
    color: #999;
    font-size: 14px
}

.card.card-stats .card-header+.card-footer {
    border-top: 1px solid #eee;
    margin-top: 20px
}

.card.card-stats .card-header i {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center
}

.card.card-stats .card-body {
    text-align: right
}

.card .map {
    height: 280px;
    border-radius: 6px;
    margin-top: 15px
}

.card .map.map-big {
    height: 420px
}

.card .card-body.table-full-width {
    padding: 0
}

.card .card-plain .card-header-icon {
    margin-right: 15px !important
}

.iframe-container {
    margin: 0 -20px
}

.iframe-container iframe {
    width: 100%;
    height: 500px;
    border: 0;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.card-wizard .nav.nav-pills .nav-item {
    margin: 0
}

.card-wizard .nav.nav-pills .nav-item .nav-link {
    padding: 6px 15px !important
}

.card-wizard .mat-form-field {
    width: 80%;
    font-size: 14px;
    top: 3px
}

.card-wizard .nav-pills:not(.flex-column) .nav-item+.nav-item:not(:first-child) {
    margin-left: 0
}

.card-wizard .nav-item .nav-link.active,
.card-wizard .nav-item .nav-link:focus,
.card-wizard .nav-item .nav-link:hover {
    background-color: inherit !important;
    box-shadow: none !important
}

.card-wizard .input-group-text {
    padding: 6px 15px 0 !important
}

.card-wizard .card-footer {
    border-top: none !important
}

.card-chart .card-body+.card-footer,
.card-product .card-body+.card-footer {
    border-top: 1px solid #eee
}

.card-product .price {
    color: inherit
}

.card-collapse {
    margin-bottom: 15px
}

.card-collapse .card .card-header a[aria-expanded=true] {
    color: #e91e63
}

.switch label {
    position: relative;
    padding-left: 2.4375rem
}

.details-img-style {
    box-shadow: 0 3px 99px 0 rgb(0 0 0 / 16%);
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px
}

.switch label .bmd-switch-track {
    position: absolute;
    top: .3125rem;
    left: 0;
    display: inline-block;
    width: 2.125rem;
    height: .875rem;
    cursor: pointer;
    background-image: linear-gradient(to right, #eee 0, #eee 50%, #c49bcb 50%, #c49bcb 100%);
    background-position: 0 0;
    background-size: 4.25rem .875rem;
    border-radius: 2.125rem;
    transition: background-position .2s ease-in
}

.switch label .bmd-switch-track::after {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    align-self: center;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: #f1f1f1;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .5);
    transition: left .2s ease-in, background-color .2s ease-in, -webkit-transform .3s ease;
    transition: left .2s ease-in, background-color .2s ease-in, transform .3s ease;
    transition: left .2s ease-in, background-color .2s ease-in, transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.switch label .bmd-switch-track:active::after {
    -webkit-transform: translateY(-50%) scale3d(1.15, .85, 1);
    transform: translateY(-50%) scale3d(1.15, .85, 1)
}

.switch label input {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    opacity: 0
}

.switch label input:checked+.bmd-switch-track {
    background-position: -100%
}

.switch label input:checked+.bmd-switch-track::after {
    left: calc(100% - 1.25rem);
    background-color: #9c27b0
}

.switch label input:disabled+.bmd-switch-track:active::after {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.switch label input:disabled+.bmd-switch-track {
    cursor: default;
    background: #eee
}

.switch label input:disabled+.bmd-switch-track::after {
    background: #bdbdbd
}

.index-page .section-components h6.description {
    margin-top: 5px;
    margin-bottom: 30px
}

.index-page .page-header {
    height: 90vh;
    overflow: hidden
}

.index-page .brand {
    color: #fff;
    text-align: center
}

.index-page .brand h1 {
    font-size: 4.2rem;
    font-weight: 600;
    display: inline-block;
    position: relative
}

.index-page .brand h3 {
    font-size: 1.313rem;
    max-width: 500px;
    margin: 10px auto 0
}

.index-page h4.description {
    font-size: 1.5em
}

.section {
    padding: 70px 0
}

.profile-page .page-header {
    height: 380px;
    background-position: top center
}

.profile-page .profile {
    text-align: center
}

.profile-page .profile img {
    max-width: 160px;
    width: 100%;
    margin: 0 auto;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0)
}

.profile-page .profile .name {
    margin-top: -80px
}

.profile-page .follow {
    position: absolute;
    top: 0;
    right: 0
}

.profile-page .description {
    margin: 1.071rem auto 0;
    max-width: 600px
}

.profile-page .profile-tabs {
    margin-top: 4.284rem
}

.profile-page .gallery {
    margin-top: 3.213rem;
    padding-bottom: 50px
}

.profile-page .gallery img {
    width: 100%;
    margin-bottom: 2.142rem
}

.profile-page .follow .btn-fab {
    margin-top: -28px
}

.profile-page .card-background .content {
    padding: 30% 0;
    min-width: 160px
}

.profile-page .work {
    padding: 40px 0
}

.profile-page .work .collections {
    margin-top: 20px
}

.profile-page .work .stats ul>li {
    padding: 5px 0;
    font-size: 1em
}

.profile-page .work .stats ul>li b {
    font-size: 1.2em
}

.profile-page .connections {
    padding: 40px 0
}

.profile-page .connections .card-profile {
    text-align: left
}

.profile-page .tab-content .collections .card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.profile-page .tab-content .collections .card .card-body .badge {
    display: inline-table;
    margin: 0 auto
}

.card-signup .card-header .social-line .btn {
    color: #fff
}

.card-signup .text-divider {
    margin-top: 30px;
    margin-bottom: 0;
    text-align: center
}

.signup-page .page-header {
    min-height: 100vh;
    height: auto;
    display: inherit
}

.signup-page .page-header .container {
    padding-top: 20vh
}

.signup-page .card-signup {
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
    margin-bottom: 100px;
    padding: 40px 0
}

.signup-page .info-horizontal {
    padding: 0 0 20px
}

.signup-page .social .btn {
    margin: 5px
}

.signup-page .social h4 {
    margin-top: 20px
}

.signup-page .footer .container {
    padding: 0
}

.signup-page .footer .copyright,
.signup-page .footer a {
    color: #fff
}

.form-check .form-check-label {
    cursor: pointer;
    padding-left: 25px
}

.form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .form-check .form-check-label:focus,
.form-group.is-focused .form-check .form-check-label:hover {
    color: rgba(0, 0, 0, .54)
}

fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
    color: rgba(0, 0, 0, .26)
}

.form-check .form-check-label span {
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    transition-duration: .2s
}

.form-check .form-check-label .circle {
    border: 1px solid rgba(0, 0, 0, .54);
    height: 15px;
    width: 15px;
    border-radius: 100%;
    top: 1px
}

.form-check .form-check-label .circle .check {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: #9c27b0;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0)
}

.form-check .form-check-label .form-check-input:checked~.check:after,
.form-check .form-check-label .form-check-input:not(:checked)~.check:after {
    -webkit-animation: .5s rippleOff;
    animation: .5s rippleOff
}

.form-check .form-check-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden
}

.form-check .form-check-input:checked~.check,
.form-check .form-check-input:checked~.circle {
    opacity: 1
}

.form-check .form-check-input:checked~.check {
    background-color: #9c27b0
}

.form-check .form-check-input:checked~.circle {
    border-color: #9c27b0
}

.form-check .form-check-input:checked~.circle .check {
    -webkit-transform: scale3d(.65, .65, 1);
    transform: scale3d(.65, .65, 1)
}

.form-check .form-check-input[disabled]~.check,
.form-check .form-check-input[disabled]~.circle {
    opacity: .26
}

.form-check .form-check-input[disabled]~.check {
    background-color: #000
}

.form-check .form-check-input[disabled]~.circle {
    border-color: #000
}

.form-check .form-check-input[disabled]+.circle .check {
    background-color: #000
}

.form-check .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block
}

.form-check+.form-check {
    margin-top: 0
}

.carousel .carousel-control.left,
.carousel .carousel-control.right {
    background-image: none
}

.carousel .carousel-control.right i {
    right: 0
}

.carousel .card {
    margin-top: 0
}

.carousel .item {
    text-align: center
}

.carousel.carousel-full-nagivation .carousel-control {
    width: 50%
}

.carousel.carousel-full-nagivation .carousel-control.left,
.carousel.carousel-full-nagivation .carousel-control.right {
    background-image: none
}

.carousel.carousel-full-nagivation .carousel-control .fa,
.carousel.carousel-full-nagivation .carousel-control .material-icons {
    display: none
}

.carousel .carousel-control {
    height: 15%;
    margin: auto
}

.carousel .carousel-control-next .material-icons,
.carousel .carousel-control-prev .material-icons {
    z-index: 5;
    display: inline-block;
    font-size: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    margin: auto
}

.carousel .carousel-indicators {
    bottom: 5px
}

.carousel .carousel-indicators .active,
.carousel .carousel-indicators li {
    margin: 11px 10px
}

.carousel .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    text-indent: -999px;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2)
}

.carousel .carousel-indicators .active {
    margin-top: 10px;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2)
}

.carousel .carousel-caption {
    padding-bottom: 45px
}

.carousel .carousel-caption .material-icons {
    position: relative;
    top: 5px
}

form {
    margin-bottom: 1.125rem;
    padding-top: 10px
}

.card form {
    margin: 0
}

.navbar form {
    margin-bottom: 0
}

.navbar form .bmd-form-group {
    display: inline-block;
    padding-top: 0
}

.navbar form .btn {
    margin-bottom: 0
}

.form-control {
    background: center bottom no-repeat, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    border: 0;
    height: 36px;
    transition: background 0 ease-out;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    font-size: 14px
}

.bmd-form-group.mat-focused .form-control,
.form-control:focus {
    background-size: 100% 100%, 100% 100%;
    transition-duration: .3s
}

.form-control::-moz-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px
}

.form-control:-ms-input-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px
}

.form-control::-webkit-input-placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 14px
}

.has-white .form-control::-moz-placeholder {
    color: #fff
}

.has-white .form-control:-ms-input-placeholder {
    color: #fff
}

.has-white .form-control::-webkit-input-placeholder {
    color: #fff
}

.bmd-help {
    position: absolute;
    display: none;
    font-weight: 400
}

.bmd-form-group.mat-focused .bmd-help {
    display: block
}

.bmd-help:nth-of-type(2) {
    padding-top: 1rem
}

.bmd-help+.bmd-help {
    position: relative;
    margin-bottom: 0
}

.checkbox label,
.checkbox-inline,
.is-focused .checkbox label,
.is-focused .checkbox-inline,
.is-focused .radio label,
.is-focused .radio-inline,
.is-focused .switch label,
.radio label,
.radio-inline,
.switch label {
    color: #999
}

.checkbox label label:has(input[type=checkbox][disabled]),
.checkbox label label:has(input[type=checkbox][disabled]):focus,
.checkbox label label:has(input[type=checkbox][disabled]):hover,
.checkbox label label:has(input[type=radio][disabled]),
.checkbox label label:has(input[type=radio][disabled]):focus,
.checkbox label label:has(input[type=radio][disabled]):hover,
.checkbox-inline label:has(input[type=checkbox][disabled]),
.checkbox-inline label:has(input[type=checkbox][disabled]):focus,
.checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.checkbox-inline label:has(input[type=radio][disabled]),
.checkbox-inline label:has(input[type=radio][disabled]):focus,
.checkbox-inline label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]),
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):focus,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox label label:has(input[type=radio][disabled]),
.is-focused .checkbox label label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox label label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]),
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]),
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):hover,
.is-focused .radio label label:has(input[type=checkbox][disabled]),
.is-focused .radio label label:has(input[type=checkbox][disabled]):focus,
.is-focused .radio label label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio label label:has(input[type=radio][disabled]),
.is-focused .radio label label:has(input[type=radio][disabled]):focus,
.is-focused .radio label label:has(input[type=radio][disabled]):hover,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]),
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):focus,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio-inline label:has(input[type=radio][disabled]),
.is-focused .radio-inline label:has(input[type=radio][disabled]):focus,
.is-focused .radio-inline label:has(input[type=radio][disabled]):hover,
.is-focused .switch label label:has(input[type=checkbox][disabled]),
.is-focused .switch label label:has(input[type=checkbox][disabled]):focus,
.is-focused .switch label label:has(input[type=checkbox][disabled]):hover,
.is-focused .switch label label:has(input[type=radio][disabled]),
.is-focused .switch label label:has(input[type=radio][disabled]):focus,
.is-focused .switch label label:has(input[type=radio][disabled]):hover,
.radio label label:has(input[type=checkbox][disabled]),
.radio label label:has(input[type=checkbox][disabled]):focus,
.radio label label:has(input[type=checkbox][disabled]):hover,
.radio label label:has(input[type=radio][disabled]),
.radio label label:has(input[type=radio][disabled]):focus,
.radio label label:has(input[type=radio][disabled]):hover,
.radio-inline label:has(input[type=checkbox][disabled]),
.radio-inline label:has(input[type=checkbox][disabled]):focus,
.radio-inline label:has(input[type=checkbox][disabled]):hover,
.radio-inline label:has(input[type=radio][disabled]),
.radio-inline label:has(input[type=radio][disabled]):focus,
.radio-inline label:has(input[type=radio][disabled]):hover,
.switch label label:has(input[type=checkbox][disabled]),
.switch label label:has(input[type=checkbox][disabled]):focus,
.switch label label:has(input[type=checkbox][disabled]):hover,
.switch label label:has(input[type=radio][disabled]),
.switch label label:has(input[type=radio][disabled]):focus,
.switch label label:has(input[type=radio][disabled]):hover,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox label:focus,
fieldset[disabled] .checkbox label:hover,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox-inline:focus,
fieldset[disabled] .checkbox-inline:hover,
fieldset[disabled] .is-focused .checkbox label,
fieldset[disabled] .is-focused .checkbox label:focus,
fieldset[disabled] .is-focused .checkbox label:hover,
fieldset[disabled] .is-focused .checkbox-inline,
fieldset[disabled] .is-focused .checkbox-inline:focus,
fieldset[disabled] .is-focused .checkbox-inline:hover,
fieldset[disabled] .is-focused .radio label,
fieldset[disabled] .is-focused .radio label:focus,
fieldset[disabled] .is-focused .radio label:hover,
fieldset[disabled] .is-focused .radio-inline,
fieldset[disabled] .is-focused .radio-inline:focus,
fieldset[disabled] .is-focused .radio-inline:hover,
fieldset[disabled] .is-focused .switch label,
fieldset[disabled] .is-focused .switch label:focus,
fieldset[disabled] .is-focused .switch label:hover,
fieldset[disabled] .radio label,
fieldset[disabled] .radio label:focus,
fieldset[disabled] .radio label:hover,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio-inline:focus,
fieldset[disabled] .radio-inline:hover,
fieldset[disabled] .switch label,
fieldset[disabled] .switch label:focus,
fieldset[disabled] .switch label:hover {
    color: #999
}

[class*=" bmd-label"],
[class^=bmd-label] {
    color: #999
}

.form-control,
.is-focused .form-control {
    background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.form-control.disabled,
.form-control:disabled,
.form-control[disabled],
fieldset[disabled][disabled] .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.form-control.form-control-success,
.is-focused .form-control.form-control-success {
    background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.form-control.form-control-warning,
.is-focused .form-control.form-control-warning {
    background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.form-control.form-control-danger,
.is-focused .form-control.form-control-danger {
    background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #999
}

.is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(153, 153, 153, .9);
    border-radius: .25rem
}

.is-focused .form-control.is-valid,
.was-validated .is-focused .form-control:valid {
    border-color: #999;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.is-focused .form-control.is-valid:focus,
.was-validated .is-focused .form-control:valid:focus {
    border-color: #999;
    box-shadow: 0 0 0 .2rem rgba(153, 153, 153, .25)
}

.is-focused .form-control.is-valid~.valid-feedback,
.is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .is-focused .form-control:valid~.valid-feedback,
.was-validated .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.is-focused textarea.form-control.is-valid,
.was-validated .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.is-focused .custom-select.is-valid,
.was-validated .is-focused .custom-select:valid {
    border-color: #999;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.is-focused .custom-select.is-valid:focus,
.was-validated .is-focused .custom-select:valid:focus {
    border-color: #999;
    box-shadow: 0 0 0 .2rem rgba(153, 153, 153, .25)
}

.is-focused .custom-select.is-valid~.valid-feedback,
.is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .is-focused .custom-select:valid~.valid-feedback,
.was-validated .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.is-focused .form-control-file.is-valid~.valid-feedback,
.is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .is-focused .form-check-input:valid~.form-check-label {
    color: #999
}

.is-focused .form-check-input.is-valid~.valid-feedback,
.is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .is-focused .custom-control-input:valid~.custom-control-label {
    color: #999
}

.is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #999
}

.is-focused .custom-control-input.is-valid~.valid-feedback,
.is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #b3b3b3;
    background-color: #b3b3b3
}

.is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(153, 153, 153, .25)
}

.is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #999
}

.is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #999
}

.is-focused .custom-file-input.is-valid~.valid-feedback,
.is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #999;
    box-shadow: 0 0 0 .2rem rgba(153, 153, 153, .25)
}

.is-focused [class*=" bmd-label"],
.is-focused [class^=bmd-label] {
    color: #9c27b0
}

.is-focused .bmd-label-placeholder {
    color: #999
}

.is-focused .form-control {
    border-color: #d2d2d2
}

.is-focused .bmd-help {
    color: #555
}

.has-success [class*=" bmd-label"],
.has-success [class^=bmd-label] {
    color: #4caf50
}

.has-success .form-control,
.is-focused .has-success .form-control {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-success .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-success .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-success .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-success .form-control.disabled,
.has-success .form-control:disabled,
.has-success .form-control[disabled],
fieldset[disabled][disabled] .has-success .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-success .form-control.form-control-success,
.is-focused .has-success .form-control.form-control-success {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-success .form-control.form-control-warning,
.is-focused .has-success .form-control.form-control-warning {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-success .form-control.form-control-danger,
.is-focused .has-success .form-control.form-control-danger {
    background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-success .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #4caf50
}

.has-success .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(76, 175, 80, .9);
    border-radius: .25rem
}

.has-success .is-focused .form-control.is-valid,
.was-validated .has-success .is-focused .form-control:valid {
    border-color: #4caf50;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-success .is-focused .form-control.is-valid:focus,
.was-validated .has-success .is-focused .form-control:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.has-success .is-focused .form-control.is-valid~.valid-feedback,
.has-success .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused textarea.form-control.is-valid,
.was-validated .has-success .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-success .is-focused .custom-select.is-valid,
.was-validated .has-success .is-focused .custom-select:valid {
    border-color: #4caf50;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-success .is-focused .custom-select.is-valid:focus,
.was-validated .has-success .is-focused .custom-select:valid:focus {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.has-success .is-focused .custom-select.is-valid~.valid-feedback,
.has-success .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused .form-control-file.is-valid~.valid-feedback,
.has-success .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-success .is-focused .form-check-input:valid~.form-check-label {
    color: #4caf50
}

.has-success .is-focused .form-check-input.is-valid~.valid-feedback,
.has-success .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label {
    color: #4caf50
}

.has-success .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #4caf50
}

.has-success .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #6ec071;
    background-color: #6ec071
}

.has-success .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.has-success .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-success .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #4caf50
}

.has-success .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-success .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #4caf50
}

.has-success .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-success .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-success .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #4caf50;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25)
}

.has-success .is-focused .bmd-label-placeholder,
.has-success .is-focused [class*=" bmd-label"],
.has-success .is-focused [class^=bmd-label] {
    color: #4caf50
}

.has-success .is-focused .form-control {
    border-color: #4caf50
}

.has-success .is-focused .bmd-help {
    color: #555
}

.has-info [class*=" bmd-label"],
.has-info [class^=bmd-label] {
    color: #00bcd4
}

.has-info .form-control,
.is-focused .has-info .form-control {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-info .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-info .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-info .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-info .form-control.disabled,
.has-info .form-control:disabled,
.has-info .form-control[disabled],
fieldset[disabled][disabled] .has-info .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-info .form-control.form-control-success,
.is-focused .has-info .form-control.form-control-success {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-info .form-control.form-control-warning,
.is-focused .has-info .form-control.form-control-warning {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-info .form-control.form-control-danger,
.is-focused .has-info .form-control.form-control-danger {
    background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-info .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #00bcd4
}

.has-info .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(0, 188, 212, .9);
    border-radius: .25rem
}

.has-info .is-focused .form-control.is-valid,
.was-validated .has-info .is-focused .form-control:valid {
    border-color: #00bcd4;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-info .is-focused .form-control.is-valid:focus,
.was-validated .has-info .is-focused .form-control:valid:focus {
    border-color: #00bcd4;
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .25)
}

.has-info .is-focused .form-control.is-valid~.valid-feedback,
.has-info .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused textarea.form-control.is-valid,
.was-validated .has-info .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-info .is-focused .custom-select.is-valid,
.was-validated .has-info .is-focused .custom-select:valid {
    border-color: #00bcd4;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-info .is-focused .custom-select.is-valid:focus,
.was-validated .has-info .is-focused .custom-select:valid:focus {
    border-color: #00bcd4;
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .25)
}

.has-info .is-focused .custom-select.is-valid~.valid-feedback,
.has-info .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused .form-control-file.is-valid~.valid-feedback,
.has-info .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-info .is-focused .form-check-input:valid~.form-check-label {
    color: #00bcd4
}

.has-info .is-focused .form-check-input.is-valid~.valid-feedback,
.has-info .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label {
    color: #00bcd4
}

.has-info .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #00bcd4
}

.has-info .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #08e3ff;
    background-color: #08e3ff
}

.has-info .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .25)
}

.has-info .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-info .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #00bcd4
}

.has-info .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-info .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #00bcd4
}

.has-info .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-info .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-info .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #00bcd4;
    box-shadow: 0 0 0 .2rem rgba(0, 188, 212, .25)
}

.has-info .is-focused .bmd-label-placeholder,
.has-info .is-focused [class*=" bmd-label"],
.has-info .is-focused [class^=bmd-label] {
    color: #00bcd4
}

.has-info .is-focused .form-control {
    border-color: #00bcd4
}

.has-info .is-focused .bmd-help {
    color: #555
}

.has-white [class*=" bmd-label"],
.has-white [class^=bmd-label] {
    color: #fff
}

.has-white .form-control,
.is-focused .has-white .form-control {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px)
}

.has-white .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px)
}

.has-white .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px)
}

.has-white .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px)
}

.has-white .form-control.disabled,
.has-white .form-control:disabled,
.has-white .form-control[disabled],
fieldset[disabled][disabled] .has-white .form-control {
    background-image: linear-gradient(to right, #fff 0, #fff 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-white .form-control.form-control-success,
.is-focused .has-white .form-control.form-control-success {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-white .form-control.form-control-warning,
.is-focused .has-white .form-control.form-control-warning {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-white .form-control.form-control-danger,
.is-focused .has-white .form-control.form-control-danger {
    background-image: linear-gradient(to top, #fff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #fff 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-white .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #fff
}

.has-white .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(255, 255, 255, .9);
    border-radius: .25rem
}

.has-white .is-focused .form-control.is-valid,
.was-validated .has-white .is-focused .form-control:valid {
    border-color: #fff;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-white .is-focused .form-control.is-valid:focus,
.was-validated .has-white .is-focused .form-control:valid:focus {
    border-color: #fff;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25)
}

.has-white .is-focused .form-control.is-valid~.valid-feedback,
.has-white .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused textarea.form-control.is-valid,
.was-validated .has-white .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-white .is-focused .custom-select.is-valid,
.was-validated .has-white .is-focused .custom-select:valid {
    border-color: #fff;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-white .is-focused .custom-select.is-valid:focus,
.was-validated .has-white .is-focused .custom-select:valid:focus {
    border-color: #fff;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25)
}

.has-white .is-focused .custom-select.is-valid~.valid-feedback,
.has-white .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused .form-control-file.is-valid~.valid-feedback,
.has-white .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-white .is-focused .form-check-input:valid~.form-check-label {
    color: #fff
}

.has-white .is-focused .form-check-input.is-valid~.valid-feedback,
.has-white .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label {
    color: #fff
}

.has-white .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #fff
}

.has-white .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #fff;
    background-color: #fff
}

.has-white .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25)
}

.has-white .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-white .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #fff
}

.has-white .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-white .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #fff
}

.has-white .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-white .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-white .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #fff;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25)
}

.has-white .is-focused .bmd-label-placeholder,
.has-white .is-focused [class*=" bmd-label"],
.has-white .is-focused [class^=bmd-label] {
    color: #fff
}

.has-white .is-focused .form-control {
    border-color: #fff
}

.has-white .is-focused .bmd-help {
    color: #555
}

.has-white .form-control:focus {
    color: #fff
}

.has-warning [class*=" bmd-label"],
.has-warning [class^=bmd-label] {
    color: #ff9800
}

.has-warning .form-control,
.is-focused .has-warning .form-control {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-warning .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-warning .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-warning .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-warning .form-control.disabled,
.has-warning .form-control:disabled,
.has-warning .form-control[disabled],
fieldset[disabled][disabled] .has-warning .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control.form-control-success {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control.form-control-warning {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control.form-control-danger {
    background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-warning .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #ff9800
}

.has-warning .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(255, 152, 0, .9);
    border-radius: .25rem
}

.has-warning .is-focused .form-control.is-valid,
.was-validated .has-warning .is-focused .form-control:valid {
    border-color: #ff9800;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-warning .is-focused .form-control.is-valid:focus,
.was-validated .has-warning .is-focused .form-control:valid:focus {
    border-color: #ff9800;
    box-shadow: 0 0 0 .2rem rgba(255, 152, 0, .25)
}

.has-warning .is-focused .form-control.is-valid~.valid-feedback,
.has-warning .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused textarea.form-control.is-valid,
.was-validated .has-warning .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-warning .is-focused .custom-select.is-valid,
.was-validated .has-warning .is-focused .custom-select:valid {
    border-color: #ff9800;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-warning .is-focused .custom-select.is-valid:focus,
.was-validated .has-warning .is-focused .custom-select:valid:focus {
    border-color: #ff9800;
    box-shadow: 0 0 0 .2rem rgba(255, 152, 0, .25)
}

.has-warning .is-focused .custom-select.is-valid~.valid-feedback,
.has-warning .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused .form-control-file.is-valid~.valid-feedback,
.has-warning .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-warning .is-focused .form-check-input:valid~.form-check-label {
    color: #ff9800
}

.has-warning .is-focused .form-check-input.is-valid~.valid-feedback,
.has-warning .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label {
    color: #ff9800
}

.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #ff9800
}

.has-warning .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #ffad33;
    background-color: #ffad33
}

.has-warning .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(255, 152, 0, .25)
}

.has-warning .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-warning .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #ff9800
}

.has-warning .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-warning .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #ff9800
}

.has-warning .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-warning .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-warning .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #ff9800;
    box-shadow: 0 0 0 .2rem rgba(255, 152, 0, .25)
}

.has-warning .is-focused .bmd-label-placeholder,
.has-warning .is-focused [class*=" bmd-label"],
.has-warning .is-focused [class^=bmd-label] {
    color: #ff9800
}

.has-warning .is-focused .form-control {
    border-color: #ff9800
}

.has-warning .is-focused .bmd-help {
    color: #555
}

.has-danger [class*=" bmd-label"],
.has-danger [class^=bmd-label] {
    color: #f44336
}

.has-danger .form-control,
.is-focused .has-danger .form-control {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-danger .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-danger .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-danger .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-danger .form-control.disabled,
.has-danger .form-control:disabled,
.has-danger .form-control[disabled],
fieldset[disabled][disabled] .has-danger .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control.form-control-success {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control.form-control-warning {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control.form-control-danger {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-danger .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #f44336
}

.has-danger .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(244, 67, 54, .9);
    border-radius: .25rem
}

.has-danger .is-focused .form-control.is-valid,
.was-validated .has-danger .is-focused .form-control:valid {
    border-color: #f44336;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-danger .is-focused .form-control.is-valid:focus,
.was-validated .has-danger .is-focused .form-control:valid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.has-danger .is-focused .form-control.is-valid~.valid-feedback,
.has-danger .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused textarea.form-control.is-valid,
.was-validated .has-danger .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-danger .is-focused .custom-select.is-valid,
.was-validated .has-danger .is-focused .custom-select:valid {
    border-color: #f44336;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-danger .is-focused .custom-select.is-valid:focus,
.was-validated .has-danger .is-focused .custom-select:valid:focus {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.has-danger .is-focused .custom-select.is-valid~.valid-feedback,
.has-danger .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused .form-control-file.is-valid~.valid-feedback,
.has-danger .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-danger .is-focused .form-check-input:valid~.form-check-label {
    color: #f44336
}

.has-danger .is-focused .form-check-input.is-valid~.valid-feedback,
.has-danger .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label {
    color: #f44336
}

.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #f44336
}

.has-danger .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #f77066;
    background-color: #f77066
}

.has-danger .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.has-danger .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-danger .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #f44336
}

.has-danger .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-danger .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #f44336
}

.has-danger .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-danger .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-danger .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #f44336;
    box-shadow: 0 0 0 .2rem rgba(244, 67, 54, .25)
}

.has-danger .is-focused .bmd-label-placeholder,
.has-danger .is-focused [class*=" bmd-label"],
.has-danger .is-focused [class^=bmd-label] {
    color: #f44336
}

.has-danger .is-focused .form-control {
    border-color: #f44336
}

.has-danger .is-focused .bmd-help {
    color: #555
}

.has-rose [class*=" bmd-label"],
.has-rose [class^=bmd-label] {
    color: #e91e63
}

.has-rose .form-control,
.is-focused .has-rose .form-control {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-rose .form-control:invalid {
    background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-rose .form-control:-moz-read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-rose .form-control:read-only {
    background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px)
}

.has-rose .form-control.disabled,
.has-rose .form-control:disabled,
.has-rose .form-control[disabled],
fieldset[disabled][disabled] .has-rose .form-control {
    background-image: linear-gradient(to right, #d2d2d2 0, #d2d2d2 30%, transparent 30%, transparent 100%);
    background-repeat: repeat-x;
    background-size: 3px 1px
}

.has-rose .form-control.form-control-success,
.is-focused .has-rose .form-control.form-control-success {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="
}

.has-rose .form-control.form-control-warning,
.is-focused .has-rose .form-control.form-control-warning {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"
}

.has-rose .form-control.form-control-danger,
.is-focused .has-rose .form-control.form-control-danger {
    background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="
}

.has-rose .is-focused .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #e91e63
}

.has-rose .is-focused .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(233, 30, 99, .9);
    border-radius: .25rem
}

.has-rose .is-focused .form-control.is-valid,
.was-validated .has-rose .is-focused .form-control:valid {
    border-color: #e91e63;
    padding-right: calc(1.5em+.875rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: center right calc(.375em+.21875rem);
    background-size: calc(.75em+.4375rem) calc(.75em+.4375rem)
}

.has-rose .is-focused .form-control.is-valid:focus,
.was-validated .has-rose .is-focused .form-control:valid:focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2rem rgba(233, 30, 99, .25)
}

.has-rose .is-focused .form-control.is-valid~.valid-feedback,
.has-rose .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-control:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused textarea.form-control.is-valid,
.was-validated .has-rose .is-focused textarea.form-control:valid {
    padding-right: calc(1.5em+.875rem);
    background-position: top calc(.375em+.21875rem) right calc(.375em+.21875rem)
}

.has-rose .is-focused .custom-select.is-valid,
.was-validated .has-rose .is-focused .custom-select:valid {
    border-color: #e91e63;
    padding-right: calc((1em+.875rem) * 3 / 4+1rem);
    /* background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") right 0 center/8px 10px no-repeat,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg 'viewBox='0088'%3e%3cpathfill='%234caf50'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1rem/calc(.75em+.4375rem) calc(.75em+.4375rem) no-repeat rgba(0,0,0,0) */
}

.has-rose .is-focused .custom-select.is-valid:focus,
.was-validated .has-rose .is-focused .custom-select:valid:focus {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2rem rgba(233, 30, 99, .25)
}

.has-rose .is-focused .custom-select.is-valid~.valid-feedback,
.has-rose .is-focused .custom-select.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused .form-control-file.is-valid~.valid-feedback,
.has-rose .is-focused .form-control-file.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .form-control-file:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-control-file:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused .form-check-input.is-valid~.form-check-label,
.was-validated .has-rose .is-focused .form-check-input:valid~.form-check-label {
    color: #e91e63
}

.has-rose .is-focused .form-check-input.is-valid~.valid-feedback,
.has-rose .is-focused .form-check-input.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label,
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label {
    color: #e91e63
}

.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label::before {
    border-color: #e91e63
}

.has-rose .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-control-input.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #ee4c83;
    background-color: #ee4c83
}

.has-rose .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(233, 30, 99, .25)
}

.has-rose .is-focused .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .has-rose .is-focused .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #e91e63
}

.has-rose .is-focused .custom-file-input.is-valid~.custom-file-label,
.was-validated .has-rose .is-focused .custom-file-input:valid~.custom-file-label {
    border-color: #e91e63
}

.has-rose .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-file-input.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-tooltip {
    display: block
}

.has-rose .is-focused .custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .has-rose .is-focused .custom-file-input:valid:focus~.custom-file-label {
    border-color: #e91e63;
    box-shadow: 0 0 0 .2rem rgba(233, 30, 99, .25)
}

.has-rose .is-focused .bmd-label-placeholder,
.has-rose .is-focused [class*=" bmd-label"],
.has-rose .is-focused [class^=bmd-label] {
    color: #e91e63
}

.has-rose .is-focused .form-control {
    border-color: #e91e63
}

.has-rose .is-focused .bmd-help {
    color: #555
}

.bmd-form-group {
    position: relative
}

.bmd-form-group:not(.has-success):not(.has-danger) [class*=" bmd-label"].bmd-label-floating,
.bmd-form-group:not(.has-success):not(.has-danger) [class^=bmd-label].bmd-label-floating {
    color: #aaa
}

.bmd-form-group [class*=" bmd-label"],
.bmd-form-group [class^=bmd-label] {
    position: absolute;
    pointer-events: none;
    transition: .3s ease all
}

.bmd-form-group [class*=" bmd-label"].bmd-label-floating,
.bmd-form-group [class^=bmd-label].bmd-label-floating {
    will-change: left, top, contents;
    margin: 0;
    line-height: 1.4;
    font-weight: 400
}

.bmd-form-group.is-filled .bmd-label-placeholder {
    display: none
}

.bmd-form-group.bmd-collapse-inline {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: 2.1em
}

.bmd-form-group.bmd-collapse-inline .collapse {
    flex: 1;
    display: none
}

.bmd-form-group.bmd-collapse-inline .collapse.show {
    max-width: 1200px
}

.bmd-form-group.bmd-collapse-inline .collapse.show,
.bmd-form-group.bmd-collapse-inline .collapsing,
.bmd-form-group.bmd-collapse-inline .width:not(.collapse) {
    display: block
}

.bmd-form-group.bmd-collapse-inline .collapsing {
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.bmd-form-group .form-control,
.bmd-form-group input::-webkit-input-placeholder,
.bmd-form-group label {
    line-height: 1.1
}

.bmd-form-group .form-control,
.bmd-form-group input::-moz-placeholder,
.bmd-form-group label {
    line-height: 1.1
}

.bmd-form-group .form-control,
.bmd-form-group input:-ms-input-placeholder,
.bmd-form-group label {
    line-height: 1.1
}

.bmd-form-group .form-control,
.bmd-form-group input::-ms-input-placeholder,
.bmd-form-group label {
    line-height: 1.1
}

.bmd-form-group .form-control,
.bmd-form-group input::placeholder,
.bmd-form-group label {
    line-height: 1.1
}

.bmd-form-group label {
    color: #aaa
}

.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group .switch label,
.bmd-form-group label.checkbox-inline,
.bmd-form-group label.radio-inline {
    line-height: 1.5
}

.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group label {
    font-size: .875rem
}

.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
    top: .6125rem
}

.bmd-form-group .is-filled .bmd-label-floating,
.bmd-form-group .is-focused .bmd-label-floating {
    top: -1rem;
    left: 0;
    font-size: .6875rem
}

.bmd-form-group .bmd-label-static {
    top: .35rem;
    left: 0;
    font-size: .875rem
}

.bmd-form-group .bmd-help {
    margin-top: 0;
    font-size: .75rem
}

.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, .9375rem .9375rem
}

.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right .46875rem
}

.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, .9375rem .9375rem
}

.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input::-webkit-input-placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input::-moz-placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input:-ms-input-placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input::-ms-input-placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm input::placeholder,
.bmd-form-group.bmd-form-group-sm label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-sm label {
    color: #aaa
}

.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm .switch label,
.bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.bmd-form-group.bmd-form-group-sm label.radio-inline {
    line-height: 1.5
}

.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label {
    font-size: .875rem
}

.bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
    top: .175rem
}

.bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating {
    top: -1.25rem;
    left: 0;
    font-size: .6875rem
}

.bmd-form-group.bmd-form-group-sm .bmd-label-static {
    top: .1rem;
    left: 0;
    font-size: .875rem
}

.bmd-form-group.bmd-form-group-sm .bmd-help {
    margin-top: 0;
    font-size: .65625rem
}

.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, .6875rem .6875rem
}

.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right .34375rem
}

.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, .6875rem .6875rem
}

.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input::-webkit-input-placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input::-moz-placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input:-ms-input-placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input::-ms-input-placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg input::placeholder,
.bmd-form-group.bmd-form-group-lg label {
    line-height: 1.1
}

.bmd-form-group.bmd-form-group-lg label {
    color: #aaa
}

.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg .switch label,
.bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.bmd-form-group.bmd-form-group-lg label.radio-inline {
    line-height: 1.5
}

.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label {
    font-size: .875rem
}

.bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
    top: .7375rem
}

.bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating {
    top: -1rem;
    left: 0;
    font-size: .6875rem
}

.bmd-form-group.bmd-form-group-lg .bmd-label-static {
    top: .35rem;
    left: 0;
    font-size: .875rem
}

.bmd-form-group.bmd-form-group-lg .bmd-help {
    margin-top: 0;
    font-size: .9375rem
}

.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem
}

.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right .59375rem
}

.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning {
    background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem
}

.form-control,
input::-webkit-input-placeholder,
label {
    line-height: 1.1
}

.form-control,
input::-moz-placeholder,
label {
    line-height: 1.1
}

.form-control,
input:-ms-input-placeholder,
label {
    line-height: 1.1
}

.form-control,
input::-ms-input-placeholder,
label {
    line-height: 1.1
}

.form-control,
input::placeholder,
label {
    line-height: 1.1
}

.checkbox label,
.radio label,
.switch label,
label.checkbox-inline,
label.radio-inline {
    line-height: 1.5
}

.checkbox label,
.radio label,
label {
    font-size: .875rem
}

.bmd-label-floating,
.bmd-label-placeholder {
    top: .6125rem
}

.is-filled .bmd-label-floating,
.is-focused .bmd-label-floating {
    top: -1rem;
    left: 0;
    font-size: .6875rem
}

.bmd-label-static {
    top: .35rem;
    left: 0;
    font-size: .875rem
}

.bmd-help {
    margin-top: 0;
    font-size: .75rem
}

.form-control.form-control-danger,
.form-control.form-control-success,
.form-control.form-control-warning {
    background-size: 0 100%, 100% 100%, .9375rem .9375rem
}

.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.form-control.form-control-success,
.form-control.form-control-success:focus,
.form-control.form-control-warning,
.form-control.form-control-warning:focus {
    padding-right: 0;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right .46875rem
}

.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.form-control.form-control-success:focus,
.form-control.form-control-warning:focus {
    background-size: 100% 100%, 100% 100%, .9375rem .9375rem
}

select,
select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none
}

.content-img {
    width: 20%
}

@media(max-width: 576px) {
    .inp-icon {
        margin-top: 3rem !important
    }

    .termcondition {
        float: left !important
    }

    .rights-reserved {
        bottom: 10px
    }
}

@media(min-width: 576px) {
    .form-inline .input-group {
        display: inline-flex;
        align-items: center
    }
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
    opacity: 0
}

.has-success .form-control-feedback {
    color: #4caf50;
    opacity: 1
}

.has-danger .form-control-feedback {
    color: #f44336;
    opacity: 1
}

.form-group {
    position: relative;
    margin: 8px 0 0
}

textarea {
    overflow: auto;
    resize: vertical;
    height: auto !important;
    resize: none;
    line-height: 1.42857143 !important
}

.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.form-newsletter .form-group,
.form-newsletter .input-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
    padding-top: 5px
}

.form-newsletter .btn {
    float: left;
    width: 20%;
    margin: 9px 0 0
}

.form-file-upload .input-group-btn:last-child>.btn-round {
    border-radius: 30px
}

.form-file-upload .input-group-btn .btn {
    margin: 0
}

.form-file-upload .input-group {
    width: 100%
}

.input-group .input-group-btn {
    padding: 0 12px
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-color: transparent;
    cursor: not-allowed;
    border-bottom: 1px dotted #d2d2d2;
    background-repeat: no-repeat
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #9c27b0
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #9c27b0
}

.mat-form-field {
    display: block !important;
    width: 100%
}

.has-success .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.has-success .mat-form-field-empty.mat-form-field-label {
    color: #4caf50
}

.has-success.mat-focused .mat-input-underline .mat-input-ripple {
    background-color: #4caf50
}

.has-danger .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.has-danger .mat-form-field-empty.mat-form-field-label {
    color: #f44336
}

.has-danger.mat-focused .mat-input-underline .mat-input-ripple {
    background-color: #f44336
}

.ng-invalid.mat-focused .mat-form-field-label {
    color: #f44336
}

.mat-form-field.has-success .mat-input-underline {
    background-color: #4caf50;
    height: 2px
}

.mat-form-field.has-success .mat-input-underline .mat-input-ripple {
    background-color: #4caf50
}

.mat-form-field.has-success .mat-form-field-label {
    color: #4caf50
}

.card .card-body .col-form-label,
.card .card-body .label-on-right {
    padding: 16px 5px 0 0;
    text-align: right
}

.card .card-body .label-on-right {
    text-align: left
}

.card .label-on-right code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px
}

.card-wizard .input-group .form-group {
    width: 80%
}

.input-group .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    background-color: transparent;
    border-color: transparent
}

.form-control:focus {
    box-shadow: none
}

.list-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: .5rem 0
}

.list-group.bmd-list-group-sm {
    padding: .25rem 0
}

.list-group.bmd-list-group-sm .list-group-item {
    padding: .5rem 1rem
}

.bmd-list-group-col {
    display: flex;
    flex-direction: column;
    min-width: 0
}

.list-group-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem;
    line-height: 1
}

.list-group-item .list-group-item-text {
    min-width: 0;
    max-height: 2.188rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .875rem;
    color: #555
}

.tab-space {
    padding: 20px 0 50px
}

.card .tab-content.tab-space {
    padding: 20px 0 9px
}

.info {
    max-width: 360px;
    margin: 0 auto;
    padding: 70px 0 30px
}

.info .icon {
    color: #999
}

.info .icon>i {
    font-size: 3.85rem
}

.info .info-title {
    color: #3c4858;
    margin: 1.75rem 0 .875rem
}

.info p {
    color: #999
}

.info-horizontal .icon {
    float: left;
    margin-top: 24px;
    margin-right: 10px
}

.info-horizontal .icon>i {
    font-size: 2.25rem
}

.info-horizontal .description {
    overflow: hidden
}

.card-icon.icon-primary {
    color: #9c27b0
}

.card-icon.icon-info {
    color: #00bcd4
}

.card-icon.icon-success {
    color: #4caf50
}

.card-icon.icon-warning {
    color: #ff9800
}

.card-icon.icon-danger {
    color: #f44336
}

.card-icon.icon-rose {
    color: #e91e63
}

.card-icon.icon-white {
    color: #fff
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.h1,
.h2,
.h3,
.h4,
body,
h1,
h2,
h3,
h4,
h5,
h6 {

    font-weight: 300;
    line-height: 1.5em
}

.h1,
h1 {
    font-size: 3.3125rem;
    line-height: 1.15em
}

.h2,
h2 {
    font-size: 2.25rem
}

.h3,
h3 {
    font-size: 1.5625rem;
    line-height: 1.4em;
    margin: 20px 0 10px
}

.h4,
h4 {
    font-size: 1.125rem;
    line-height: 1.4em;
    font-weight: 300
}

.h5,
h5 {
    font-size: 1.0625rem;
    line-height: 1.4em;
    margin-bottom: 15px
}

.h6,
h6 {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 500
}

.card-title,
.card-title a,
.footer-big h4,
.footer-big h4 a,
.footer-big h5,
.footer-big h5 a,
.footer-brand,
.footer-brand a,
.info-title,
.info-title a,
.media .media-heading,
.media .media-heading a,
.title,
.title a {
    color: #3c4858;
    text-decoration: none
}

.card-blog .card-title {
    font-weight: 700
}

h2.title {
    margin-bottom: 2.142rem
}

.card-description,
.description,
.footer-big p {
    color: #999
}

.text-warning {
    color: #ff9800 !important
}

.text-primary {
    color: #9c27b0 !important
}

.text-danger {
    color: #f44336 !important
}

.text-success {
    color: #4caf50 !important
}

.text-info {
    color: #00bcd4 !important
}

.text-rose {
    color: #e91e63 !important
}

.text-gray {
    color: #999 !important
}

.nav-tabs {
    border: 0;
    border-radius: 3px;
    padding: 0 15px
}

.nav-tabs .nav-item .nav-link {
    margin: 0;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px 15px;
    background-color: transparent;
    transition: .3s background-color 0
}

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:focus,
.nav-tabs .nav-item .nav-link:hover {
    border: 0 !important;
    color: #fff !important;
    font-weight: 500
}

.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
    color: rgba(255, 255, 255, .5)
}

.nav-tabs .nav-item .material-icons {
    margin: -1px 5px 0 0
}

.nav-tabs .nav-item .nav-link.active {
    background-color: rgba(255, 255, 255, .2);
    transition: .3s background-color .2s
}

.nav-tabs .nav-link {
    border-bottom: .214rem solid transparent;
    color: #555
}

.nav-tabs .nav-link.active {
    color: #333;
    border-color: #9c27b0
}

.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
    border-color: #9c27b0
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:focus,
.nav-tabs .nav-link.disabled:hover {
    color: #999
}

.mat-tooltip {
    text-align: center
}

.popover,
.tooltip-inner {
    line-height: 1.5em;
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
    color: #555
}

.form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, .26)
}

.form-group.is-focused .togglebutton label:focus,
.form-group.is-focused .togglebutton label:hover {
    color: rgba(0, 0, 0, .54)
}

fieldset[disabled] .form-group.is-focused .togglebutton label {
    color: rgba(0, 0, 0, .26)
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 260px;
    background: #fff;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.form-check,
label {
    font-size: 12px;
    line-height: 1.42857;
    font-weight: 400
}

.main-raised {
    margin: -60px 30px 0;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.animation-transition-general,
.carousel .carousel-indicators li,
.lock-page .card-profile,
.login-page .card-login,
.sidebar .logo a.logo-mini,
.sidebar .logo a.logo-normal,
.sidebar .user .photo,
.sidebar .user .user-info>a>span {
    transition: all .3s linear
}

.animation-transition-slow {
    transition: all 370ms linear
}

.animation-transition-fast,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role=remove],
.card-collapse .card-header a i,
.mat-button,
.mat-button-focus-overlay,
.mat-calendar-body-cell-content,
.mat-icon-button,
.navbar {
    transition: all 150ms ease 0
}

.signup-page .card-signup form .form-check {
    padding-left: 20px
}

.signup-page .card-signup form .form-check .form-check-label {
    padding-left: 35px
}

.section-signup .card-signup .card-header {
    width: auto
}

.section-signup .card-signup .card-body .input-group {
    padding-bottom: 7px;
    margin: 27px 0 0
}

.offline-doc .page-header,
.offline-free-doc .page-header {
    height: 100vh !important
}

.offline-doc .page-header:after,
.offline-free-doc .page-header:after {
    background: rgba(0, 0, 0, .5) !important
}

.offline-doc .footer,
.offline-free-doc .footer {
    position: absolute;
    width: 100%;
    background: 0;
    bottom: 0;
    color: #fff;
    z-index: 1
}

.offline-doc .footer .copyright a,
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .footer .copyright a,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
    color: #fff
}

/* @-webkit-keyframes hinge {
    0 {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,60% {
        -webkit-transform: rotate3d(0,0,1,80deg);
        transform: rotate3d(0,0,1,80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,80% {
        -webkit-transform: rotate3d(0,0,1,60deg);
        transform: rotate3d(0,0,1,60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0,700px,0);
        transform: translate3d(0,700px,0);
        opacity: 0
    }
}

@keyframes hinge {
    0 {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,60% {
        -webkit-transform: rotate3d(0,0,1,80deg);
        transform: rotate3d(0,0,1,80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,80% {
        -webkit-transform: rotate3d(0,0,1,60deg);
        transform: rotate3d(0,0,1,60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0,700px,0);
        transform: translate3d(0,700px,0);
        opacity: 0
    }
} */

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge
}

.tim-row {
    margin-bottom: 20px;
    padding-top: 50px
}

.tim-white-buttons {
    background-color: #777
}

.title {
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px
}

#map {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 70px);
    margin-top: 70px
}

.tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
    width: 100%
}

.tim-typo .tim-note {
    bottom: 5px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px
}

.tim-row h3 {
    margin-top: 0
}

#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
    margin-bottom: 0
}

.switch {
    margin-right: 20px
}

#navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2
}

.space {
    height: 130px;
    display: block
}

.space-110 {
    height: 110px;
    display: block
}

.space-50 {
    height: 50px;
    display: block
}

.space-70 {
    height: 70px;
    display: block
}

.navigation-example .img-src {
    background-attachment: scroll
}

.navigation-example {
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    min-height: 740px
}

#notifications {
    background-color: #fff;
    display: block;
    width: 100%;
    position: relative
}

#notifications .alert-danger {
    margin-bottom: 0
}

.tim-note {
    text-transform: capitalize
}

#buttons .btn {
    margin: 0 0 15px
}

.space-100 {
    height: 100px;
    display: block;
    width: 100%
}

.parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute
}

.parallax .parallax-image img {
    width: 100%
}

@media(max-width: 768px) {
    .parallax .parallax-image {
        width: 100%;
        height: 640px;
        overflow: hidden
    }

    .parallax .parallax-image img {
        height: 100%;
        width: auto
    }
    .top-banner-text {
        font-size: 28px !important;      
        line-height: 36px !important;
        margin-top: 20px !important;
 
    }
    .partner-logo
    {
        width: 100px !important;
        height: 44px !important;
    }
    .top-banner-text-sm
    {
        font-size: 16px !important;
    }
    .credit-heading-black,.credit-heading-bg
    {
        font-size: 24px !important;      
    }
    .text-font-list ,.font-medium-text
    {
        font-size: 16px !important;      
    }
    .txt-dark-bold ,.txt-green-dark,.font-large-text
    {
        font-size: 20px !important;      
    }
    .prefix-number
    {
        bottom: 120px;
        }
        .txt-font-bigger
{
    
    font-size: 24px !important;
    
}
.txt-font-sm
{
    
    font-size: 14px !important;
    
}
.txt-credit-section-heading
{
    font-size: 20px !important;
}
.credt-icon-top
{
    width: 48px !important;
    height: 48px !important;
}
.txt-font-medium
{
    
    font-size: 14px !important;
    
}
.txt-mobPartnet-heading
{
    font-size: 20px !important;
    font-weight: bold;
}

}
.txt-mobPartnet-heading
{
    font-size: 32px;
    font-weight: bold;
    font-family: 'Inter' !important;
}
.txt-font-medium
{
    
    font-size: 20px;
    font-weight: 500;
    
}
.txt-font-sm
{
    
    font-size: 16px;
    
}
.credt-icon-top
{
    width: 64px;
    height: 64px;
}
.txt-credit-section-heading
{
    font-size: 32px;
    font-weight: 600;
}
.txt-font-bigger
{
    color: #0a243f;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}
.separator {
    content: "Separator";
    color: #fff;
    display: block;
    width: 100%;
    padding: 20px
}

.separator-line {
    background-color: #eee;
    height: 1px;
    width: 100%;
    display: block
}

.separator.separator-gray {
    background-color: #eee
}

.social-buttons-demo .btn {
    margin: 10px 5px 7px 1px
}

.img-container {
    width: 100%;
    overflow: hidden
}

.img-container img {
    width: 100%
}

.section-black {
    background-color: #333
}

.animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out
}

.sharing-area {
    margin-top: 80px
}

.sharing-area .btn {
    margin: 15px 4px 0;
    color: #fff
}

.sharing-area .btn i {
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px
}

#navbar {
    margin-bottom: -20px
}

.sharing-area .btn-twitter {
    background-color: #55acee
}

.sharing-area .btn-facebook {
    background-color: #3b5998
}

.sharing-area .btn-google-plus {
    background-color: #dd4b39
}

.sharing-area .btn-github {
    background-color: #333
}

#navbar .navbar {
    border-radius: 0
}

@media(max-width: 830px) {
    .main-raised {
        margin-left: 10px;
        margin-right: 10px
    }
}

.loader {
    margin: 0 auto;
    width: 60px;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    z-index: 1;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    text-align: center;
    top: 50%
}

.loader:before {
    content: "";
    display: block;
    padding-top: 100%
}

.circular {
    -webkit-animation: 2s linear infinite rotate;
    animation: 2s linear infinite rotate;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: 1.5s ease-in-out infinite dash, 2s ease-in-out infinite color;
    animation: 1.5s ease-in-out infinite dash, 2s ease-in-out infinite color;
    stroke-linecap: round
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

/* @-webkit-keyframes dash {
    0 {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35px
    }

    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124px
    }
}

@keyframes dash {
    0 {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35px
    }

    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124px
    }
}

@-webkit-keyframes color {
    0,100% {
        stroke: #9c27b0
    }

    50% {
        stroke: #ff9800
    }
}

@keyframes color {
    0,100% {
        stroke: #9c27b0
    }

    50% {
        stroke: #ff9800
    }
} */

a {
    color: #9c27b0
}

a:focus,
a:hover {
    color: #89229b;
    text-decoration: none
}

a .material-icons {
    vertical-align: middle
}

.btn-group.bootstrap-select.open .caret,
.dropdown.open .caret,
.dropup.open .caret,
a[data-toggle=collapse][aria-expanded=true] .caret {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role=remove],
.caret,
.sidebar a {
    transition: all 150ms ease-in
}

.disabled,
[disabled] {
    pointer-events: none;
    cursor: not-allowed
}

.wrapper:after {
    display: table;
    clear: both;
    content: " "
}

.wrapper.wrapper-full-page {
    height: auto;
    min-height: 100vh
}

.full-page:after,
.full-page:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2
}

.full-page:before {
    background-color: rgba(0, 0, 0, .5)
}

.full-page[filter-color=primary]:after,
.full-page[filter-color=purple]:after {
    background: rgba(225, 190, 231, .56);
    background: linear-gradient(60deg, rgba(225, 190, 231, .56), rgba(186, 104, 200, .95))
}

.full-page[filter-color=primary].lock-page .form-group .form-control,
.full-page[filter-color=purple].lock-page .form-group .form-control {
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[filter-color=blue]:after,
.full-page[filter-color=info]:after {
    background: rgba(178, 235, 242, .56);
    background: linear-gradient(60deg, rgba(178, 235, 242, .56), rgba(77, 208, 225, .95))
}

.full-page[filter-color=blue].lock-page .form-group .form-control,
.full-page[filter-color=info].lock-page .form-group .form-control {
    background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[filter-color=green]:after,
.full-page[filter-color=success]:after {
    background: rgba(165, 214, 167, .56);
    background: linear-gradient(60deg, rgba(165, 214, 167, .56), rgba(102, 187, 106, .95))
}

.full-page[filter-color=green].lock-page .form-group .form-control,
.full-page[filter-color=success].lock-page .form-group .form-control {
    background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[filter-color=orange]:after,
.full-page[filter-color=warning]:after {
    background: rgba(255, 224, 178, .56);
    background: linear-gradient(60deg, rgba(255, 224, 178, .56), rgba(255, 183, 77, .95))
}

.full-page[filter-color=orange].lock-page .form-group .form-control,
.full-page[filter-color=warning].lock-page .form-group .form-control {
    background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[filter-color=danger]:after,
.full-page[filter-color=red]:after {
    background: rgba(239, 154, 154, .56);
    background: linear-gradient(60deg, rgba(239, 154, 154, .56), rgba(239, 83, 80, .95))
}

.full-page[filter-color=danger].lock-page .form-group .form-control,
.full-page[filter-color=red].lock-page .form-group .form-control {
    background-image: linear-gradient(#f44336, #f44336), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[filter-color=rose]:after {
    background: rgba(248, 187, 208, .56);
    background: linear-gradient(60deg, rgba(248, 187, 208, .56), rgba(240, 98, 146, .95))
}

.full-page[filter-color=rose].lock-page .form-group .form-control {
    background-image: linear-gradient(#e91e63, #e91e63), linear-gradient(#d2d2d2, #d2d2d2)
}

.full-page[data-image]:after {
    opacity: .8
}

.full-page>.content,
.full-page>.footer {
    position: relative;
    z-index: 4
}

.full-page>.content {
    min-height: calc(100vh - 80px)
}

.full-page .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center
}

.full-page .footer,
.full-page .footer .copyright a,
.full-page .footer nav>ul a:not(.btn) {
    color: #fff
}

.clear-filter:before {
    display: none
}

.lock-page>.content,
.login-page>.content {
    padding-top: 18vh
}

.lock-page .page-header,
.login-page .page-header {
    min-height: 100vh;
    display: inherit
}

.lock-page .page-header .container,
.login-page .page-header .container,
.login-page {
    padding-top: 2vh !important
}

.pt-11vh {
    padding-top: 11vh !important
}

.login-page .card-login {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.login-page .card-login .card-header {
    margin-top: -40px;
    margin-bottom: 20px
}

.login-page .card-login .card-body {
    padding: 0 30px 0 10px
}

.login-page .card-login .card-body .input-group .input-group-text {
    padding: 15px 15px 0
}

.login-page .card-login .card-body .input-group .form-control {
    padding-bottom: 10px;
    margin: 17px 0 0
}

.login-page .card-login .social-line .btn {
    margin-left: 5px;
    margin-right: 5px
}

.login-page .card-login.card-hidden {
    opacity: 0;
    -webkit-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0)
}

.lock-page .card-profile {
    width: 240px;
    margin: 60px auto 0;
    color: #fff;
    left: 0;
    right: 0;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.lock-page .card-profile.card-hidden {
    opacity: 0;
    -webkit-transform: translate3d(0, -60px, 0);
    transform: translate3d(0, -60px, 0)
}

.login-page .footer .container {
    padding: 0
}

.login-page .footer .copyright,
.login-page .footer a {
    color: #fff
}

.field-validation-error {
    color: red;
    margin-left: 20px
}

.sell-box {
    background-color: #fff;
    padding: 30px;
    width: 100%;
    border-radius: 10px
}

.div-center h3 {
    color: #002e5d;
    margin: 0 !important;
    font-size: 22px !important;
    font-weight: 600
}

.content hr {
    margin: 10px 0 0 0 !important
}

.forgotpass h4 {
    color: #36a8fe !important;
    font-weight: 600;
    font-size: 16px
}

.forgot-sign-in {
    background-color: #0091fe !important;
    color: #fff !important
}

.forgotpass {
    height: 320px !important
}

.forgotpass span {
    font-size: 12px
}

.mb-5 {
    margin-bottom: 3rem
}

.sell-box h3 {
    font-size: 15px;
    font-weight: 800
}

#accordion .panel,
#accordion .panel-heading,
#accordion panel-title,
#accordion .panel-body {
    border: 0
}

#accordion .panel {
    box-shadow: none
}

#accordion .panel .panel-heading {
    border-radius: 0
}

#accordion .panel .panel-heading h5 {
    color: #8c9297;
    text-decoration: none
}

#accordion .panel .panel-heading h5 span {
    float: right;
    width: 50px
}

#accordion .panel .panel-heading h5 span:after {
    content: "-";
    color: #1b5062;
    font-size: 20px
}

#accordion .panel .panel-heading h5.collapsed span:after {
    content: "+"
}

#accordion .panel-heading {
    background: #f4faff
}

#accordion .panel {
    margin-top: 15px !important;
    margin-bottom: 15px !important
}

#accordion h1 {
    margin-top: 40px;
    font-weight: 800;
    margin-bottom: 20px;
    color: #002e5d;
    font-size: 40px
}

.services-box {
    height: 235px;
    padding: 20px;
    width: 100%;
    border-radius: 10px
}

.services-box h3 {
    font-size: 18px;
    font-weight: 600
}

.palel-primary {
    border-color: #bce8f1
}

.panel-primary>.panel-body {
    padding-top: 10px;
    background-color: #002e5d;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px
}

.panel-body label {
    font-size: 10px
}

.signup-seller:focus {
    background-color: #bce8f1 !important
}

.signup-seller-btn,
.signup-seller-btn:hover,
.signup-seller-btn:active,
.signup-seller-btn:focus {
    width: 100%;
    background-color: #0354a3 !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    top: 15px;
    outline: unset !important
}

.lblTerms {
    margin-top: 15px
}

.supplier {
    padding-top: 50px
}

.supplier h1 {
    font-size: 32px;
    font-weight: 800;
    color: #002e5d
}

.register-here {
    margin-top: 5px !important;
    margin-bottom: 15px !important
}

.becom-supplier {
    margin-top: 15px !important;
    margin-bottom: 5px !important
}

.panel.panel-primary {
    margin-top: 40px;
    border: none !important
}

.become-MOB-seller {
    margin-top: 15px
}

.hr-sa {
    border-top: 1px solid #7f96ae
}

.panel-primary>.panel-heading {
    border-bottom: unset !important;
    padding-bottom: 0;
    padding-top: 20px
}

.supplier span {
    color: #00de9d;
    font-weight: 600;
    font-size: 15px
}

.login-form {
    background: #00de9d;
    padding-bottom: 30px
}

.back {
    width: 100%
}

.div-center hr {
    margin: 15px 0
}

.div-center {
    width: 100%;
    height: 340px;
    background-color: #fff;
    margin: auto;
    border-radius: 9px;
    overflow: auto;
    padding: 0;
    display: table;
    margin-bottom: 30px
}

.content .form-control {
    height: 40px !important
}

div.content {
    display: table-cell;
    vertical-align: middle
}

.sign-in:hover,
.sign-in:active,
.sign-in:focus {
    background-color: #024383 !important;
    color: #e8e8e8 !important;
    border: none !important;
    box-shadow: unset
}

.login-form .sign-in {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 12px
}

.sign-in,
.sign-in:hover {
    background-color: #002e5d;
    color: #e8e8e8 !important
}

.sign-in-otp,
.sign-in-otp:hover {
    color: #002e5d;
    background-color: #e8e8e8
}

.d-flex {
    display: flex
}

.justify-content-md-between {
    justify-content: space-between
}

.text-blue {
    color: #3cabff;
    font-size: 12px
}

.header-section .btn:hover,
.header-section .btn:active,
.header-section .btn:focus {
    background-color: #002e5d;
    color: #e8e8e8 !important;
    border: none !important
}

.footer-section {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #032039
}

.footer-section ul {
    list-style: none;
    padding-left: 0
}

.footer-section li a:hover {
    text-decoration: none !important
}

.footer-section li,
.footer-section li a {
    font-size: 12px;
    font-weight: 600;
    padding: 5px;
    padding-left: 0;
    color: #fff
}

.footer-section h5 {
    color: #88a3bc;
    font-weight: 600
}

.social-media li {
    padding-right: 16px
}

.terms-condition span a:hover,
.terms-condition span a:focus,
.terms-condition span a:visited {
    text-decoration: none !important
}

.terms-condition span,
.terms-condition span a {
    font-size: 12px;
    font-weight: 500;
    color: #fff
}

.pt-3 {
    padding-top: 1.5rem
}

.pb-3 {
    padding-bottom: 1.5rem
}

.qrcode {
    width: 120px
}

.download-app a {
    padding: 10px
}

.header-section {
    background-color: #00de9d;
    padding-top: 2rem;
    padding-bottom: 3rem
}

.btn-blue {
    background: #002e5d;
    color: #fff
}

.btn-lgtblue,
.btn-lgtblue:hover,
.btn-lgtblue:focus,
.btn-lgtblue:active {
    background: #3cabff;
    color: #fff !important;
    border: 0
}

.form-group1 {
    margin-bottom: 10px
}

.inp-icon {
    margin-top: 7rem
}

.termcondition {
    float: right
}

.contact-us img {
    width: 25px
}

.social-media img {
    width: 25px
}

.terms-condition {
    background-color: #032039;
    color: #fff
}

.home-registration .modal-header {
    border-bottom: none !important;
    padding: 25px 25px 0 15px !important
}

.home-registration .modal-body {
    padding: 0 25px 25px 25px !important
}

.home-registration form button {
    width: 100%
}

.home-registration h3 {
    color: #3cabff
}

.home-registration .form-control {
    height: 40px !important
}

.home-registration .text-area {
    height: 80px !important
}

.home-registration .text-area {
    height: 75px !important
}

.header-section a:hover {
    text-decoration: none
}

.header-section .btn:hover,
.header-section .btn:active,
.header-section .btn:focus {
    padding: 10px 10px 8px 10px;
    font-size: 11px;
    border: 1px solid #002e5d !important
}

.header-section button {
    padding: 10px 10px 8px 10px;
    font-size: 11px;
    border: 1px solid #002e5d
}

.sign-in,
.sign-in:hover {
    border: 1px solid #002e5d !important
}

.content .btn {
    line-height: 1 !important;
    font-size: 12px;
    padding: 10px
}

.header-section a {
    color: #002e5d !important;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600
}

.login-form label {
    color: #242424;
    margin-bottom: 3px !important
}

.services-box p {
    font-size: 13px;
    color: #525454
}

.panel-primary>.panel-heading {
    background: #002e5d !important;
    font-size: 18px;
    font-weight: 600;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    text-align: left !important
}

.panel-body {
    padding: 15px 18px 0 18px !important;
    font-size: 13px;
    color: #525454
}

.sell-on-MOB h1 {
    font-size: 34px;
    font-weight: 800;
    color: #002e5d;
    margin-bottom: 45px;
    margin-top: 45px
}

.sell-box p {
    font-size: 13px;
    color: #525454
}

.sell-on-MOB {
    background-color: #a5d8ff;
    padding-bottom: 40px
}

.accordion h1 {
    font-size: 34px;
    font-weight: 800;
    color: #002e5d;
    margin-bottom: 45px;
    margin-top: 45px
}

.accordion {
    margin-bottom: 40px
}

.inp-icon {
    background-color: #a5d8ff !important;
    /* background: url(asset/dist/img/feather.png)no-repeat 100%; */
    border: 1px solid #e0dfdd;
    padding: 10px 10px;
    border-radius: 7px;
    width: 100%;
    cursor: pointer;
    background-size: 30px;
    background-position: right center
}

.inp-icon {
    margin-top: 103px
}

label.error {
    color: #f00 !important
}

.forgot-sign-in {
    width: 100% !important
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-offset: unset;
    border: 0
}

.cliick-to-login {
    background: transparent;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    font-size: 15px
}

.cliick-to-login:hover {
    background: transparent;
    text-decoration: none
}

.login-card-style {
    padding: 10px;
    border-radius: 9px
}

.password-style {
    padding-top: 15px
}

.sign-in-style {
    width: 100% !important;
    outline: unset !important;
    margin-top: 15px
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: none !important;
    outline-offset: unset
}

.forgot-style {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 12px
}

.submit-style-inner {
    width: 100%
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important
}

.click-to-login-style {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px
}

.not-robot .input-container {
    margin-top: 30px;
    background-color: #fff;
    height: 60px;
    padding: 10px;
    width: 100%;
    background-image: none !important
}

.not-robot .input-container input {
    margin-top: 10px
}

.not-robot img {
    width: 27px
}

.not-robot span,
.not-robot a {
    color: #9b9b9b
}

.not-robot .recaptcha {
    font-size: 11px;
    display: block
}

div.item {
    float: right;
    vertical-align: top;
    display: inline-block;
    text-align: center
}

.captcha-error {
    font-size: 11px !important
}

.header-top-section {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.12)
}

.register-style-btn {
    background-position: center;
    transition: background .8s
}

.register-style-btn:hover {
    background: #03488b radial-gradient(circle, transparent 1%, #03488b 1%)center/11000%;
    color: #fff
}

.register-style-btn:active {
    background-color: #4f7fae;
    background-size: 100%;
    transition: background 0
}

.register-style-btn {
    padding: 11px 40px 10px 40px;
    border-radius: 32px;
    background-color: #015fe5 !important;
    border: 1px solid #015fe5 !important;
    box-shadow: unset;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    text-transform: capitalize;
   

    text-decoration: none;
    height: 50px
}

.otp-seller-btn {
    border: 1px solid #0354a3 !important;
    color: #0354a3 !important
}

a.btn-primary.login-btn-style-top {
    border-radius: 32px;
    border: 1px solid #0a243f !important;
    box-shadow: unset;
    background-color: #fff !important;
    padding: 10px 24px 10px 24px;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #0a243f;
    text-transform: capitalize
}

a.btn-primary.login-btn-style {
    border-radius: 32px;
    border: 1px solid #0a243f !important;
    box-shadow: unset;
    background-color: #fff;
    padding: 11px 35px 10px 35px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    text-transform: capitalize;

    margin-bottom: 0;
    margin-top: 0
}

.banner-section {
    background: url(../../../assets/seller/icons/topbanner-image.webp);
    background-repeat: no-repeat !important;
    background-position: right 0px  top 100% !important;
    background-size: 45% auto, 100% 100%;
}



.your-online-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.22;
    text-align: left;
    color: #467fb6;
    margin-bottom: 10px;
    margin-top: 7px;
   
}

.free-forever-style {
    padding: 10px 18px !important;
    border-radius: 8px !important;
    background-color: #01a685 !important;
    border: 1px solid #01a685 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 18px;

    box-shadow: unset !important;
    margin-top: 164px !important
}

.text-default-mob {
    color: #2c84ea;
    font-weight: bold;

}

.text-bold {

    font-weight: bold !important;

}

.banner-toppadding {

    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 18px;
    box-shadow: unset !important;
    margin-top: 100px !important
}

.slider-bg img {
    width: 95%
}

.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.mat-button.btn.btn-primary:active,
.mat-button.btn.btn-primary:focus,
.mat-button.btn.btn-primary:hover,
.mat-raised-button.btn.btn-primary:active,
.mat-raised-button.btn.btn-primary:focus,
.mat-raised-button.btn.btn-primary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:active,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:hover {
    box-shadow: unset
}

.btn.btn-primary.free-forever-style.focus,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.mat-button.btn.btn-primary.focus,
.mat-button.btn.btn-primary:focus,
.mat-button.btn.btn-primary:hover,
.mat-raised-button.btn.btn-primary.focus,
.mat-raised-button.btn.btn-primary:focus,
.mat-raised-button.btn.btn-primary:hover,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary.focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:focus,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary:hover {
    background-color: #01a685 !important;
    border: 1px solid #01a685 !important;
    box-shadow: unset !important
}

.btn.btn-primary.free-forever-style .mat-button.btn.btn-primary,
.mat-raised-button.btn.btn-primary,
.mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary {
    box-shadow: unset !important
}

.form-control,
.is-focused .form-control {
    background-image: unset
}

.form-controll-click {
    padding-top: 10px
}

.form-controll-click .form-control {
    height: 60px;
    border-radius: 30px;
    padding: 12px 155px 10px 70px;
    font-size: 16px;
    font-weight: 600;
    background: unset !important;
    color: #fff !important;
    border: solid 1px #acacac;
}

.form-controll-click .form-control::placeholder {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #6c7c8c;
    font-size: 16px
}

.form-controll-click .form-control:-ms-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #6c7c8c;
    font-size: 16px
}

.form-controll-click .form-control::-ms-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #6c7c8c;
    font-size: 16px
}

.form-controll-click label {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: left !important;
    color: #0a243f !important;
    position: absolute;
    top: 103px;
    bottom: 0;
    left: 40px;
   
}

.btn.btn-primary.register-now-btn {
    padding: 12px 20px;
    border-radius: 50px;
    background-color: #015fe5 !important;
    border: 1px solid #015fe5 !important;
    box-shadow: unset;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #fff;
    text-transform: capitalize;
    /* position: absolute; */
    /* right: 30px;
    top: 84px; */
   
    width: 75%;
    text-align: center;
}

.btn.btn-primary.register-now-outline {
    padding: 12px 20px;
    border-radius: 50px;
    background-color: unset !important;
    border: 1px solid #0354a3 !important;
    box-shadow: unset;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #0354a3;
    text-transform: capitalize;
    position: absolute;
    right: 16px;
    top: 30px;
   
}

.btn.btn-primary.register-now-outline:hover {

    background-color: #0354a3 !important;
    color: #fff;

}

.buildings-box {
    border-radius: 16px;
    border: solid 1.5px #dedede;
    background-color: #fff;
    width: 100%;
    padding: 50px;
    padding: 30px 15px
}

.over-buildings-section {
    padding-top: 110px;
    background: #fff !important;

}

.ant-layout {
    background: #fff !important;
}

.suppliers-section {
    padding-top: 98px
}

.register-text-style h2 {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
 
}

.over-buildings-section h1 {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
    margin-bottom: 60px;
   
}

.register-text-style h6 {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #0a243f;
    text-transform: capitalize;
    line-height: 25px;
    
}

.buildings-box h4 {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    line-height: 33px;
    padding-top: 20px
}

.buildings-box p {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #0a243f;
    opacity: .8;
    margin-bottom: 0
}

.img-buildings {
    background: #eaf7eb;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    text-align: center;
    padding: 20px 0
}

.suppliers-section h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding-bottom: 25px;
    
}

.carousel-inner.testimonails-style {
    background-color: #f7f7f8;
    padding: 25px 25px 25px 25px;
    border-radius: 16px
}

.testimonails-style p {
    font-size: 24px !important;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    padding: 5px 30px 25px 20px;
   
}

.testimonails-img img {
    width: 100%
}

.carousel-control-prev-icon {
    background-image: url('../../../assets/seller/icons/next-icon-testi.svg') !important;
    width: 50px !important;
    height: 50px !important;
    opacity: .3;
    transform: rotate(180deg)
}

.carousel-control-next-icon {
    background-image: url('../../../assets/seller/icons/next-icon-testi.svg') !important;
    background-repeat: no-repeat;
    width: 50px !important;
    height: 50px !important
}

.testi-box-size-padding h5 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding-left: 26px
}

.testi-box-size-padding h6 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    padding-left: 26px
}

.carousel-control-next.testi-style {
    right: 25px !important;
    bottom: 36px !important
}

.carousel-control-prev.testi-style {
    left: unset !important;
    right: 96px !important;
    bottom: 35px !important;
    position: absolute;
}

.carousel-control-next.testi-style,
.carousel-control-prev.testi-style {
    width: unset;
    opacity: unset;
    display: flex;
    justify-content: end;
    align-items: end;
}

.register-img {
    text-align: center
}

.register-img h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    padding-top: 40px
}

.register-img p {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #0a243f;
    padding-top: 10px
}

p.register-style-text {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #0a243f;
    padding-top: 24px;

}

.number-buildings {
    font-size: 120px;
    font-weight: 600;
    text-align: left;
    color: #88cbc2
}

.register-text-style h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding-top: 20px;
    padding-bottom: 15px;
   
}

.work-section h1 {
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: #fff;
    padding-top: 40px;
   
}

.work-section {
    background-color: #467fb6;
    margin-top: 118px;
    margin-bottom: 63px;
    padding: 100px 0 0 0;
    position: relative
}

.work-section h6 {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    padding-top: 25px;
    text-transform: none;
    line-height: 45px
}

.btn.product-register-style-btn {
    border-radius: 32px !important;
    background-color: #fff !important;
    border: 1px solid #fff !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center;
    color: #0a243f !important;
    box-shadow: unset !important;
    padding: 10px 60px;
    margin-top: 35px;
    text-transform: capitalize
}

.work-section p {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    padding-top: 25px;
    margin-bottom: 0
}

.work-section h3 {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #fff;
    padding-top: 10px
}

.firrst-box-add {
    border-radius: 16px;
    background-color: #6b98c4;
    width: 100%;
    text-align: center;
    padding: 30px 30px
}

.firrst-box-add img,
.second-box-add img {
    width: 100%
}

.second-box-add {
    border-radius: 16px;
    background-color: #6b98c4;
    width: 100%;
    text-align: center;
    padding: 30px 30px
}

.margintop-section-top {
    margin-top: 50px
}

.work-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    left: 0;
    right: 0;
    height: 35px
}

.brands-listed-section h1 {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #0a243f;
   
}

.all-logo-section {
    margin-top: 45px;
    text-align: center
}

.start-selling-section {
    margin-top: 68px;
    background: url('../../../assets/seller/icons/selling-new-bg-bg.png') #f7f7f8;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
    background-position: right
}

.start-selling-section h2 {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;

    padding-top: 70px
}

.start-selling-section h4 {
    font-size: 24px;
    font-weight: 600 !important;
    text-align: left;
    color: #0a243f !important;
    padding-top: 20px
}

.btn.btn-primary.selling-btn-style {
    border-radius: 32px;
    border: solid 1px #0a243f;
    background-color: #fff;
    box-shadow: unset;
    padding: 10px 50px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    text-transform: capitalize;
    margin-top: 40px
}

.selling-car-img img {
    width: 100%
}

.register-background-img {
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding-right: 0 !important;
    display: table;
    margin-top: 115px !important
}

.new-otp-btn-style {
    padding: 12px !important
}

.supplier-support {
    margin-bottom: 80px;
    margin-top: 109px
}

.supplier-support h2 {
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    text-align: left;
    color: #0a243f
}

.supplier-support p {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f;
    margin-top: 25px
}

a.tels-number-style {
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.13;
    text-align: right;
    color: #0a243f
}

.support-style {
    padding-top: 20px
}

.call-us-style {
    font-size: 24px;
    font-weight: normal;
    text-align: left;
    color: #0a243f;
    padding-left: 10px;
    padding-right: 10px
}

.supplier-support-style {
    padding-top: 65px
}

.supplier-support-style h3 {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    color: #0a243f
}

.accordion-item.frequently {
    border-radius: 16px !important;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 16%);
    background-color: #fff !important;
    margin-bottom: 25px;
    border: unset !important
}

.accordion-button:not(.collapsed) {
    background-color: unset !important;
    box-shadow: unset !important;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding: 20px
}

.accordion-item.frequently:first-of-type {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    border: unset;
    color: #0a243f
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 16px;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding: 20px
}

.accordion-button.frequently:not(.collapsed) {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: normal;
    text-align: left;
    color: #0a243f
}

.accordion-button {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: left;
    color: #0a243f !important;
    border-radius: 16px !important;
    padding: 25px 20px !important;
    
}

.accordion-button.frequently:not(.collapsed) {
    color: #0a243f !important;
    background-color: transparent;
    box-shadow: unset
}

.accordion-button:not(.collapsed) {
    border-radius: 16px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: normal;
    text-align: left !important;
    color: #0a243f !important
}

.footer-bottom-section {
    /* background-color: #f8f8f8; */
    padding: 55px 0 10px 15px
}

.footer-bottom-box {
    padding-left: 0
}

.footer-bottom-box ul {
    padding-left: 0
}

.footer-bottom-box h3 {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0a243f;
    padding-bottom: 10px
}

.footer-social-icon-style {
    text-align: right
}

.footer-social-icon-style ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.footer-social-icon-style li {
    display: inline;
    padding: 5px
}

.follow-style {
    font-size: 16px;
    font-weight: 500;
    color: #0a243f
}

.foot-icon {
    background-color: #133b62;
    padding: 10px;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px !important
}

.footer-drop-down-style {
    position: relative
}

.input-group.footer-drop-down-style .input-group-text {
    text-align: center;
    white-space: nowrap;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-right: 0;
    padding: 10px 0 10px 10px
}

.footer-drop-down-style .form-select {
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    padding: 10px 10px 10px 10px;
    border-left: unset
}

.footer-last-section {
    background-color: #f8f8f8;
    padding: 10px 0 45px 10px
}

.footer-bottom-box li a {
    text-decoration: none;
    font-size: 16px;
    text-align: left;
    color: #0a243f;
    line-height: 30px;
    font-weight: 500
}

.footer-bottom-box ul li {
    list-style: none
}

a.number-style1 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    margin-left: 25px;
    text-decoration: none
}

a.number-style2 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    margin-left: 10px;
    text-decoration: none
}

a.number-style3 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #0a243f;
    margin-left: 24px;
    text-decoration: none
}

.border-section {
    background-color: #f8f8f8
}

.border-top-style {
    border-top: 1px solid #636363;
    margin-bottom: 13px;
    margin-top: 25px;
    opacity: .5
}

.footer-social-icon-style li a {
    list-style: none;
    text-decoration: none;
    color: #fff
}

p.company-name-style {
    opacity: .6;
    font-size: 16px;
    text-align: left;
    color: #0a243f;
    padding-top: 20px
}

.footer-bottom-box p {
    font-size: 16px;
    text-align: left;
    color: #0a243f
}

.vh-100 {
    min-height: 100vh
}

.seller-login {
    margin: 0 !important
}

.width-100 {
    width: 100% !important
}

.seller-login {
    opacity: .7;
    background-image: linear-gradient(143deg, #0354a3 25%, #74d8ad 86%);
    height: 100vh;
    position: relative
}

.col-md-6.seller-login {
    padding-left: 0;
    overflow: hidden
}

.logo-bottom-section {
    position: absolute;
    bottom: -20px;
    left: 0
}

.logo-bottom-section img {
    width: 70%
}

.header-logo-padding-size {
    padding: 30px 20px
}

.card-login-box-style {
    border-radius: 16px;
    box-shadow: 0 13px 36px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    padding: 25px;
    top: 60px;
    bottom: 0;
    width: 77%;
    margin: auto;
    display: table
}

.card-login-box-style h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    margin-top: 0;
    padding-bottom: 30px
}

label {
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: left !important;
    color: #0a243f !important
}

.card-forgotpassword-box-style {
    border-radius: 16px;
    box-shadow: 0 13px 36px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    padding: 25px;
    position: relative;
    top: 60px;
    bottom: 0;
    width: 77%;
    margin: auto;
    display: table
}

.card-forgotpassword-box-style h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    margin-top: 0;
    padding-bottom: 30px
}

.form-control.login-input-box {
    padding: 10px;
    border-radius: 8px !important;
    border: solid 1px #bac7d5 !important;
    background: #fff !important;
    height: 50px;
    box-shadow: unset !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left;
    color: #0a243f !important
}

.form-group-top-style {
    margin-top: 20px
}

.form-group .text-blue {
    margin-top: 7px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #2973f0
}

.form-group .text-blue {
    margin-top: 15px !important
}

.btn.sign-in {
    padding: 12px 10px;
    border-radius: 8px;
    background-color: #0354a3;
    border: 1px solid #0354a3 !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    margin-top: 40px;
    margin-bottom: 5px
}

.btn.sign-in-admin {
    border-radius: 8px;
    background-color: #0354a3;
    border: 1px solid #0354a3 !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    margin-top: 10px;
    margin-bottom: 10px
}

.sellar-login-style {
    margin-top: 21px !important
}

.field-icon {
    z-index: 2;
    position: absolute;
    right: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: table;
    cursor: pointer;
    font-size: 20px;
    color: #283f56
}

.form-control:focus {
    border: 1px solid #ced4da !important;
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0
}

.login-input-box:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0
}

.invalid-feedback-text-size {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #bf2600
}

.desktop-style-text-folllow {

    font-size: 16px;
    font-weight: 500;
    color: #0a243f
}

.company-name-style {

    font-size: 16px;
    font-weight: normal;
    color: #0a243f
}

.margin-toplogin-box {
    margin-top: 25px !important
}

@media only screen and (min-width: 2001px) and (max-width:2700px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1525px
    }

    .register-background-img {
        margin-top: 0 !important
    }

    .margin-toplogin-box {
        margin-top: 100px !important
    }
}

@media only screen and (min-width: 1801px) and (max-width:2000px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1324px
    }

    .margin-toplogin-box {
        margin-top: 100px !important
    }

    .banner-section {
        background-position: right 0px top 100% !important;
        background-size: 30% auto, 100% 100% !important;
    }

    .free-forever-style {
        margin-top: 230px !important
    }

    .banner-section h1 {
        font-size: 42px;
        font-weight: bold;
        line-height: 75px;
        /* padding-top: 30px; */
        line-height: 1.5;
    }

    .slider-bg img {
        width: 100%
    }

    .form-controll-click {
        padding-top: 10px
    }

    /* .btn.btn-primary.register-now-btn {
        top: 84px
    } */

    .form-controll-click label {
        top: 103px
    }

    .over-buildings-section {
        background-position: right 150px;
        background-size: 45%
    }

    .start-selling-section h2 {
        padding-top: 100px
    }

    p.register-style-text {
        padding-bottom: 94px
    }

    .start-selling-section {
        background-size: 106% !important
    }

    .register-background-img {
        margin-top: 6px !important
    }
}

@media only screen and (min-width: 1601px) and (max-width:1800px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1260px
    }

    .register-background-img {
        position: absolute;
        right: 0;
        background-repeat: no-repeat;
        margin: auto;
        padding-right: 0 !important;
        margin-top: 35px !important
    }

    .margin-toplogin-box {
        margin-top: 100px !important
    }
}

@media only screen and (min-width: 1517px) and (max-width:1600px) {
    .over-buildings-section {
        background-position: right 150px !important
    }

    .banner-section {
        background-size: 45% auto, 100% 100% !important;
    }

    .margin-toplogin-box {
        margin-top: 46px !important
    }
}

@media(max-width: 1199px) {
    .work-section p {
        font-size: 12px
    }

    .second-box-add,
    .firrst-box-add {
        padding: 15px 15px
    }

    .banner-section {
     
    background: url(../../../assets/seller/icons/topbanner-image.webp);
    background-repeat: no-repeat !important;
    background-position: right 0px  top 100% !important;
    background-size: 45% auto, 100% 100% !important;
    }

    a.btn-primary.register-style-btn {
        padding: 10px 20px 10px 20px
    }

    a.btn-primary.login-btn-style {
        padding: 10px 35px 10px 35px
    }

    .all-logo-section img {
        width: 100%
    }

    .banner-section h1 {
        line-height: 42px;

        font-size: 27px;
        font-weight: bold;
        text-align: left;
        /* color: #0a243f */
    }

    .your-online-text {
        font-size: 23px;
        font-weight: 600;
        line-height: 1.22;
        text-align: left;
        color: #467fb6;
        margin-bottom: 10px;
        margin-top: 7px;
      
    }

    p.register-style-text {
        padding-bottom: 0
    }
}

@media only screen and (max-width: 600px) {

    .testi-box-size-padding h5,
    .testi-box-size-padding h6 {
        padding-left: 21px !important
    }

    .btn.btn-primary,
    .mat-button.btn.btn-primary,
    .mat-raised-button.btn.btn-primary,
    .mat-raised-button.btn:not([class*=mat-elevation-z]).btn-primary {
        box-shadow: unset
    }

    .container {
        padding-right: 13px !important;
        padding-left: 12px !important;
        border-top: 0px !important;
    }

    .card-login-box-style.login-otp-style {
        right: auto;
        border-radius: 16px;
        box-shadow: 0 13px 36px 0 rgb(0 0 0 / 16%);
        background-color: #fff;
        padding: 25px;
        position: fixed !important;
        top: 145px !important;
        bottom: 0 !important;
        width: 35%;
        margin: 0 auto;
        vertical-align: middle
    }

    .banner-section {
 
        background: url(../../../assets/seller/icons/topbanner-image.webp);
        background-repeat: no-repeat !important;
        background-position: right 0px  top 100% !important;
        background-size: 45% auto, 100% 100% !important;
    }

    .register-background-img img {
        display: none
    }

    .start-selling-section {
        /* background: url(../../../assets/seller/icons/mobile-car.svg) #f7f7f8 !important; */
        background-repeat: no-repeat !important;
        margin-top: 0 !important;
        background-position: right -170px bottom !important
    }

    .slider-bg img {
        display: none
    }

    .free-forever-style {
        margin-top: 25px !important;
        font-size: 10px !important;
        font-weight: 600 !important;
        padding: 7px 15px !important;
        border-radius: 4px !important
    }

    .mobile-banner-section h1 {
        font-size: 24px !important;
        font-weight: normal !important;
        line-height: 32px
    }

    .your-online-text {
        font-size: 16px !important;
        font-weight: 600 !important;
        text-align: left;
        color: #467fb6;
    }

    .form-controll-click .form-control {
        height: 50px !important;
        border-radius: 30px;
        padding: 12px 25px 10px 60px !important;
        font-size: 16px;
        font-weight: 600;
        background: unset !important;
        color: #fff !important;
    }

    .btn.btn-primary.register-now-btn {
        position: relative !important;
        right: unset !important;
        top: unset !important;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 17px;
        padding: 15px;
        margin-bottom: 0;
    }

    .form-controll-click label {
        top: 30px !important;
        bottom: 0;
        left: 20px !important;
    }

    .form-controll-click {
        padding-top: 6px;
    }

    .form-controll-click .text-cashflow {
        font-size: 14px;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }

    .padding-size {
        padding-top: unset !important;
        padding-bottom: unset !important;
    }

    .over-buildings-section,
    .suppliers-section {
        padding-top: 40px;
    }

    .over-buildings-section h1 {
        font-size: 20px;
        font-weight: 600;
    }

    .over-buildings-section h1 {
        margin-bottom: 50px;
    }

    .mobile-view-bottom {
        padding-bottom: 40px;
    }

    .number-buildings {
        font-size: 64px !important;
        font-weight: 600 !important
    }

    .over-buildings-section {
        background-image: unset !important
    }

    .register-text-style h2 {
        font-size: 16px;
        font-weight: 600;
        padding-top: 0;
        padding-bottom: 5px;
    }

    .register-text-style h6 {
        font-size: 14px;
        font-weight: normal;
    }

    .suppliers-section h1 {
        font-size: 20px;
        font-weight: 600;
    }

    .carousel-inner.testimonails-style {
        background-color: unset !important;
        padding: 0 !important;
    }

    .testi-box-size-padding img {
        display: none;
    }

    .testi-box-size-padding {
        border-radius: 16px;
        padding: 165px 5px 100px 10px;
        background-color: #f7f7f8;
        position: relative;
        margin-top: 160px;
    }

    .carousel-control-prev.testi-style {
        left: -50px !important;
        right: 0 !important;
        position: absolute;
        margin: 0 auto;
    }

    .carousel-control-next.testi-style {
        left: 0 !important;
        right: -65px !important;
        position: absolute;
        margin: 0 auto;
    }

    .carousel-control-next.testi-style,
    .carousel-control-prev.testi-style {
        width: fit-content !important
    }

    .testimonails-img {
        position: absolute;
        top: 20px;
        bottom: 0;
        z-index: 99;
        width: 70%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .work-section {
        margin-top: 90px;
    }

    .testimonails-img img {
        width: 100%;
        border-radius: 15px;
        height: 290px;
        overflow: hidden;
        object-fit: cover;
        object-position: top;
    }

    .testimonails-style p {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 30px !important;
    }

    .testi-box-size-padding h5 {
        font-size: 14px;
        font-weight: 600;
    }

    .testi-box-size-padding h6 {
        font-size: 14px;
        font-weight: normal;
    }

    .carousel-control-next.testi-style {
        bottom: 26px !important;
    }

    .carousel-control-prev.testi-style {
        bottom: 26px !important;
    }

    .carousel-control-prev-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .carousel-control-next-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .suppliers-section h1 {
        padding-bottom: 0;
    }

    .work-section {
        padding: 50px 0 0 0 !important;
    }

    .btn.product-register-style-btn {
        font-size: 12px !important;
        font-weight: 600 !important;
        padding: 11px 35px;
    }

    .work-section h3 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 15px;
    }

    .work-section h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;

    }

    .work-section h1 {
        padding-top: 0;
        font-size: 24px;
        font-weight: 600;

    }

    .margintop-section-top {
        margin-top: 50px;
    }

    .firrst-box-add {
        margin-top: 20px;
    }

    .brands-listed-section h1 {

        font-size: 24px;
        font-weight: bold;
        text-align: left;
        margin-top: -35px;
        margin-bottom: 30px;
    }

    .start-selling-section h2 {
        padding-top: 30px;
        font-size: 24px !important;
        font-weight: 600 !important;
    }

    .all-logo-section {
        margin-top: 0;
    }

    .selling-section-top {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .start-selling-section h4 {

        font-size: 16px !important;
        font-weight: 500 !important;
        text-align: left;
        color: #0a243f !important;
    }

    .btn.btn-primary.selling-btn-style {

        font-size: 12px !important;
        font-weight: 600 !important;
        color: #0a243f !important;
        margin-top: 30px;
    }

    p.register-style-text {
        font-size: 14px !important;
        font-weight: normal;
        color: #0a243f !important;
        /* padding-bottom: 230px; */
    }

    p.register-style-text.mobile-text-style {
        font-size: 14px !important;
        font-weight: normal;
        color: #0a243f !important;
        padding-bottom: 0;
    }

    .selling-car-img img {
        display: none;
    }

    .start-selling-section h4 {
        font-size: 20px;
        font-weight: 600 !important;
        text-align: left;
        color: #0a243f !important;
        padding-top: 20px;
    }

    .supplier-support h2 {
        font-size: 24px;
        font-weight: 600;
    }

    .supplier-support p {
        font-size: 16px;
        font-weight: 500;

        margin-top: 14px;
    }

    .support-style img {
        width: 40px;
    }

    a.tels-number-style {

        font-size: 16px;
        font-weight: 500;
    }

    .call-us-style {
        font-size: 16px;
    }

    .supplier-support-style h3 {
        font-size: 20px;
        font-weight: 600;
    }

    .accordion-button {
        font-size: 14px !important;
        font-weight: 600 !important;

        color: #0a243f !important;
        line-height: 23px;
        padding: 20px 15px !important;
    }

    .accordion-button:not(.collapsed) {
        font-size: 14px !important;
        font-weight: 600 !important;

        color: #0a243f !important;
        line-height: 23px;
        padding: 20px 15px !important;
    }

    .supplier-support-style {
        padding-top: 25px;
    }

    .footer-bottom-box {
        padding-top: 0;
    }

    .footer-social-icon-style {
        text-align: left;
    }

    .footer-bottom-box h3 {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 600;
    }

    .footer-bottom-box li a {
        font-size: 14px !important;
        font-weight: normal !important;

    }

    .start-selling-section {
        margin-top: 60px;
        background: unset;
        background-color: #f7f7f8;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
    }

    .card-login-box-style {
        position: absolute !important;
        width: 98% !important;
        top: 60px !important;
        bottom: 0 !important;
        margin: 0 auto;
        padding: 25px 25px 25px 25px;
    }

    .login-bg-color-style {
        position: fixed;
        width: 100%;
    }

    .banner-section h1 {
        font-size: 24px;
        /* line-height: 25px; */
    }

    .header-top-section {
        background-color: #fff;
        padding: 24px 0px 24px 5px;
        box-shadow: 0 1px 13px 0 rgb(0 0 0 / 12%);
    }

    .register-style-btn {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .login-btn-style {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    a.btn-primary.register-style-btn {
        /* margin-right: -12px !important;
        padding: 7px 25px 7px 25px !important; */
        padding-right: 4px;
        padding-left: 4px;
        width: 140px;
    }
    a.btn.login-btn-style {
        /* margin-right: -12px !important;
        padding: 7px 25px 7px 25px !important; */
        padding-right: 4px;
        padding-left: 4px;
        width: 140px;
        height: 38px !important;
    }
    a.btn-primary.login-btn-style {
        padding: 7px 25px 7px 25px !important;
    }

    .mobile-logo-size {
        width: 90px;
        margin-left: -4px;
    }

    .row,
    .col-12 {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .work-section p {
        font-size: 10px;
        font-weight: bold;
        padding-top: 15px;
        margin-bottom: 6px;
    }

    .mobile-products-listed {
        padding: 0 20px 0 20px !important;
    }

    .supplier-support {
        margin-bottom: 80px;
        margin-top: 65px;
    }

    .footer-bottom-section {
        background-color: #f8f8f8;
        padding: 55px 10px 10px 10px;
    }

    .footer-bottom-section img {
        width: 90px;
        padding-bottom: 20px;
    }

    a.number-style1,
    a.number-style2,
    a.number-style3 {
        font-size: 14px;
        font-weight: 500;
    }

    .footer-box-1mobile {
        margin-left: -10px;
    }

    p.follows-on-top {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #0a243f;
        padding-top: 35px;
    }

    .footer-social-icon-style ul {
        text-align: center;
    }

    .company-name-style {

        font-size: 12px !important;
        font-weight: normal !important;
        text-align: center !important
    }

    .footer-last-section {
        padding: 10px 10px 10px 10px !important;
    }

    .accordion-button {
        position: relative;
        display: flex;
        align-items: flex-start !important;
    }

    .seller-login {
        background: unset;
    }

    .login-label-name label {

        font-size: 12px !important;
        font-weight: normal !important;
        color: #0a243f !important;
    }

    a.login-btn-inner-style {
        border-radius: 56px;
        box-shadow: unset !important;
        border: solid 1px #0a243f !important;
        background-color: #fff !important;

        font-size: 12px !important;
        font-weight: 500 !important;
        color: #0a243f !important;
        text-transform: capitalize;
        padding: 8px 18px;
    }

    .header-logo-padding-size {
        padding: 0;
    }

    .mobile-header-style {
        padding: 10px 10px;
    }

    .card-login-box-style h3 {
        
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .send-otp-style {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        color: #fff;
     
    }

    .or-text-style {
        font-size: 14px;
        font-weight: normal;
        color: #0a243f;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 0;
    }

    .form-control.login-input-box::-ms-input-placeholder {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #0a243f;
    }

    .form-control.login-input-box::placeholder {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #0a243f;
    }

    .form-control.mobile-text-center {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #0a243f;
    }

    .card-login-box-style h3 {

        font-size: 20px !important;
        font-weight: 600 !important;
        text-align: center;
        color: #0a243f !important;
        padding-bottom: 20px;
    }

    .forgot-password-mobile {

        font-size: 14px !important;
        font-weight: normal;
        text-align: right !important;
        color: #2973f0;
        margin-top: 20px;
        margin-bottom: 13px;
        text-decoration: none;
    }

    .form-group-top-style .enter-password-style {
        border-radius: 8px !important;
        border: solid 1px #bac7d5 !important;
        background: #fff !important;

        font-size: 14px !important;
        font-weight: 500 !important;
        text-align: center !important;
        color: #0a243f !important;
        padding: 15px 10px !important;
        text-transform: capitalize;
        box-shadow: unset;
    }

    .row.mt-3.frequently-mobile-style {
        margin-top: 0 !important;
    }

    .accordion-item.frequently {
        margin-bottom: 18px;
    }

    .free-ever-padding {
        padding-left: 8px;
        margin-top: 20px;
    }

    a.register-style-btn {
        height: unset;
    }
}

.form-group .text-blue {
    margin-top: 7px;
}

.login-form-seller {
    padding-right: 12vh;
}



button.btn.btn-white.mt-0.width-100 {
    color: #0354a3;
}

.margin-top-padding {
    margin-top: 50px;
}



.ant-col.container {
    border-top: 2px solid #dedede !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 20px !important;
}

.login-btn-style {
    background-color: #fff !important;
    border-radius: 32px;
    border: 1px solid #0a243f !important;
    box-shadow: unset;

    padding: 11px 35px 10px 35px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #0a243f;
    text-transform: capitalize;

    margin-bottom: 0;
    margin-top: 0;
}

.disabled {
    pointer-events: none !important;
}

.register-style-text {
    justify-content: left;
    display: flex;
    gap: 10px;
}

.banner-content {
    width: 517px;
    height: 225px;
    margin: 20px 0px 20px 0;
    padding: 24px 24px 24px 24px;
    border-radius: 16px;
    background-image: linear-gradient(to right, #fff 48%, rgba(255, 255, 255, 0));
}

.bannertext {
    font-size: 42px;
    color: #0a243f;
    line-height: 1.5;
    font-weight: normal !important;
    text-align: left;
}

h1.bannertext {
    font-weight: normal !important;
}

.text-cashflow {
    font-size: 24px;
    color: #0a243f;
    font-weight: 600;
    padding-bottom: 10px;
}

.text-powerdby {
    display: block;
    margin-top: 5px;
}

.brands-listed-section {
    margin-bottom: 50px;
}

.banner-section {
    padding: 80px 0px 80px;
}

.login-btn-style:hover {
    color: #0a243f !important;
}

@media (max-width: 1200px) {
    .banner-section {
        background-size: 39%;
    }
}

.product-register-style-btn:hover {
    background-color: #fff !important;
}

.mobile-banner-content {
    background-image: linear-gradient(90deg, #f7f7f8 48%, #fff0);
    border-radius: 16px;
    margin: 0px -7px;
    padding: 16px !important;

}

.menu-collapsed .ant-layout-content {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 60px !important;
}

.ant-layout-content {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 170px;
}

/* Media query for mobile view (adjust max-width as needed) */
@media (max-width: 767px) {

    /* Apply margin-left only when menu is collapsed */
    .menu-collapsed .ant-layout-content {
        margin: 8px !important;
        /* Use !important to override inline styles */
    }

    .menu-collapsed .ant-layout-content {
        transition: margin-left 0.3s ease-in-out;
        margin: 10px !important;
    }

    .ant-layout-content {
        transition: margin-left 0.3s ease-in-out;
        margin: 10px !important;
    }
}

/* Media query for tablets (adjust min-width and max-width as needed) */
@media (min-width: 768px) and (max-width: 1024px) {

    /* Apply margin-left only when menu is collapsed */
    .menu-collapsed .ant-layout-content {
        margin-left: 60px !important;
        /* Adjust margin-left value for tablets */
    }
}

.how-to-use {
    display: flex;
    align-items: center;
    justify-content: end;
}

@media (min-width: 1200px) {
    .d-xl-block {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .d-lg-block {
        display: block !important;
    }
}

@media (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }
}

.d-none {
    display: none !important;
}

.sellersideview {
    margin: -39px -40px 20px !important;
}

.banner {
    padding: 0 60px !important;
}
.web-layout .ant-layout-content
{
    margin: 0px !important;
}
.top-banner
{
    margin-top: 90px !important;
}
.top-banner-section
{
    padding-top: 24px;
}
.cashflow-button
{
    padding: 10px 20px 9px;   
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.2); 
    font-family: 'Inter' !important;
    
}
.cashflow-text
{
    color: #ffb895;
    font-size: 18px;
    font-weight: bold;
}
.top-banner-bg
{
    background-image: linear-gradient(115deg, #101010 41%, #425781 100%);
    color: #fff;

}
.text-gradient
{
    background: linear-gradient(97deg, #ff9d81 6%, #f09fa7 52%, #f6d099 103%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}
.top-banner-text
{
    font-size: 56px;
    font-weight: bold;
    line-height: 72px;
    margin-top: 40px;
    color: #fff;
    font-family: 'Inter' !important;
}
.top-banner-text-sm
{
    font-size: 24px;
  font-weight: 600;
}
.made-india-italics
{
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
}
.custom-input-register {
    /* display: flex;
    align-items: center;
    padding: 8px 8px 8px 32px;
    border-radius: 32px;
    border: solid 1px #acacac; 
    background-color: #000;
    color: #fff; */
    width: 70%;
}
.custom-input-register span {
    margin-left: 16px;
}
.custom-button-register {
    padding: 14px 24px;
    border-radius: 24px;
    background-color: #015fe5;
    color: #fff;
    margin-right: 8px;
    height: 48px;
    border: 0px !important;
    position: absolute;
    right: 30%;
}
.card-light-section
{
    border-radius: 24px;
  background-color: #f4fdea;
}
.card-light-section-common
{
    border-radius: 24px;
  background-color: #f8faf7;
}
.card-risk-section
{
    border-radius: 24px;
    background-color: #fff5e3;
    padding: 20px 24px 20px 24px;
}
.ml-16px
{
    margin-left:16px;
}
.card-easyline
{
   
    border-radius: 24px;
    background-color: #f9f7f3;
}
.card-guarenteed
{

    border-radius: 24px;
    background-color: #fff5e3;
}
.card-onboarding
{
 
    border-radius: 24px;
    background-color: #e7f9fa;
}
.card-optimise
{
  
    border-radius: 24px;
    background-color: #e3fae6;
}
.homepage-video-tab .ant-tabs-tab-active .ant-tabs-tab-btn
{
    border-radius: 16px;
    background-color: #0a243f;
    
}
.homepage-video-tab .ant-tabs-tab-active .ant-tabs-tab-btn span
{
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}
.homepage-video-tab .ant-tabs-tab-btn span
{
    font-size: 20px;
    font-weight: 500;
}
.f-small-text
{
    font-size: 14px !important; 
    font-weight: normal !important;
}
.homepage-video-tab .ant-tabs-tab-btn
{
    border-radius: 16px;
    border: solid 1.5px #dedede;
    padding: 24px 24px;
    width: 100%;
    text-align: left;

}
.custom-collapse
{
    border: 0px !important;
}
.homepage-video-tab .ant-tabs-content-holder
{
    width: 60%;
}
.homepage-video-tab .ant-tabs-nav
{
    width: 40%;
}
.img-lineofcredit
{
    /* margin: 28px 65px 0px 0px; */
  object-fit: contain;
  width: 100%;
  margin-top: 28px;
}
.img-lineofcredit1
{
    /* margin: 70px 65px 0px 75px; */
  object-fit: contain;
  width: 80%;
  margin-top: 18px;
}
.txt-enabling-partner
{
    color: #767b81;
    text-align: left;
    font-style: italic;
}
.partner-logo
{
    width: 152px;
    height: 52px;
    /* margin: 48px 4px; */
    object-fit: contain;
}
.credit-heading-bg
{
    color: #ff693c;
    font-size: 40px;
    font-weight: bold;
}
.credit-heading-black
{
    color: #0a243f;
    font-size: 40px;
    font-weight: bold;
}
.homepage-video-tab .ant-tabs-tab-active .ant-collapse-content-active
{
    background-color: #0a243f !important;
    color: #fff;
    border: 0px !important
}
.homepage-video-tab .ant-tabs-content-holder
{
    border: 0px !important;
}
.homepage-video-tab .ant-tabs-tabpane
{
padding-left: 0px !important;
}
.homepage-video-tab .ant-tabs-ink-bar
{
    display: none;
}
.homepage-video-tab .ant-collapse-item-active .ant-collapse-expand-icon{
    color: #fff;
}
.homepage-video-tab .ant-collapse-item, .homepage-video-tab .ant-collapse-content-active
{
    border: 0px !important    
}
.homepage-video-tab .ant-tabs-tab-active .ant-collapse .ant-collapse-content
{
    /* background-color: #0a243f !important; */
        background-color: transparent !important;
    border-top: 0px !important;
}
.partner-user-img
{
    position: relative;
 
    /* border-radius: 20px; */
    overflow: hidden; /* Hide overflow */
/* background-image: radial-gradient(at bottom right, transparent 67%, #f4fdea 67.5%),
        linear-gradient(135deg, #cce9e5 0%, #fff 100%); */
    /* background-size: 100% 100%,cover;
    background-repeat: no-repeat; */
    /* background: url(../../../assets/seller/icons/bg-topbanner.svg); */
    /* background-repeat: no-repeat !important; */
    /* background-position: right 0px  top 100% !important; */
    /* background-size: 100% auto, 100% 100% !important; */

}
.card-light-section.p-0 .ant-card-body,.card-light-green-section.p-0 .ant-card-body,.card-light-section-common .ant-card-body
{
    padding: 0px !important;
}
.card-padding-custom
{
    padding: 24px 34px !important;
}
.homepage-video-tab .ant-collapse
{
    background: transparent !important;
}
.text-common-green
{
    color: #55a77b;
}
.marquee-img
{
    height: 72px;
    margin: 80px 40px 0px;
}
.lineofcredit-bg-img
{
    position: relative;
 
    border-radius: 20px; /* Curved edges */
    overflow: hidden; /* Hide overflow */
/* background-image: radial-gradient(at bottom right, transparent 67%, #f4fdea 67.5%),
        linear-gradient(135deg, #cce9e5 0%, #fff 100%); */
    /* background-size: 100% 100%,cover;
    background-repeat: no-repeat; */
    background: url(../../../assets/seller/icons/bg-middlebanner.svg), #f9f7f3;
    background-repeat: no-repeat !important;
    background-position: right 0px  top 100% !important;
    background-size: 80% auto, 100% 100% !important;

}
.text-font-list
{
    font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Inter' !important;
}
.card-light-green-section
{
    border-radius: 24px;
    background-color: #f8faf7;
}
.catalogue-bg-img
{
    position: relative;
   padding-bottom: 100px;
    
    overflow: hidden; /* Hide overflow */
/* background-image: radial-gradient(at bottom right, transparent 67%, #f4fdea 67.5%),
        linear-gradient(135deg, #cce9e5 0%, #fff 100%); */
    /* background-size: 100% 100%,cover;
    background-repeat: no-repeat; */
    background: url(../../../assets/seller/icons/SKUAdd.webp), #e5f6ff;
    background-repeat: no-repeat !important;
    background-position: right 0px  top 100% !important;
    background-size: 50% auto, 100% 100% !important;

}
.rfm-marquee-container 
{
    margin-top: -50px !important;
}
.txt-green-dark
{
    color: #55a77b;
    font-size: 32px;
  font-weight: bold;
}
.txt-dark-bold
{
    color: #0a243f;
    font-size: 32px;
  font-weight: bold;
}
.ant-carousel .slick-dots-bottom
{
    bottom: -10px;
}
.ant-layout-footer
{
    background: #f8f8f8 !important;
}
.font-medium-text
{
    font-size: 16px;
    font-weight: 500;
    color: #0a243f;
}
.font-large-text
{
    font-size: 28px;
    font-weight: 500;
    color: #0a243f;
}
.overflow-text
{
    max-width: 36rem;
    text-wrap: balance;
}
.ant-modal-root .ant-modal-wrap
{
    z-index: 1032;
}
.ant-modal-root .ant-modal-mask
{
    z-index: 1031;
}
.play-button:hover
{
    background-color: unset !important;
}
.text-banner-mobile
{
    flex-grow: 1;
    margin-left: 16px;
    background: transparent;
    color: #fff;
    border: 0;
}
.text-banner-mobile:focus-visible
{
    border: 0px !important;
}
.home-collapse
{
    background-color: unset !important;
    border: 0px !important;
}
.home-collapse .ant-collapse-item
{
    margin: 24px 16px 16px;
    padding: 32px 16px;
    border-radius: 12px !important;
    border: solid 1.5px #dedede;
    background-color: #fff;
}
.home-collapse .ant-collapse-item.ant-collapse-item-active,.home-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-content-box
{
    background-color: #f9f7f3 !important;
    border-top: 0px !important;
}
.home-collapse .ant-collapse-content
{
    border-top: 0px !important; 
}
.txt-collapse-heading
{
    font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
}
.home-collapse .ant-collapse>.ant-collapse-item >.ant-collapse-header
{
padding: 0px !important
}
.cls-center-img-mob
{
    position: absolute;
    top: 50px;
    bottom: 15px;
    left: 0;
    right: 0;
}
.prefix-number
{
    left: 40px;
    position: absolute;
}
.homepage-video-tab .ant-collapse-header
{
    padding: 0px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
}
#txtMobileNo {
    border: 1px solid #ced4da  !important; 
  }
  .height-100
  {
    height: 100% !important;
  }
  .web-layout .ant-card
{
    border: 0px !important;
}
.main-common-padding
{
    padding: 80px 0px 24px
}
.ant-layout-footer
{
    padding: 16px 16px !important;
}