.btn-mob-credit
{
    display: flex !important;
    align-items: center  !important;
}

.mr-8
{
    margin-right: 8px;
}
.ml-8
{
    margin-left: 8px;
}
.font-sm
{
    font-size: 12px !important;
}
.txt-outofstock
{
    font-size: 20px;
    font-weight: 600;
    color: #0a243f;
}
.txt-outofstock-sm{
    font-size: 16px;
    font-weight: 600;
    color: #0a243f; 
}