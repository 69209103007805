.submit-btn {
  height: 46px;
  width: 40%;
  font-weight: 500;
  background-color: #0354a3;
}
.cancel-btn {
  height: 46px;
  width: 30%;
  font-weight: 500;
}
.pay-amount {
  padding: 8px 18px 18px;
  border-bottom: 1px solid #f1f1f2;
  display: flex;
  justify-content: space-between;
}
.pay-align-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.amount-key {
  font-size: 16px;
  font-weight: 500;
}

.addPayment-input{
    font-size: 16px;
    font-weight: 500;
    /* padding: 16px; */
    border-radius: 8px;
    border: solid 1px #bac7d5;
    height: 46px;

}
.pay-lebel{
display: flex;
justify-content: space-between;
}
.pay-wrapper{
padding: 18px;
display: flex;
flex-direction: column;
gap:16px;
}
.pay-date{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pay-date-picker{
  display: flex;
  flex-direction: column;
  width: 48%;

}
.pay-cal{
  border-radius: 8px;
  border: solid 1px #bac7d5;
  height: 46px;
  padding: 7px;
}
.pay-type-text{
  font-size: 16px;
  font-weight: 500;
  
}
.pay-type{
display: flex;
flex-direction: column;
gap:8px

}
.pay-notify{
  padding: 12px;
  border-radius: 12px;
  background-color: #f8e6b6;
}
.style-radio{
  font-size: 14px;
  font-weight: 500;
}
.dropdown-wrapper{
display: flex;
align-items: center;
padding: 0 16px;
border: none;

border-radius: 8px;
  border: solid 1px #bac7d5;
  height: 46px;
}
.dis-text{
  font-size: 14px;;
  color: #6c7c8c;
}
.val-text{
  font-size: 16px;
  font-weight: 500;
}
.styled-menu{
  border-bottom: 2px solid #dedede;
}
.pay-mode{
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  }
  .mode-type{
width: 48%;


  }
  .add-payment-modal{
    min-height: 90vh;
    top: 5px;

}
.pay-modal{
  height: 510px; 
  overflow:  auto
  
}
.text-icon-align{
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}
.notfication-alert
{
  padding: 16px 16px 16px 16px;
  border-radius: 8px;
  background-color: #ffebe6;
  margin: 16px;
}
.order-list-payable
{
  border-radius: 16px;
  border: solid 1.5px #dedede;
  background-color: #fff;
  margin: 16px;
  padding: 16px;
}
.bg-grey
{
  background-color: #f1f1f2;
}
.text-subtitle
{
  font-size: 14px;
  color: #6c7c8c;
}
.bg-unmapped
{
  padding: 18px 18px 18px 16px;
  border-radius: 12px;
  background-color: #f8e6b6;

}
.bg-unmapped span
{

  font-size: 14px;
  font-weight: 600;
}