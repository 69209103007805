.txt-getstarted
{
    font-size: 32px;
    font-weight: 600;
    color: #0a243f;
}
.banner-top
{
    width: 100%;
    height: 100%;
  
}
.ant-steps-item-title
{
    font-size: 14px !important;
    line-height: 1.5 !important;
}
.card-credit-section
{
    border-radius: 16px;
    background-image: linear-gradient(139deg, #f3ffd5 16%, #d1f4fa 124%);
}
.card-tips-section
{
    border-radius: 16px;
  background-color: #e7f9fa;
}
.card-content,.card-content-expect
{
    border-radius: 16px;
    background-color: #f8faf7;
}
.card-credit-section .ant-card-body,.card-content .ant-card-body,.card-content-expect .ant-card-body,
.card-planlist .ant-card-body,.card-tips-section .ant-card-body,.card-content-normal .ant-card-body,.bg-testimonial .ant-card-body,.noborder-card .ant-card-body,.video-card-content .ant-card-body 
{
padding: 0px !important;
}
.card-content-expect .ant-card-body
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}
.risk-strip
{
    padding: 20px 20px 20px 24px;
    border-radius: 16px;
    background-image: linear-gradient(100deg, #f3ffd5 25%, #d1f4fa 103%);
}
.document-strip
{
    padding: 18px 25px 18px 24px;
    border-radius: 16px;
    background-color: #55a77b;
}
.card-planlist
{
    border-radius: 16px;
  border: solid 2px #0a243f;
  /* background-image: linear-gradient(258deg, #f3ffd5 69%, #e0f9e9 -17%); */
  background-image: linear-gradient(100deg, #e0f9e9 -17%, #f3ffd5 69%);
}
.txt-numbers
{
    color: #55a77b;
    font-size: 40px;
    font-weight: 900;
}
.txt-risk-color
{
    color: #55a77b;
    
}
.ml-10
{
    margin-left: 10px;
}
.text-head-font ,.chk-text span
{
    font-size: 16px;
    font-weight: 600;
    color: #0a243f;
}
.text-subhead-font
{
    font-style: italic;
    color: #767b81;
    font-size: 14px;
    font-weight: normal;
}
.text-subtext
{
    font-style: italic;
    color: #0a243f;
    font-size: 14px;
    font-weight: normal;
}
.text-heading
{
    font-size: 20px;
    font-weight: 600;
    color: #0a243f;
}.text-heading-sm
{
    font-size: 20px ;
    font-weight: 600 !important;
    color: #0a243f !important;
}
.d-flex
{
    align-items: center;
}
.text-comission
{
    color: #0a243f;
    font-size: 24px;
  font-weight: 600;
}
.text-comission-sub
{
    color: #0a243f;
    font-size: 14px;
  font-weight: normal;
}
.text-comission-sub-bold
{
    color: #0a243f;
    font-size: 14px;
    font-weight: 600 !important;
  font-weight: normal;
}
.text-head-white
{
    font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.text-head-white-big
{
    font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.text-price-big
{
    color: #0a243f;
    font-size: 28px;
  font-weight: bold;
}
.text-price-sm
{
    color: #0a243f;
    font-size: 14px;
  font-weight: 600;
}
.text-price-save{
  font-size: 16px;
  font-weight: bold;
  color: #55a77b;
}
.text-price-offer{
  font-size: 16px;
  text-decoration: line-through;;
  color: #808e9c;
}
.gst_text
{
font-weight: 500;
background-color: #133b62;
padding: 8px 16px;
border-radius: 8px;
color: #fff;
}
.margin-top-50
{
    margin-top: 50px;
}
.mt-6
{
    margin-top: 16px;
}
.btn-common
{
    height: 56px;
    font-size: 16px;
  font-weight: 600;
}
.signup-modal-inp
{
    border-radius: 8px;
  border: solid 1px #bac7d5;
  height: 52px !important;
}
.price-text-alignments
{
  text-align: right;
}
  .submissionModalParent
  .submissionModalImageContentParent {
  height: 50%;
  justify-content: center;
  padding: 50px 0 24px 0;
}
.editAddressFooterButton {
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    padding: 0 32px;
    border-radius: 8px;
  }
 
  .submissionModalDesc {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    color: #0a243f;
  
  }
  .submissionModalTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #0a243f;
  }

  .request-price-inp-err {
    border: 1px solid red !important;
    background-color: rgb(243, 227, 227) !important;
  }
  .submitButton
  {
    border-radius: 8px;
  background-color: #015fe5;
  height: 56px;
  color: #fff;
  width: 100%;
  }
  .submitButton:hover
  {
  
  color: #fff !important;
  width: 100%;
  }
  .cart-return-reason-parent
  {
    border-radius: 16px;
  background-color: #f8faf7;
  padding: 16px;
  }
  .select-full
  {
    border-radius: 8px;
    width: 100% !important;

  }
  .card-alert-strip
  {
    border-radius: 16px;
  background-color: #ffebe6;
  padding: 16px;
  color: #0a243f;
  font-weight: 500;
  }
  .collapse-section
  {
    border-left: 0px !important;
    border-right: 0px !important;
    background-color: #fff !important;
    border-radius: 0px !important;
    
  }
  .collapse-section:first-child
  {
    border-top: 0px !important;
   
    
  }
  .collapse-section .ant-collapse-header-text
  {
    color: #0a243f;
    font-size: 20px;
    font-weight: 600;

  }
  /* .collapse-section .ant-collapse-expand-icon{
    padding: 12px;
  background-color: #0a243f;
  color: #fff;
  border-radius: 50%;

  } */
  .collapse-section .ant-collapse-arrow
  {
    font-size: 20px;
  }
 .mb-4
 {
  margin-bottom: 40px !important;
 }
 .money-hand
 {
  position: absolute;
    right: -25px;
    bottom: 0px;
    top: -8px;
    overflow: hidden;
    width: 40%;
}
.card-tips-section,.card-content
{
  height: 100%;
}
.fixed-height-card{
  height: 355px;
}
 .pan-aadhar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
 }
 .HidecorLogo
 {
  width: 100%;
  height: 100%;
 }
 .YaahviLogo
 {
  width: 100%;
  height: 100%;
 }
 .sandharbhLogo
 {
  width: 100%;
  height: 100%;
 }
 .MysankalpLogo
 {
  width: 100%;
  height: 100%;
 }
 .card-content-normal
{
    border-radius: 16px;
    background-color: #f8faf7;
}
.chk-text .ant-checkbox-inner
{
  width: 24px !important;
  height: 24px !important;
}
.made-india
{
  font-size: 14px;
  font-weight: 500;
  color: #0a243f;
}
.mt-6{
  margin-top:50px;

}
.submissionModalParent {
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}
.submissionModalContentParent
{
  height: 194px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.padding-16px
{
  padding: 16px;
}
.upload-control .ant-tooltip
{
display: none !important;
}
.bg-testimonial
{
  background-color: #f8faf7 !important;
  border-radius: 16px !important;
}
.radio-price .ant-radio-inner
{
  width: 24px;
  height: 24px;
}
.static-table .ant-table-tbody .even-row
{
  background-color: #fff !important;
}
.static-table .ant-table-tbody .odd-row
{
  background-color: #f8faf7 !important;
}
.static-table .ant-table-thead tr th
{
  background-color: #55a77b !important;
  color: #fff !important;
}
.particulars-column
{
  font-size: 14px;
  font-weight: 600;
  color: #0a243f;
}
.sno-column
{
  font-size: 14px;

  color: #0a243f;
}
.static-list-content
{
  list-style: none !important;
  margin-top: 10px;
}
.card-testimonial
{
  height: 300px;
}
.authorname
{
  line-height: 0.5 !important;
}
.font-italic
{
  font-style: italic;
}
.testimonial-badge
{
  padding: 5px 12px 4px;
  border-radius: 8px;
  background-color: #e8f6c6;
  font-size: 12px;
  font-weight: 500;

}
.highlight-bold
{
  font-weight: 500;
}
.noborder-card
{
  box-shadow: unset !important;
  border:  0 !important;
}
.text-subhead-font-owner
{
   
    color: #767b81;
    font-size: 14px;
    font-weight: normal;
}

.video-card-content
{
  border: 0 !important;
}
.cls-center-img
{
  position: absolute;
  top: -15px;
    bottom: 15px;
    left: 0;
    right: 0;

}
.youtube-modal .ant-modal-header
{
  margin-bottom: 0 !important;
}
.youtube-modal .ant-modal-content
{
  border-radius:  0px !important;
  /* background: transparent !important; */
}
.youtube-modal .ant-modal-body {
  height: 600px;
  overflow: hidden; /* optional, to enable scrolling if content exceeds height */
}
.modal-center {
  top:-30px !important;
}