.screen-heading
{
    font-size: 20px;
    font-weight: 600;
}
.sub-strip
{
    
  padding: 24px;
  border-radius: 16px;
  background-image: linear-gradient(102deg, #f3ffd5 25%, #d1f4fa 103%);
}
.active-text
{
    font-size: 28px;
  font-weight: bold;
  color: #0a243f;
}
.active-text-sm
{
    font-size: 14px;

}